import { getUserPhotoUrl, getSearchResultUrl, getSearchPersonUrl, searchUserUrl } from '../urls'
var graph = require("@microsoft/microsoft-graph-client");
async function getAuthenticatedClient(accessToken) {
  const client = await graph.Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    },
  });
  return client;
}
export async function getToken(authClient) {
  return new Promise((resolve, reject) => {
    authClient.acquireToken(
      "https://graph.microsoft.com",
      function (errDesc, token, err, tokenType) {
        if (token) {
          resolve(token);
        } else {
          authClient.login();
        }
      }
    );
  });
}
export async function getPersonPhoto(authClient, PersonId) {
  var accessToken = await getToken(authClient);

  const client = await getAuthenticatedClient(accessToken);
  var photoUrl = getUserPhotoUrl(PersonId);

  var Avatar = null;
  try {
    Avatar = await client.api(photoUrl).get();
    return Avatar;
  } catch (e) {
    return null;

  }
}
export async function searchContact(
  msalinstance,
  searchString,
  enabledAccount
) {
  var accessToken = await getToken(msalinstance);
  const client = await getAuthenticatedClient(accessToken);
  var url = getSearchPersonUrl(searchString);
  url = getSearchResultUrl(searchString);
  try {
    let people = await client.api(url).get();
    let searchResult = {};
    if (people.value.length > 0) {
      for (let account of people.value) {
        if (account.accountEnabled === enabledAccount) {
          var details = account.userPrincipalName.split("@");
          searchResult[account.id] = {
            displayName: details[0],
            userPrincipalName: account.userPrincipalName,
          };
        }
      }
    }
    return searchResult;
  } catch (e) {
    console.log("ERROR " + JSON.stringify(e));
    return [];
  }
}
export async function searchContactFullinfos(
  msalinstance,
  searchString,
  enabledAccount
) {
  var accessToken = await getToken(msalinstance);

  const client = await getAuthenticatedClient(accessToken);
  var url = getSearchPersonUrl(searchString);
  url = getSearchResultUrl(searchString);
  try {
    let people = await client.api(url).get();
    return people.value;
  } catch (e) {
    return [];
  }
}
export async function searchUserById(
  msalinstance,
  searchString,
  enabledAccount
) {

  var accessToken = await getToken(msalinstance);
  const client = await getAuthenticatedClient(accessToken);
  var url = searchUserUrl(searchString);

  try {
    let people = await client.api(url).get();
    return people.value;
  } catch (e) {
    console.log("ERROR " + JSON.stringify(e));
    return [];
  }
}
