import React from "react";
import { connect } from "react-redux";
import { Header } from "@fluentui/react-northstar";
import {
  setGlobalAdmins,
  setFunctionalAdmins,
  setScopeList,
  addNewScope,
} from "../redux/actions";
import ScopeComponent from "../components/scope/ScopeComponent";
import axios from "axios";
import { authContext } from "../auth";
import { adminUsersApiUrl } from "../urls";
import translate from "../i18n/translate";
import { useIntl } from "react-intl";
const GlobalAdminsDescriptionText = translate(
  "Global Administrators have all permissions to create, delete, list and update all Heedify services"
);
const AdminUsersContainer = (props) => {
  const intl = useIntl();
  return (
    <div className="form-container">
      <Header
        as="h2"
        content={intl.formatMessage({ id: "scopes-managemant" })}
      />
      <Header
        className="titre-global-setting"
        as="p"
        content={intl.formatMessage({ id: "scopes-introduction" })}
        style={{ marginRight: "0px" }}
      ></Header>
      {props.scopeList ? (
        <ScopeComponent
          scopeList={props.scopeList}
          setScopList={props.setScopList}
          addNewScope={props.addNewScope}
          deleteScope={props.deleteScope}
          removeAdminUser={props.removeScopeAdminUser}
          addScopeAdminUser={props.addScopeAdminUser}
        />
      ) : null}
    </div>
  );
};
authContext.handleWindowCallback();
const user = authContext.getCachedUser();
const ADMIN_USERS_API_URL = adminUsersApiUrl;
const mapStateToProps = (state) => ({
  globalAdminUsers: state.globalAdminUsers,
  functionalAdminUsers: state.functionalAdminUsers,
  scopeList: state.scopeList,
});
const mapDispatchToProps = (dispatch) => ({
  setScopList: (scopeList) => {
    dispatch(setScopeList(scopeList));
  },
  addNewScope: (newScope) => {
    dispatch(addNewScope(newScope));
  },
  getGlobalAdmins: async () => {
    let url =
      ADMIN_USERS_API_URL +
      user.profile.tid +
      "/default/get/admins?userid=" +
      user.profile.oid;
    axios.post(url).then((result) => {
      dispatch(setGlobalAdmins(result.data));
    });
  },
  getFunctionalAdmins: async () => {
    let url =
      ADMIN_USERS_API_URL +
      user.profile.tid +
      "/default/get/functionalAdmins?userid=" +
      user.profile.oid;
    axios.post(url).then((result) => {
      dispatch(setFunctionalAdmins(result.data));
    });
  },
  addGlobalAdminUser: (admin) => {
    let url =
      ADMIN_USERS_API_URL +
      user.profile.tid +
      "/default/add/admins?userid=" +
      user.profile.oid +
      "&adminid=" +
      admin.id +
      "&adminDisplayName=" +
      admin.header;
    if (admin.id) {
      axios.post(encodeURI(url)).then((result) => {
        dispatch(setGlobalAdmins(result.data));
      });
    }
  },
  addFunctionalAdminUser: async (admin) => {
    let url =
      ADMIN_USERS_API_URL +
      user.profile.tid +
      "/default/add/functionalAdmins?userid=" +
      user.profile.oid +
      "&adminid=" +
      admin.id +
      "&adminDisplayName=" +
      admin.header;
    if (admin.id) {
      axios.post(encodeURI(url)).then((result) => {
        dispatch(setFunctionalAdmins(result.data));
      });
    }
  },
  removeGlobalAdminUser: async (admin, scope) => {
    let url =
      ADMIN_USERS_API_URL +
      user.profile.tid +
      "/" +
      scope +
      "/remove/admins?userid=" +
      user.profile.oid +
      "&adminid=" +
      admin;
    axios.post(encodeURI(url)).then((result) => {
      dispatch(setGlobalAdmins(result.data));
    });
  },
  removeFunctionalAdminUser: async (admin) => {
    let url =
      ADMIN_USERS_API_URL +
      user.profile.tid +
      "/default/remove/functionalAdmins?userid=" +
      user.profile.oid +
      "&adminid=" +
      admin;
    axios.post(encodeURI(url)).then((result) => {
      dispatch(setFunctionalAdmins(result.data));
    });
  },
  removeScopeAdminUser: async (admin, scope) => {
    let url =
      ADMIN_USERS_API_URL +
      user.profile.tid +
      "/" +
      scope +
      "/remove/admins?userid=" +
      user.profile.oid +
      "&adminid=" +
      admin;
    axios.post(encodeURI(url)).then((result) => {
      dispatch(setGlobalAdmins(result.data));
    });
  },
  addScopeAdminUser: (admin, scope) => {
    let url =
      ADMIN_USERS_API_URL +
      user.profile.tid +
      "/" +
      scope +
      "/add/admins?userid=" +
      user.profile.oid +
      "&adminid=" +
      admin.id +
      "&adminDisplayName=" +
      admin.header;
    if (admin.id) {
      axios.post(encodeURI(url)).then((result) => {
        dispatch(setGlobalAdmins(result.data));
      });
    }
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersContainer);
