import React from "react";
import ListeLicencesComponent from '../../components/Licences/ListeLicencesComponent';
import { connect } from "react-redux";
import { useIntl } from 'react-intl';
const LicenceContainer = (props) => {
    const intl = useIntl();
    return (
        <ListeLicencesComponent licences={props.licences} licencesName={props.licencesName} />
    )
}
const mapStateToProps = (state) => {
    return {
        licences: state.licences,
        licencesName: state.licencesName,
    }
}
export default connect(mapStateToProps)(LicenceContainer);