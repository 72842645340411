import { Table, Button, Flex, Dropdown, Text, Input } from "@fluentui/react-northstar";
import {
    gridNestedBehavior,
    gridCellWithFocusableElementBehavior,
    gridCellMultipleFocusableBehavior,
} from "@fluentui/accessibility";
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
    useEffect,
} from "react";
import { TrashCanIcon } from "@fluentui/react-icons-northstar";
import CallCenterName from "./CallCenterName";
import { v4 as uuidv4 } from "uuid";
import update from "immutability-helper";
import { useIntl } from 'react-intl';
const StaticDropDown = (props) => (

    <Dropdown
        className="StaticDropDown"
        defaultValue={props?.defaultVal}
        items={props.inputItems}
        placeholder={`Select ${props.column}`}
        checkable
        onChange={(e, val) => {
            props.actionChangeHandler(props.rowId, val.value.key, props.dialKey);
        }}
        getA11ySelectionMessage={{
            onAdd: (item) => `${item} has been selected.`,
        }}
    />
);
const Icr = forwardRef((props, ref) => {
    const intl = useIntl();
    const inputItems = [
        {
            header: intl.formatMessage({ id: "queue" }),
            key: "queue"
        },
        {
            header: intl.formatMessage({ id: "routing" }),
            key: "routing"
        }
    ];
    const header = {
        key: "header",
        items: [
            {
                content: intl.formatMessage({ id: "choise-name" }),
                key: "choise-name",
            },
            {
                content: intl.formatMessage({ id: "next" }),
                key: "getA11ySelectionMessage",
            },
            {
                content: intl.formatMessage({ id: "redirect-to" }),
                key: "pic",
            },
            {
                content: intl.formatMessage({ id: "delete" }),
                key: "delete",
            },
        ],
    };
    const deleteKey = (key) => {
        setRows((state) => state.filter((elm) => elm.key != key));
    };
    const getRedirectComponent = (val, data, dielKey) => {
        switch (val.toLowerCase()) {
            case "queue":
                return {
                    content: (
                        <Flex gap="gap.small" vAlign="center">
                            <CallCenterName
                                scope={props.scope}
                                itemName="icrQueue"
                                getData={props.getData}
                                searchQueue
                                searchactifProfils
                                displayName={data.queueDisplayName}
                                dielKey={dielKey}
                                routingName={props.routingName}
                            />
                        </Flex>
                    ),
                };
                break;
            case "routing":
                let items = []
                for (const routing of props.routingList) {
                    if (routing !== props.routingName && props.chatCenterData.routing[routing].type && props.chatCenterData.routing[routing].type !== "") {
                        if (routing === "0") {
                            items.push("Start")
                        } else {
                            items.push(routing)
                        }
                    }
                }
                return {
                    content: (
                        <Dropdown
                            className="StaticDropDown"
                            defaultValue={props.data.customerChoiceActions[dielKey]
                                && props.data.customerChoiceActions[dielKey].next
                                && props.data.customerChoiceActions[dielKey].next.routingName ?
                                props.data.customerChoiceActions[dielKey].next.routingName === "0" ? "Start" : props.data.customerChoiceActions[dielKey].next.routingName
                                : null
                            }
                            items={items}
                            onChange={(e, data) => {
                                props.getData(data.value, "routingIcr", dielKey, props.routingName)
                            }}
                        />
                    ),
                };
                break;
        }
    };
    const actionChangeHandler = (key, val, dialKey) => {
        setRows((state) => {
            const lineIndex = state.findIndex((elm) => elm.key === key);
            // props.getHolidaysActionData(val, "Holidays");
            return update(state, {
                [lineIndex]: {
                    items: {
                        [2]: {
                            content: {
                                $set: getRedirectComponent(
                                    val,
                                    { queueDisplayName: "" },
                                    dialKey
                                ).content,
                            },
                        },
                    },
                },
            });
        });
    };
    useEffect(() => {
        if (props.data.customerChoiceActions) {
            const options = props.data.customerChoiceActions;
            const rowItems = Object.keys(options).map((elm, i) => {
                const id = uuidv4();
                const getNextValueTranslated = (type) => {
                    let result = ""
                    switch (type) {
                        case "queue":
                            result = intl.formatMessage({ id: "queue" })
                            break;
                        case "routing":
                            result = intl.formatMessage({ id: "routing" })
                            break;
                    }
                    return result;
                }
                if (options[elm].next.type && options[elm].next.type !== "loop") {
                    return {
                        key: id,
                        items: [
                            {
                                content: <Input
                                    placeholder={intl.formatMessage({ id: "type-here..." })}
                                    defaultValue={options[elm].text}
                                    onChange={(e, data) => {
                                        let value = data.value;
                                        props.setPostBack(value, elm, props.routingName);
                                    }}
                                />,
                                key: `${i}-0`,
                                id: "name-1",
                                style: { width: '100%!important' }
                            },
                            {
                                content: (
                                    <Flex gap="gap.small" vAlign="center">
                                        <StaticDropDown
                                            dialKey={elm}
                                            defaultVal={getNextValueTranslated(options[elm].next.type)}
                                            actionChangeHandler={actionChangeHandler}
                                            rowId={id}
                                            inputItems={inputItems}
                                            column="action"
                                        />
                                    </Flex>
                                ),
                                key: `${i}-1`,
                                id: "name-1",
                            },
                            {
                                content: getRedirectComponent(
                                    options[elm].next.type,
                                    {
                                        queueDisplayName:
                                            options[elm].next.queueDisplayName
                                    },
                                    elm
                                ),
                                key: `${i}-2`,
                            },
                            {
                                content: (
                                    <Flex gap="gap.small" vAlign="center">
                                        <Button
                                            primary
                                            icon={<TrashCanIcon />}
                                            onClick={(e, key) => {
                                                props.removeIcr(options[elm].value);
                                                deleteKey(id);
                                            }}
                                            text
                                        />{" "}
                                    </Flex>
                                ),
                                key: `${i}-3`,
                            },
                        ],
                    };
                }
            });
            setRows(rowItems);
        }

    }, []);
    const [rows, setRows] = useState([]);
    const [scope, setScope] = useState(props.scope);
    useImperativeHandle(ref, (props) => ({
        addIvr: (key, setPostBack, routingName) => {
            const uid = uuidv4();
            const newIvr = {
                ref: ref,
                key: uid,
                items: [
                    {
                        content: (
                            <Input rowId={uid}
                                column="ivr"
                                placeholder={intl.formatMessage({ id: "type-here..." })}
                                onChange={(e, data) => {
                                    let value = data.value;
                                    setPostBack(value, key, routingName);
                                }} />
                        ),
                        key: `${rows.length + 1}-1`,
                        style: { width: '100%!important' }
                    },
                    {
                        content: (
                            <Flex>
                                <StaticDropDown
                                    dialKey={key}
                                    rowId={uid}
                                    inputItems={inputItems}
                                    actionChangeHandler={actionChangeHandler}
                                    column="action"
                                />
                            </Flex>
                        ),

                        key: `${rows.length + 1}-2`,
                        id: "name-1",
                    },
                    {
                        content: (
                            <Flex gap="gap.small" vAlign="center">
                                <CallCenterName searchQueue scope={scope} />
                            </Flex>
                        ),
                        key: `${rows.length + 1}-3`,
                    },
                    {
                        content: (
                            <Flex gap="gap.small" vAlign="center">
                                <Button
                                    primary
                                    icon={<TrashCanIcon />}
                                    onClick={(key) => deleteKey(newIvr.key)}
                                    text
                                />{" "}
                            </Flex>
                        ),
                        key: `${rows.length + 1}-4`,
                    },
                ],
            };

            setRows((state) => {
                const newSate = [...state, newIvr];
                return newSate;
            });
        },
    }));
    return (
        <Table
            className="table_ivrs"
            variables={{
                cellContentOverflow: "none",
            }}
            header={header}
            rows={rows}
            aria-label="Nested navigation"
            accessibility={gridNestedBehavior}
        />
    );
});

export default Icr;