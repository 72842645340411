import React, { useState } from "react";
import update from "immutability-helper";
import {
    Input,
    Header,
    Dropdown,
    Flex,
    AddIcon,
    Button,
    Label,
    CloseIcon

} from "@fluentui/react-northstar";
import { useIntl } from 'react-intl';
import AudioComponent from './AudioComponent'
import './Survey.css'
const QuestionComponent = (props) => {
    const intl = useIntl();
    const [activeRange, setActiveRange] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [yesDegitErrorMessage, setYesDegitErrorMessage] = useState(null)
    const [orderErrorMessage, setOrderErrorMessage] = useState(null)
    const serveyItems = [
        {
            key: "YES_OR_NO",
            header: intl.formatMessage({ id: "YES_OR_NO" })
        },
        {
            key: "RATING",
            header: intl.formatMessage({ id: "RATING" })
        },
        /**
         *         {
            key: "NUMERICAL",
            header: intl.formatMessage({ id: "NUMERICAL" })
        }
         */
    ]
    const ValidateUSNumber = (string) => {
        if (string !== null && string.length > 0) {
            var regExp = /^[0-9-*#]+$/;
            var phone = string.match(regExp);
            if (phone) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const setValuesRange = () => {
        switch (props.question.type) {
            case "RATING":
                return (
                    <div className="survey-type-div">
                        <Flex hAlign="start" vAlign="center" gap="gap.small">
                            <Header as="h5" content={intl.formatMessage({ id: "RANGE_VALUES" })} />
                            <Input type="number" min='0' className="survey-number-input"
                                onChange={(event, data) => {
                                    setActiveRange(data.value)
                                }} />
                            <Button
                                icon={<AddIcon />}
                                text
                                primary
                                onClick={() => {
                                    if (activeRange !== null && activeRange !== '') {
                                        let newValuesRange = []
                                        if (props.question.valuesRange) {
                                            newValuesRange = props.question.valuesRange
                                        }
                                        newValuesRange.push(activeRange)
                                        newValuesRange = [...new Set(newValuesRange)];
                                        props.setCallCenterData((state) => {
                                            return update(state, {
                                                survey: { questions: { [props.questionName]: { valuesRange: { $set: newValuesRange } } } },
                                            });
                                        });
                                        setActiveRange(null)
                                    }
                                }}
                            />
                        </Flex>
                        <div style={{ display: 'flex', marginTop: '10px' }}>
                            {
                                props.question.valuesRange ?
                                    props.question.valuesRange.map((item, i) => {
                                        return (
                                            <div className="waitTimeIntervals-label " key={i} >
                                                <Label
                                                    content={item}
                                                    icon={
                                                        <CloseIcon
                                                            className="delete-waitTimeInterval-btn"
                                                            onClick={() => {
                                                                let newValuesRange = props.question.valuesRange
                                                                newValuesRange = newValuesRange.filter(number => number !== item);
                                                                props.setCallCenterData((state) => {
                                                                    return update(state, {
                                                                        survey: { questions: { [props.questionName]: { valuesRange: { $set: newValuesRange } } } },
                                                                    });
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    style={{ marginRight: "5px", marginBottom: "5px" }}
                                                />
                                            </div>
                                        )
                                    })
                                    :
                                    null
                            }
                        </div>
                    </div >
                )
                break;
            case "NUMERICAL":
                return (
                    <div className="survey-type-div" style={{ alignContent: 'center' }}>
                        <Flex hAlign="start" vAlign="center" gap="gap.small" style={{ marginBottom: '10px', alignItems: 'center' }}>
                            <Header className="numerical-input-case" as="h5" content={intl.formatMessage({ id: "VALUE_RANGE_INF" })} />
                            <Input type="number"
                                min='0'
                                className="numerical-value-input"
                                defaultValue={props.question.valuesRangeInf ? props.question.valuesRangeInf : null}
                                onChange={(event, data) => {
                                    if (props.question.valuesRangeSup && data.value > props.question.valuesRangeSup) {
                                        setErrorMessage(intl.formatMessage({ id: 'CALLFLOW_NUMERICAL_CASE_ERRORMESSAGE' }))
                                    } else {
                                        setErrorMessage(null)
                                        props.setCallCenterData((state) => {
                                            return update(state, {
                                                survey: { questions: { [props.questionName]: { valuesRangeInf: { $set: data.value } } } },
                                            });
                                        });
                                    }

                                }} />
                        </Flex>
                        <Flex hAlign="start" vAlign="center" gap="gap.small" style={{ marginBottom: '10px', alignItems: 'center' }}>
                            <Header className="numerical-input-case" as="h5" content={intl.formatMessage({ id: "VALUE_RANGE_SUP" })} />
                            <Input type="number"
                                min='0'
                                className="numerical-value-input"
                                defaultValue={props.question.valuesRangeSup ? props.question.valuesRangeSup : null}
                                onChange={(event, data) => {
                                    if (props.question.valuesRangeInf && data.value < props.question.valuesRangeInf) {
                                        setErrorMessage(intl.formatMessage({ id: "CALLFLOW_NUMERICAL_CASE_ERRORMESSAGE" }))
                                    } else {
                                        setErrorMessage(null)
                                        props.setCallCenterData((state) => {
                                            return update(state, {
                                                survey: { questions: { [props.questionName]: { valuesRangeSup: { $set: data.value } } } },
                                            });
                                        });
                                    }

                                }} />
                        </Flex>
                    </div>
                )
                break;
            case "YES_OR_NO":
                return (
                    <div className="survey-type-div" style={{ alignContent: 'center' }}>
                        <Flex hAlign="start" vAlign="center" gap="gap.small" style={{ marginBottom: '10px', alignItems: 'center' }}>
                            <Header className="numerical-input-case" as="h5" content={intl.formatMessage({ id: "YES_DIGIT" })} />
                            <Input type="text"
                                className="yes-degit-value-input"
                                defaultValue={props.question.yesDigit ? props.question.yesDigit : null}
                                onChange={(event, data) => {
                                    let isNumber = ValidateUSNumber(data.value);
                                    if (isNumber && data.value.length === 1) {
                                        setYesDegitErrorMessage(null)
                                        props.setCallCenterData((state) => {
                                            return update(state, {
                                                survey: { questions: { [props.questionName]: { yesDigit: { $set: data.value } } } },
                                            });
                                        });

                                    } else {
                                        setYesDegitErrorMessage(intl.formatMessage({ id: "YES_DIGIT_ERROR_MESSAGE" }))
                                    }
                                }} />
                        </Flex>
                        {
                            yesDegitErrorMessage && yesDegitErrorMessage !== null ?
                                <p style={{ color: 'red' }}>{yesDegitErrorMessage}</p>
                                :
                                null

                        }
                    </div>
                )
                break;
            default:
                return null
                break;
        }
    }
    const setAudioType = (type) => {
        let audio = { type: type }
        if (type = "text2speech") {
            audio.language = props.callFlowLanguage;
        }
        props.setCallCenterData((state) => {
            return update(state, {
                survey: { questions: { [props.questionName]: { audio: { $set: audio } } } },
            });
        });

    }
    const setAudiofile = (audio) => {
        props.setCallCenterData((state) => {
            return update(state, {
                survey: {
                    questions: {
                        [props.questionName]: { audio: { $set: audio } }
                    },
                }
            });
        });
    }
    return (
        <div style={{ marginLeft: '100px' }}>
            <Flex hAlign="start" vAlign="center" gap="gap.small">
                <Header style={{ minWidth: '128px' }} as="h5" content={intl.formatMessage({ id: "type" })} />
                <Dropdown
                    className="child-title-call-flow"
                    defaultValue={props.question.type ? intl.formatMessage({ id: props.question.type }) : null}
                    onChange={(e, data) => {
                        props.setCallCenterData((state) => {
                            return update(state, {
                                survey: { questions: { [props.questionName]: { type: { $set: data.value.key } } } },
                            });
                        });
                    }
                    }
                    items={serveyItems}
                />
            </Flex>
            <Flex hAlign="start" vAlign="center" gap="gap.small">
                <Header as="h5" content={intl.formatMessage({ id: "QUESTION_TEXT" })} />
                <Input
                    className="child-title-call-flow survey-name-input"
                    defaultValue={props.question.questionText ? props.question.questionText : null}
                    onChange={(event, data) => {
                        props.setCallCenterData((state) => {
                            return update(state, {
                                survey: { questions: { [props.questionName]: { questionText: { $set: data.value } } } },
                            });
                        });
                    }}
                />
            </Flex>
            <Flex hAlign="start" vAlign="center" gap="gap.small">
                <Header as="h5" content={intl.formatMessage({ id: "QUESTION_ORDRE" })} />
                <Input
                    className="numerical-value-input question-order-input"
                    type="number"
                    min="0"
                    defaultValue={props.question.Order ? props.question.Order : null}
                    onChange={(event, data) => {
                        if (props.usedOrder.includes(data.value) || data.value === "") {
                            setOrderErrorMessage(intl.formatMessage({ id: "ORDER_ERROR_MESSAGE" }))
                        } else {
                            if (props.question.Order) {
                                props.setUsedOrder(props.usedOrder.filter(item => item !== props.question.Order))
                            }
                            props.setUsedOrder(usedOrder => [...usedOrder, data.value]);
                            setOrderErrorMessage(null)
                            props.setCallCenterData((state) => {
                                return update(state, {
                                    survey: { questions: { [props.questionName]: { Order: { $set: data.value } } } },
                                });
                            });
                        }

                    }}
                />
            </Flex>
            {
                orderErrorMessage !== null ?
                    <p style={{ color: "red" }} className="error-message-div">{orderErrorMessage}</p>
                    :
                    null
            }
            <Flex hAlign="start" vAlign="start" gap="gap.small" >
                <Header as="h5" content={intl.formatMessage({ id: "ANNOUCEMENT" })} />
                <AudioComponent
                    audioList={props.audioList}
                    fileLocationUnderCallflow={"survey/" + props.questionName}
                    setAudioType={setAudioType}
                    setAudiofile={setAudiofile}
                    data={props.question.audio}
                    callFlowId={props.callFlowId}
                />
            </Flex>
            {
                errorMessage !== null ?
                    <p style={{ color: "red" }} className="error-message-div">{errorMessage}</p>
                    :
                    null

            }
            <div style={{ marginTop: '25px' }}>
                {
                    setValuesRange()
                }
            </div>
        </div>
    )

}
export default QuestionComponent