import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Flex, Image, Text } from "@fluentui/react-northstar";
import translate from "../../i18n/translate";
const HomePageContainer = (props) => {
  const history = useHistory();
  let timeOfDay;
  const date = new Date();
  const hours = date.getHours();
  const styles = {
    fontSize: 35,
  };

  if (hours < 12) {
    timeOfDay = translate("Good-morning");
    styles.color = "#D90000";
  } else if (hours >= 12 && hours < 17) {
    timeOfDay = translate("Good-afternoon");
    styles.color = "#04733F";
  } else {
    timeOfDay = translate("Good-evening");
    styles.color = "#04756F";
  }
  return (
    <div style={{ marginLeft: "20px", paddingTop: "30px" }}>
      <h1 style={{ textAlign: "center" }}>{translate("greeting_home_page")}</h1>
      <span className="home-page-greeting"> {timeOfDay}</span>
      <p>{translate("home_page_message")}</p>
      <div
        style={{ justifyContent: "space-around", display: "flex" }}
        className="row"
      >
        <Card
          className="home-card"
          aria-roledescription="card with image and text"
          onClick={() => {
            window.open("https://agentconsole.heedify.io", "_blank");
          }}
        >
          <Card.Body fitted>
            <Flex column gap="gap.small" style={{ minHeight: "150px" }}>
              <img src="https://heedify.io/wp-content/uploads/2021/02/reception.png" />
            </Flex>
            <Text className="home-card-name" content="Agent console" />
          </Card.Body>
        </Card>
        <Card
          className="home-card"
          aria-roledescription="card with image and text"
          onClick={() => {
            window.open("https://reports.heedify.io/#", "_blank");
          }}
        >
          <Card.Body fitted>
            <Flex column gap="gap.small" style={{ minHeight: "150px" }}>
              <img src="https://heedify.io/wp-content/uploads/2021/09/porweBIEN.png" />
            </Flex>
            <Text className="home-card-name" content="Reports portal" />
          </Card.Body>
        </Card>
        <Card
          className="home-card"
          aria-roledescription="card with image and text"
          onClick={() => {
            window.open("https://wallboard.heedify.io/", "_blank");
          }}
        >
          <Card.Body fitted>
            <Flex column gap="gap.small" style={{ minHeight: "150px" }}>
              <img src="https://heedify.io/wp-content/uploads/2021/09/realtimedashboredFR.png" />
            </Flex>
            <Text className="home-card-name" content="RealTime Dashboard" />
          </Card.Body>
        </Card>
        <Card
          className="home-card"
          aria-roledescription="card with image and text"
          onClick={() => {
            window.open("https://recordings.heedify.io/", "_blank");
          }}
        >
          <Card.Body fitted>
            <Flex column gap="gap.small" style={{ minHeight: "150px" }}>
              <img src="https://heedify.io/wp-content/uploads/2022/02/heedify-Recording.png" />
            </Flex>
            <Text className="home-card-name" content="Call Recording" />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default HomePageContainer;
