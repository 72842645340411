import React, { useState, useEffect } from "react";
import { Button, EditIcon, TrashCanIcon, AddIcon, Table } from '@fluentui/react-northstar'
import { useIntl } from "react-intl";
import { authContext } from "../../auth";
import { getAllGroupsUrl, removeGroupUrl, addGroupsUrl, updateGroupsUrl } from "../../urls";
import AddGroup from './AddGroup';
import { confirmAlert } from 'react-confirm-alert';
import UpdateGroup from './UpdateGroup';
import axios from "axios";
const GroupsComponent = (props) => {
    const intl = useIntl();
    const [isAddGroup, setIsAddGroup] = useState(false);
    const [isUpdateGroup, setIsUpdateGroup] = useState(false);
    const [formattedGroupsList, setFormattedGroupsList] = useState([]);
    const [groupToUpdate, setGroupToUpdate] = useState();
    const [groupList, setGroupList] = useState([]);
    const confirmeRemoveGroup = (groupId, groupName, scope) => {
        confirmAlert({
            title: intl.formatMessage({ id: "confirmation" }),
            message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + groupName + '!',
            buttons: [
                {
                    label: intl.formatMessage({ id: "yes" }),
                    onClick: () => removeGroup(groupId, scope)
                },
                {
                    label: intl.formatMessage({ id: "no" }),
                }
            ]
        });
    };
    const formatGroupList = (list) => {
        setFormattedGroupsList([])
        const formattedList = Object.keys(list).map((elm, j) => {
            let supervisor = 0;
            for (const item of Object.keys(list[elm].agentDetails)) {
                if (list[elm].agentDetails[item].isSupervisor === true) {
                    supervisor++
                }
            }
            const rowTable = {
                key: list[elm].id,
                items: [{
                    content: list[elm].groupName,
                    key: j + "-1",
                }, {
                    content: list[elm].scope,
                    key: j + "-2",
                },
                {
                    content: list[elm].agentsIds.length,
                    key: j + "-3",
                },
                {
                    content: supervisor,
                    key: j + "-4",
                },
                {
                    content: <div >
                        <Button
                            icon={<EditIcon />}
                            text
                            title={intl.formatMessage({ id: 'edit' })}
                            iconOnly
                            onClick={() => {
                                setGroupToUpdate(list[elm])
                                setIsUpdateGroup(true);
                            }
                            }
                        />
                        <Button
                            icon={<TrashCanIcon />}
                            text
                            title={intl.formatMessage({ id: 'delete' })}
                            iconOnly
                            disabled={elm === "default" ? true : false}
                            onClick={() => {
                                confirmeRemoveGroup(list[elm].id, list[elm].groupName, list[elm].scope)
                            }}
                        />
                    </div>,
                    key: j + "-5",
                }
                ]

            }
            setFormattedGroupsList(formattedGroupsList => [...formattedGroupsList, rowTable]);
            return formattedGroupsList
        });
    };
    const getGroupList = async () => {
        let scopeListName = Object.keys(props.scopeList)
        const promises = []
        for (let scope of scopeListName) {
            const url = getAllGroupsUrl(authContext._user.profile.tid, scope, authContext._user.profile.oid);
            await axios.get(url).then((Res) => {
                promises.push(Res.data)
            });
        }
        Promise.all(promises).then((results) => {
            var groups = []
            for (var result of results) {
                groups = groups.concat(result)
            }
            return groups
        }).then((groups) => {
            setGroupList(groups)
        })

    };
    const addNewGroup = async (newGroup) => {
        const url = addGroupsUrl(authContext._user.profile.tid, newGroup.scope, authContext._user.profile.oid);
        await axios.post(url, newGroup).then((Res) => {
            if (Res.data === 'created') {
                getGroupList()
                setIsAddGroup(false)
            }
        });
    }
    const updateGroup = async (group) => {
        const url = updateGroupsUrl(authContext._user.profile.tid, group.scope, authContext._user.profile.oid, group.id);
        await axios.post(url, group).then((Res) => {
            if (Res.data === 'updated') {
                let newGroupList = groupList.map((item, i) => {
                    if (item.id === group.id) {
                        return group;
                    } else {
                        return item
                    }

                });
                setGroupList(newGroupList)
                setIsUpdateGroup(false)
            }
        });
    }
    const removeGroup = async (groupId, scope) => {
        let url = removeGroupUrl(authContext._user.profile.tid, scope, groupId, authContext._user.profile.oid)
        await axios.get(url).then((Res) => {
            if (Res.data === "removed") {
                let newGroupList = groupList.filter(agent => agent.id !== groupId);
                setGroupList(newGroupList)
            }
        });
    };
    const isUserAdminScope = (scope, userId) => {
        let isUserAdmin = false;
        if (props.scopeList['default'] && props.scopeList['default'].admins.adminsIds.includes(userId)) {
            isUserAdmin = true;
        } else {
            if (scope.admins.adminsIds.includes(userId)) {
                isUserAdmin = true;
            }
        }
        return isUserAdmin
    }
    const header = {
        key: "0-0",
        items: [intl.formatMessage({ id: "name" }), intl.formatMessage({ id: "scope" }), intl.formatMessage({ id: "agents" }), intl.formatMessage({ id: "supervisors" }), intl.formatMessage({ id: "action" })],
    }
    useEffect(() => {
        formatGroupList(groupList)
    }, [groupList])
    useEffect(() => {
        if (props.scopeList) {
            getGroupList()
        }
    }, [props.scopeList])
    return (
        <div className="table-list">
            {
                isAddGroup && Object.keys(props.scopeList).length > 0 ?
                    <AddGroup
                        isUserAdminScope={isUserAdminScope}
                        setIsAddGroup={setIsAddGroup}
                        setGroupList={props.setGroupList}
                        scopeList={props.scopeList}
                        addNewGroup={addNewGroup}
                    />
                    :
                    <div>
                        {
                            isUpdateGroup ?
                                <UpdateGroup
                                    isUserAdminScope={isUserAdminScope}
                                    data={groupToUpdate}
                                    setGroupList={props.setGroupList}
                                    isUpdateGroup={isUpdateGroup}
                                    setIsUpdateGroup={setIsUpdateGroup}
                                    scopeList={props.scopeList}
                                    updateGroup={updateGroup}
                                />
                                :
                                <div >
                                    <div >
                                        <Button
                                            icon={<AddIcon />}
                                            text
                                            content={intl.formatMessage({ id: 'add' })}
                                            title={intl.formatMessage({ id: 'add' })}
                                            primary
                                            onClick={() => { setIsAddGroup(true) }} />
                                    </div>
                                    {
                                        /**
                                         *   <List items={formattedGroupsList} />
                                         */
                                    }
                                    <Table header={header} rows={formattedGroupsList} aria-label="Static table" className="groups-table" />
                                </div>
                        }
                    </div>
            }

        </div >
    );
};

export default GroupsComponent;