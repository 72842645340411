import React, { useEffect, useState } from "react";
import { Dropdown, } from "@fluentui/react-northstar";
import { authContext } from "../../auth";
import { getAllAgentsUrl } from "../../urls"
import Axios from "axios";
const SearchAgent = (props) => {
    const [agentsList, setAgentsList] = useState([]);
    const getScopeAgents = async () => {
        const url = getAllAgentsUrl(authContext._user.profile.tid, authContext._user.profile.oid, props.scope);
        await Axios.get(url).then((res) => {
            setAgentsList([])
            Object.keys(res.data).map((agentId, i) => {
                setAgentsList(agentsList => [...agentsList, {
                    header: res.data[agentId].displayName,
                    key: res.data[agentId].id,
                    email:res.data[agentId].email
                }])
        })
        })
    }
    useEffect(() => {
        if (props.scope) {
            getScopeAgents()
        }
    }, [props.scope])
    return (
        <Dropdown
        style={{marginLeft:"50px"}}
            search
            items={agentsList}
            placeholder="Start typing a name"
            noResultsMessage="We couldn't find any matches."
            getA11ySelectionMessage={{
                onAdd: item => {
                    let agent= {
                        id: item.key,
                        header: item.header,
                        email:item.email
                      }
                    props.addAnsweringAgent(agent)
                },
            }}
        />

    )
};
export default SearchAgent;
