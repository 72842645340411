import React, { useState, useEffect } from "react";
import { Button, TrashCanIcon, CloseIcon } from '@fluentui/react-northstar'
import FunctionAdmins from './FunctionAdmins';
import { authContext } from "../../auth";
import { confirmAlert } from 'react-confirm-alert';
import translate from '../../i18n/translate'
import { useIntl } from 'react-intl';
const UpdateScope = (props) => {
    const intl = useIntl();
    const [newScope, setNewScope] = useState(null)
    const setAdmins = (contact) => {
        let user = {
            id: contact.id,
            header: contact.displayName
        }
        props.addScopeAdminUser(user, newScope.RowKey)
        let newContactId = contact.id;
        let newContactName = contact.displayName;
        let adminsIds = newScope.admins.adminsIds
        let adminsDetails = newScope.admins.adminsDisplayNames
        adminsIds.push(newContactId)
        adminsDetails[newContactId] = newContactName
        let newContact = {
            adminsIds: adminsIds,
            adminsDisplayNames: adminsDetails
        }
        setNewScope({ ...newScope, admins: newContact })
    }
    const confirmeRemoveUserAdmin = (userAdmin, userDisplayName) => {
        confirmAlert({
            title: intl.formatMessage({ id: "confirmation" }),
            message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + userDisplayName + '!',
            buttons: [
                {
                    label: intl.formatMessage({ id: "yes" }),
                    onClick: () => deleteUserFromScope(userAdmin)
                },
                {
                    label: intl.formatMessage({ id: "no" }),
                }
            ]
        });
    };
    const deleteUserFromScope = (elm) => {
        props.removeAdminUser(elm, newScope.RowKey)
        let newAdminsIds = newScope.admins.adminsIds.filter((x) => x === elm)
        let newAdminsDisplayNames = newScope.admins.adminsDisplayNames;
        delete newAdminsDisplayNames[elm];
        setNewScope({
            PartitionKey: authContext._user.profile.tid,
            RowKey: newScope.RowKey,
            admins: {
                adminsIds: newAdminsIds,
                adminsDisplayNames: newAdminsDisplayNames
            }
        })

    };
    useEffect(() => {
        setNewScope(props.data)
    }, [])
    return (
        <>
            {
                newScope && newScope !== null ?
                    <div>
                        <div className="header-update-scope">
                            <label id="scope-name-update" >{intl.formatMessage({ id: "scope-name" }) + newScope.RowKey}</label>
                            <Button
                                icon={<CloseIcon />}
                                text
                                title={intl.formatMessage({ id: "close" })}
                                iconOnly
                                onClick={() => {
                                    props.setIsUpdateScope(false);
                                    setNewScope(null)
                                }}
                            />
                        </div>
                        <label className="admin-list-title">{translate('admin-list')}</label>
                        < FunctionAdmins getData={setAdmins} />
                        <div className="scope-list" >
                            {
                                Object.keys(newScope.admins.adminsDisplayNames).map((elm, i) => {
                                    return (
                                        <div className="item-scope-list" key={i}>
                                            <p>{newScope.admins.adminsDisplayNames[elm]}</p>
                                            <Button
                                                icon={<TrashCanIcon />}
                                                text
                                                title={intl.formatMessage({ id: "delete" })}
                                                iconOnly
                                                onClick={() => {
                                                    confirmeRemoveUserAdmin(elm, newScope.admins.adminsDisplayNames[elm])
                                                }}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div >
                    :
                    null

            }
        </>
    )
}
export default UpdateScope