import React, { useState, useEffect } from "react";
import { Table } from "@fluentui/react-northstar";
import translate from "../../i18n/translate";
import { useIntl } from "react-intl";
const ListeLicencesComponent = (props) => {
  const [licencesItems, setLicencesItems] = useState([]);
  const intl = useIntl();
  const header = {
    key: "header2",
    items: [
      {
        content: intl.formatMessage({ id: "licence" }),
        key: "licence",
      },
      {
        content: intl.formatMessage({ id: "scope" }),
        key: "Scope",
      },
      {
        content: intl.formatMessage({ id: "total" }),
        key: "total",
      },
      {
        content: intl.formatMessage({ id: "used" }),
        key: "licenceType",
      },
      {
        content: intl.formatMessage({ id: "available" }),
        key: "quantity ",
      }
    ],
  }
  const listFormateLicences = () => {
    const formattedList = Object.keys(props.licences).map(function (scope,i ) {
   
      let licencesName = Object.keys(props.licences[scope])
      licencesName= licencesName.filter(key => (!key.includes("PartitionKey") && !key.includes("RowKey") && !key.includes("AVAILABLE_") && !key.includes("USED_")));
      for(const licence of licencesName){
        let rowTable = {
          key: scope+"-"+licence,
          items: [
            {
              content: intl.formatMessage({ id: licence }),
              key: i +licencesName[0]+ "-11",
            },
            {
              content: scope,
              key: i + licencesName[0]+"-44",
            },
            {
              content: props.licences[scope][licence],
              key: i +licencesName[0]+ "-77",
            },
            {
              content: props.licences[scope]["USED_"+licence],
              key: i +licencesName[0]+ "-66",
            },
            {
              content: props.licences[scope]["AVAILABLE_"+licence],
              key: i +licencesName[0]+ "-55",
            }
          ],
        };
        setLicencesItems((licencesItems) => [...licencesItems, rowTable]);
      }
    });
  };
  useEffect(() => {
    listFormateLicences();
  }, [props?.licences]);
  return (
    <div>
      <div className="table-list">
        <h2>{translate("licences")}</h2>
        <Table
          id="licence-Table"
          className="table-agent"
          variables={{
            cellContentOverflow: "none",
          }}
          header={header}
          aria-label="Static table"
          rows={licencesItems}
        />
      </div>
    </div>
  );
};

export default ListeLicencesComponent;
