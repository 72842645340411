import React from "react";
import QueueUserSearch from "../../components/Queues/QueueUserSearch";
import QueueSearch from "../../components/Queues/QueueSearch";
import { connect } from "react-redux";
import { setSelectedUserTransfertId, setSelectedOverflowQueue } from "../../redux/actions";
const QueueUserSearchContainer = (props) => {
  if (props.isQueueFallback !== true) {
    return (
      <>
        {
          props.action === "queue" ?
            <QueueSearch
              scope={props.scope}
              queueInfo={props.queueInfo}
              setSelectedHolidaysQueue={props.setSelectedHolidaysQueue}
              setSelectedOpenHoursQueue={props.setSelectedOpenHoursQueue}
              overflowType={props.overflowType}
              queueSearch={props.queueSearch}
              {...props}
            />
            :
            <QueueUserSearch
              target={
                props.isCallflow ? props.targetCF : { displayName: props.target }
              }
              itemToUpdate={props.itemToUpdate}
              setSelectedUserTransfertIdProp={
                props.isCallflow
                  ? props.setSelectedUserTransfertIdForCF
                  : props.setSelectedUserTransfertId
              }
              overflowType={props.overflowType}
              queueSearch={props.queueSearch}
              {...props}
            />
        }

      </>
    );
  } else {
    return (
      <>
        {
          props.isQueueFallback ?
            <QueueSearch
              queueId={props.queueIdOverflow}
              queueDisplayName={props.queueDisplayNameOverflow}
              overflowType={props.overflowType}
              queueSearch={props.queueSearch}
              setSelectedOverflowQueue={props.setSelectedOverflowQueue}
              {...props}
            />
            :
            <QueueUserSearch
              target={
                props.isCallflow ? props.targetCF : { displayName: props.target }
              }
              itemToUpdate={props.itemToUpdate}
              setSelectedUserTransfertIdProp={
                props.isCallflow
                  ? props.setSelectedUserTransfertIdForCF
                  : props.setSelectedUserTransfertId
              }
              overflowType={props.overflowType}
              queueSearch={props.queueSearch}
              {...props}
            />
        }

      </>
    );
  }

};
const mapStateToProps = (state, props) => {
  return {
    queueInfos: state.autoAttendant[props.match.params.id],
    target: state.autoAttendant[props.match.params.id]?.queueOverflow?.displayName,
    queueDisplayNameOverflow: state.autoAttendant[props.match.params.id]?.queueOverflow?.queueDisplayName,
    queueIdOverflow: state.autoAttendant[props.match.params.id]?.queueOverflow?.queueId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedUserTransfertId: (value) => { dispatch(setSelectedUserTransfertId(value)); },
  setSelectedOverflowQueue: (queueId, queueDisplayName) => { dispatch(setSelectedOverflowQueue(queueId, queueDisplayName)); },

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueueUserSearchContainer);
