import { Flex, Header } from "@fluentui/react-northstar";
import { authContext } from "../auth";
import Illustration from "./reportingIllustration";
import { Redirect } from "react-router-dom";
import React from "react";
const styles = {
  illust: {
    height: "350px",
  },
};
function Welcome(props) {
  const isLogged = authContext.getCachedUser();
  return isLogged === null ? (
    <Flex column>
      <Flex hAlign="center" vAlign="center">
        <Header as="h1" content="Welcome to Heedify Admin Page" />
      </Flex>
      <Flex hAlign="center" vAlign="center">
        <Illustration style={styles.illust} />
      </Flex>
      <Flex hAlign="center" vAlign="center">
        <p></p>
        <p></p>
        <mgt-login></mgt-login>
      </Flex>
    </Flex>
  ) : (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
}

export default Welcome;
