import React from "react";
import { Dropdown } from "@fluentui/react-northstar";
const CallflowScope = (props) => {
    const inputItems = Object.keys(props.scopeList)
    return (
        <>
            <Dropdown
                className="child-title-call-flow"
                disabled = {!props.isNewCallFlow}
                items={inputItems}
                placeholder="Scope"
                checkable
                value={props.callCenterData.scope}
                onChange={(e, data) => {
                    props.setScope(data.searchQuery)
                }}
            />
        </>
    );
};
export default CallflowScope;
