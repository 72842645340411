import React, { useEffect, useState } from "react";
import {
    RadioGroup,
    Header,
    Dropdown,
    Flex,
    TextArea,
    Button,
    Loader
} from "@fluentui/react-northstar";
import Axios from "axios";
import { authContext } from "../../auth";
import { useIntl } from 'react-intl';
import { text2speechApiUrl } from "../../urls"
const AudioComponent = (props) => {
    const intl = useIntl();
    const [checkboxValue, setCheckboxValue] = useState("");
    const [audioLangue, setAudioLangue] = useState(null);
    const [audioText, setAudioText] = useState(null);
    const [isT2sLoading, setIsT2sLoading] = useState(false);
    const items = [
        {
            name: "Text to speech",
            key: "text",
            label: intl.formatMessage({ id: "text2speech" }),
            value: "text2speech",
        },
        {
            name: "Audio",
            key: "audio",
            label: intl.formatMessage({ id: "audio" }),
            value: "Audiofile",
        },
    ];
    const voiceList = [
        {
            header: "fr-FR",
            voice: "DeniseNeural",
        },
        {
            header: "fr-CA",
            voice: "SylvieNeural",
        },
        {
            header: "en-US",
            voice: "AriaNeural",
        },
        {
            header: "de-DE",
            voice: "KatjaNeural",
        },
        {
            header: "es-ES",
            voice: "ElviraNeural",
        },
        {
            header: "it-IT",
            voice: "ElsaNeural",
        },
        {
            header: "pt-PT",
            voice: "RaquelNeural",
        },

    ];
    const generateAudioFile = () => {
        const url = text2speechApiUrl;
        const data = {
            language: audioLangue !== null && audioLangue.header ? audioLangue.header : "en-US",
            voice: audioLangue !== null && audioLangue.voice ? audioLangue.voice : "AriaNeural",
            text: audioText,
            directory: `${authContext._user.profile.tid}/${props.callFlowId
                }/${props.fileLocationUnderCallflow.toString().replace(/\s/g, "").toLowerCase()}`,
        };
        setIsT2sLoading(true);
        Axios.post(url, data).then((response, err) => {
            setIsT2sLoading(false);
            let url = response.data.url
            let audio = {
                "type": "text2speech",
                "language": audioLangue.header,
                "text": audioText,
                "audioFileUrl": url
            }
            props.setAudiofile(audio)
        });
    };
    useEffect(() => {
        if (props.data && props.data.type) {
            setCheckboxValue(props.data.type)
        }
        if (props.data && props.data.language) {
            let defaultAudioLangue = voiceList.filter(item => item.header === props.data.language);
            defaultAudioLangue = defaultAudioLangue[0]
            setAudioLangue(defaultAudioLangue)
        }
        if (props.data && props.data.text) {
            setAudioText(props.data.text)
        }
    }, [props.data])
    return (
        <div>
            <Flex hAlign="start" vAlign="start" gap="gap.small" className="audio-flex">
                <RadioGroup
                    className="child-title-call-flow"
                    items={items}
                    checkedValue={props.data && props.data.type ? props.data.type : null}
                    onCheckedValueChange={(e, data) => {
                        setCheckboxValue(data.value)
                        props.setAudioType(data.value)
                    }
                    }
                />
            </Flex>
            {
                checkboxValue === "Audiofile" ?
                    <Dropdown
                        className="child-title-call-flow"
                        items={props.audioList}
                        defaultValue={props.data && props.data.fileName ? props.data.fileName : null}
                        onChange={(e, data) => {
                            let audio = {
                                type: "Audiofile",
                                language: "",
                                text: "",
                                fileName: data.value.header,
                                audioFileUrl: data.value.url
                            };
                            props.setAudiofile(audio);
                        }
                        }
                    />
                    :
                    checkboxValue === "text2speech" ?
                        <>
                            <Flex column gap="gap.small" className="child-title-call-flow">
                                <Dropdown
                                    items={voiceList}
                                    placeholder={intl.formatMessage({ id: "language" })}
                                    checkable
                                    defaultValue={props.data && props.data.language ? props.data.language : null}
                                    onChange={(e, data) => { setAudioLangue(data.value) }}
                                    getA11ySelectionMessage={{
                                        onAdd: (item) => `${item} has been selected.`,
                                    }}
                                />
                                <div>
                                    <TextArea
                                        defaultValue={props.data && props.data.text ? props.data.text : ""}
                                        onChange={(e, data) => {
                                            setAudioText(data.value)
                                        }} />
                                </div>
                                <div>
                                    <Button
                                        content={isT2sLoading ? <Loader size="smallest" /> : intl.formatMessage({ id: "generate-audio" })}
                                        primary
                                        title={intl.formatMessage({ id: "transform-to-audio" })}
                                        onClick={generateAudioFile}
                                    />
                                </div>
                            </Flex>
                        </>

                        :
                        null
            }
        </div>
    )

}
export default AudioComponent;