import React from "react";
import { Dropdown } from "@fluentui/react-northstar";
import { useIntl } from 'react-intl';
import translate from '../../i18n/translate';
const Weekend = (props) => {
  const intl = useIntl();
  const inputItems = [
    { header: intl.formatMessage({ id: "Monday" }), id: 1 },
    { header: intl.formatMessage({ id: "Tuesday" }), id: 2 },
    { header: intl.formatMessage({ id: "Wednesday" }), id: 3 },
    { header: intl.formatMessage({ id: "Thursday" }), id: 4 },
    { header: intl.formatMessage({ id: "Friday" }), id: 5 },
    { header: intl.formatMessage({ id: "Saturday" }), id: 6 },
    { header: intl.formatMessage({ id: "Sunday" }), id: 0 },
  ];
  const getValue = (data, inputList) => {
    const dataList = data
      ? data.map((elm) => {
        return inputList[inputList.map((elmt) => elmt.id).indexOf(elm)];
      })
      : [];
    return dataList;
  };
  return (
    <div className="child-title-call-flow" style={{ marginBottom: '10px' }}>
      <Dropdown
        multiple
        items={inputItems}
        onChange={(e, val) => {
          return props.getData(
            e,
            val.value.map((elm) => elm.id),
            "weekends",
            props.annoucementName
          );
        }}
        value={getValue(props.data, inputItems)}
        placeholder={intl.formatMessage({ id: "select-weekend-days" })}
        noResultsMessage={intl.formatMessage({ id: "we-couldn't-find-any-matches" })}
      />
    </div>
  );
};
export default Weekend;
