import React, { useState } from 'react'
import { Label, Button } from '@fluentui/react-northstar'
import { PlayIcon, PauseThickIcon, CloseIcon } from '@fluentui/react-icons-northstar'

const PlayPause = (props) => (<Button icon={props.isPaused ? <PlayIcon /> : <PauseThickIcon />} onClick={() => { props.isPaused ? props.audio.play() : props.audio.pause(); props.setIsPaused(!props.isPaused) }} text content={props.content} />)

const AudioLabel = (props) => {
    const [isPaused, setIsPaused] = useState(true)
    const [audio] = useState(new Audio(props.musicObj.url));

    return (<Label content={(<>{<PlayPause isPaused={isPaused} setIsPaused={setIsPaused} content={props.musicObj.header} audio={audio} />} </>)
    }
        circular
        icon={
            <Button
                onClick={() => props.deleteOnHoldMusic(props.musicObj)}
                icon={< CloseIcon />}
                iconOnly
                text
            />}
    />)
}
export default AudioLabel