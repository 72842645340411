/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Input, Button, Checkbox } from "@fluentui/react-northstar";
import { authContext } from "../../auth";
import translate from '../../i18n/translate'
const AudioTranscribe = (props) => {
    authContext.handleWindowCallback();
    const [audioTranscribeIsEnabled, setAudioTranscribeIsEnabled] = useState()
    const handleToggleChange = (e, val) => {
        let value = val.checked;
        if (value === true) {
            setAudioTranscribeIsEnabled(true)
            props.setAudioTranscribeEnabled(true)
        } else {
            setAudioTranscribeIsEnabled(false)
            props.setAudioTranscribeEnabled(false)
        }
    };
    useEffect(() => {
        if (props.audioTranscribeEnabled) {
            setAudioTranscribeIsEnabled(props.audioTranscribeEnabled)
        }

    }, [])
    return (
        <>
            <div id='recording-state' >
                <label >{translate("audio-transcribe")}</label>
                <Checkbox
                    id='checkbox-recording'
                    defaultChecked={props.audioTranscribeEnabled}
                    toggle
                    onChange={handleToggleChange}
                />
            </div>
            {audioTranscribeIsEnabled ?
                <Input
                    className="audioTranscribe-input"
                    defaultValue={props.audioTranscribeDetails}
                    onChange={(e, data) => {
                        props.setAudioTranscribeData(data.value)
                    }}
                />
                :
                null
            }

        </>
    );
};
export default AudioTranscribe;