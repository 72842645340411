import React, { useState, useEffect } from "react";
import { Dropdown, Input, Flex } from "@fluentui/react-northstar";
import CallCenterName from './CallCenterName';
import translate from '../../i18n/translate';
import { useIntl } from 'react-intl';
const Time = (props) => {
    const inputItems = ["Queue", "Transfer to user", "Disconnect", "Routing", "Transfer to phone"]
    const intl = useIntl();
    const [OnMatchedRedirect, setOnMatchedRedirect] = useState(null);
    const [OnUnMatchedRedirect, setOnUnMatchedRedirect] = useState();
    const [startMessage, setStartMessage] = useState();
    const [endMessage, setEndMessage] = useState();
    const parseRegex = require("regex-parser")
    var timeoptions = [
        "00:00",
        "00:15",
        "00:30",
        "00:45",
        "01:00",
        "01:15",
        "01:30",
        "01:45",
        "02:00",
        "02:15",
        "02:30",
        "02:45",
        "03:00",
        "03:15",
        "03:30",
        "03:45",
        "04:00",
        "04:15",
        "04:30",
        "04:45",
        "05:00",
        "05:15",
        "05:30",
        "05:45",
        "06:00",
        "06:15",
        "06:30",
        "06:45",
        "07:00",
        "07:15",
        "07:30",
        "07:45",
        "08:00",
        "08:15",
        "08:30",
        "08:45",
        "09:00",
        "09:15",
        "09:30",
        "09:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00",
        "16:15",
        "16:30",
        "16:45",
        "17:00",
        "17:15",
        "17:30",
        "17:45",
        "18:00",
        "18:15",
        "18:30",
        "18:45",
        "19:00",
        "19:15",
        "19:30",
        "19:45",
        "20:00",
        "20:15",
        "20:30",
        "20:45",
        "21:00",
        "21:15",
        "21:30",
        "21:45",
        "22:00",
        "22:15",
        "22:30",
        "22:45",
        "23:00",
        "23:15",
        "23:30",
        "23:45",
    ];
    const onChangeMatchedType = (value) => {
        switch (value) {
            case "queue":
                setOnMatchedRedirect(
                    <div>
                        <label>{translate("queue-name")}</label>
                        <CallCenterName
                            scope={props.scope}
                            from="regexp"
                            searchQueue
                            itemName="OnMatchedRegexpQueue"
                            getData={props.getData}
                            routingName={props.routingName}
                            displayName={props.data.onMatched !== undefined ? (props.data.onMatched?.queueDisplayName) : ''}
                        />
                    </div>

                )
                break;
            case "transfer to user":
                setOnMatchedRedirect(
                    <div>
                        <label>User name</label>
                        <CallCenterName
                            itemName="OnMatched transfer to user"
                            getData={props.getData}
                            searchactifProfils
                            routingName={props.routingName}
                            displayName={props.data.onMatched !== undefined ? (props.data.onMatched?.userDisplayName) : ''}
                        />
                    </div>
                )
                break;
            case "disconnect":
                props.getData(null, "OnMatchedDisconnect", null, props.routingName)
                setOnMatchedRedirect(null)
                break;
            case "routing":
                let routingListName = Object.keys(props.routingList);
                for (var i = 0; i < routingListName.length; i++) {
                    if (routingListName[i] === "0") {
                        routingListName[i] = 'Start'
                    }
                }
                setOnMatchedRedirect(
                    <div>
                        <label>Routing name</label>
                        <Dropdown
                            className="StaticDropDown"
                            items={routingListName}
                            onChange={(e, data) => {
                                props.getData(data.value, "OnMatchedRouting", null, props.routingName)
                            }}
                            defaultValue={props.data.onMatched !== undefined ? (props.data.onMatched.routingName === "0" ? "Start" : props.data.onMatched.routingName) : ''}
                        />
                    </div>

                )
                break;
            case "transfer to phone":
                setOnMatchedRedirect(
                    <div className="regexp-transfer-to-phone-input">
                        <label>Phone number</label>
                        <Input
                            onChange={(e, data) => {
                                props.getData(data.value, "OnMatched transfer to phone", null, props.routingName)
                            }}
                            defaultValue={props.data.onMatched !== undefined ? (props.data.onMatched?.targetId) : ''}
                        />
                    </div>
                )
                break;
            default:
                if (props.data.onMatched.targetType === "user") {
                    setOnMatchedRedirect(
                        <div>
                            <label>User name</label>
                            <CallCenterName
                                itemName="OnMatched transfer to user"
                                getData={props.getData}
                                searchactifProfils
                                routingName={props.routingName}
                                displayName={props.data.onMatched !== undefined ? (props.data.onMatched?.userDisplayName) : ''}
                            />
                        </div>
                    )
                } else if (props.data.onMatched.targetType === "phone") {
                    setOnMatchedRedirect(
                        <div className="regexp-transfer-to-phone-input">
                            <label>Phone number</label>
                            <Input
                                onChange={(e, data) => {
                                    props.getData(data.value, "OnMatched transfer to phone", null, props.routingName)
                                }}
                                defaultValue={props.data.onMatched !== undefined ? (props.data.onMatched?.targetId) : ''}
                            />
                        </div>
                    )
                }
                break;
        }
    }
    const onChangeUnMatchedType = (value) => {
        switch (value) {
            case "queue":
                setOnUnMatchedRedirect(
                    <div>
                        <label>Queue name</label>
                        <CallCenterName
                            scope={props.scope}
                            from="regexp"
                            searchQueue
                            itemName="OnUnMatchedRegexpQueue"
                            getData={props.getData}
                            routingName={props.routingName}
                            displayName={props.data.onUnMatched !== undefined && props.data.onUnMatched.queueDisplayName !== undefined ? (props.data.onUnMatched.queueDisplayName) : ''}
                        />
                    </div>

                )
                break;
            case "transfer to user":
                setOnUnMatchedRedirect(
                    <div>
                        <label>User name</label>
                        <CallCenterName
                            itemName="OnUnMatched transfer to user"
                            getData={props.getData}
                            searchactifProfils
                            routingName={props.routingName}
                            displayName={props.data.onUnMatched !== undefined && props.data.onUnMatched.userDisplayName !== undefined ? (props.data.onUnMatched.userDisplayName) : ''}
                        />
                    </div>
                )
                break;
            case "disconnect":
                props.getData(null, "OnUnMatchedDisconnect", null, props.routingName)
                setOnUnMatchedRedirect()
                break;
            case "routing":
                let routingListName = Object.keys(props.routingList);

                for (var i = 0; i < routingListName.length; i++) {
                    if (routingListName[i] === "0") {
                        routingListName[i] = 'Start'
                    }
                }
                setOnUnMatchedRedirect(
                    <div>
                        <label>Routing name</label>
                        <Dropdown
                            className="StaticDropDown"
                            items={routingListName}
                            onChange={(e, data) => {
                                props.getData(data.value, "OnUnMatchedRouting", null, props.routingName)
                            }}
                            defaultValue={props.data.onUnMatched !== undefined ? (props.data.onUnMatched.routingName === "0" ? "Start" : props.data.onUnMatched.routingName) : null}
                        />
                    </div>

                )
                break;
            case "transfer to phone":
                setOnUnMatchedRedirect(
                    <div className="regexp-transfer-to-phone-input">
                        <label>Phone number</label>
                        <Input
                            onChange={(e, data) => {
                                props.getData(data.value, "OnUnMatched transfer to phone", null, props.routingName)
                            }}
                            defaultValue={props.data.onUnMatched !== undefined && props.data.onUnMatched.targetId !== undefined ? (props.data.onUnMatched.targetId) : ''}
                        />
                    </div>
                )
                break;
            default:
                if (props.data.onUnMatched.targetType === "user") {
                    setOnUnMatchedRedirect(
                        <div>
                            <label>User name</label>
                            <CallCenterName
                                itemName="OnUnMatched transfer to user"
                                getData={props.getData}
                                searchactifProfils
                                routingName={props.routingName}
                                displayName={props.data.onUnMatched !== undefined && props.data.onUnMatched.userDisplayName !== undefined ? (props.data.onUnMatched.userDisplayName) : ''}
                            />
                        </div>

                    )
                } else if (props.data.onUnMatched.targetType === "phone") {
                    setOnUnMatchedRedirect(
                        <div className="regexp-transfer-to-phone-input">
                            <label>Phone number</label>
                            <Input
                                onChange={(e, data) => {
                                    props.getData(data.value, "OnUnMatched transfer to phone", null, props.routingName)
                                }}
                                defaultValue={props.data.onUnMatched !== undefined && props.data.onUnMatched.targetId !== undefined ? (props.data.onUnMatched.targetId) : ''}
                            />
                        </div>
                    )
                }
                break;
        }
    }
    const startTimeValidation = (value) => {
        const regexForm = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        try {
            if (regexForm.exec(value)) {
                setStartMessage("")
                props.setStartTime(value, props.routingName)
            } else {
                setStartMessage(
                    <div className=" alert alert-danger" style={{ marginTop: '10px', marginLeft: '50px', width: '355px' }}>
                        <p >{translate('Start time: You need respect this form hh:mm !')}</p>
                    </div>
                )
            }
        } catch (error) {
            setStartMessage(
                <div className=" alert alert-danger" style={{ marginTop: '10px', marginLeft: '50px', width: '355px' }}>
                    <p >{translate('Start time: You need respect this form hh:mm !')}</p>
                </div>
            )
        }


    }
    const endTimeValidation = (value) => {
        const regexForm = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        try {
            if (regexForm.exec(value)) {
                setEndMessage("")
                props.setEndTime(value, props.routingName)
            } else {
                setEndMessage(
                    <div className=" alert alert-danger" style={{ marginTop: '10px', marginLeft: '50px', width: '355px' }}>
                        <p >{translate('End time: You need respect this form hh:mm !')}</p>
                    </div>
                )
            }
        } catch (error) {
            setEndMessage(
                <div className=" alert alert-danger" style={{ marginTop: '10px', marginLeft: '50px', width: '355px' }}>
                    <p >{translate('End time: You need respect this form hh:mm !')}</p>
                </div>
            )
        }


    }
    useEffect(() => {
        if (props.data.onMatched !== undefined) {
            onChangeMatchedType(props.data.onMatched.type)
        }
    }, []);
    useEffect(() => {
        if (props.data.onUnMatched !== undefined) {
            onChangeUnMatchedType(props.data.onUnMatched.type)
        }
    }, []);
    return (
        <>
            <Flex>
                <div className="row">
                    <div className="col-5">
                        <div className="row" style={{ marginLeft: '35px', width: '385px' }}>
                            <div className="col-6" id="start-time">
                                <label>{intl.formatMessage({ id: "start-time" })}</label>
                                <Input
                                    defaultValue={props.data.Time !== undefined ? props.data.Time.start : null}
                                    placeholder="hh:mm"
                                    onBlur={(data) => {
                                        startTimeValidation(data.target.value)
                                    }}
                                />
                            </div>
                            <div className="col-6" id="end-time">
                                <label>{intl.formatMessage({ id: "end-time" })}</label>
                                <Input
                                    placeholder="hh:mm"
                                    defaultValue={props.data.Time !== undefined ? props.data.Time.end : null}
                                    onBlur={(data) => {
                                        endTimeValidation(data.target.value)
                                    }}
                                />

                            </div>
                        </div>
                        {startMessage}
                        {endMessage}
                        <div className="row" style={{ justifyContent: 'space-around', marginTop: '10px' }}>
                            <div className="col-12" style={{ marginLeft: '100px' }}>
                                <label style={{ fontWeight: '700' }}>{translate('if-matched')}</label>
                                <div >
                                    <div style={{ marginBottom: '10px' }}>
                                        <Dropdown
                                            items={inputItems}
                                            checkable
                                            getA11ySelectionMessage={{
                                                onAdd: item => `${item} has been selected.`,
                                            }}
                                            onChange={(e, data) => {
                                                let value = data.searchQuery.toLowerCase();
                                                props.setOnMatched(value, props.routingName);
                                                onChangeMatchedType(value);
                                            }}
                                            defaultValue={
                                                props.data.onMatched !== undefined && props.data.onMatched.type === "transfer" ?
                                                    props.data.onMatched.targetType === "user" ?
                                                        "Transfer to user"
                                                        :
                                                        "Transfer to phone"
                                                    :
                                                    props.data.onMatched !== undefined ?
                                                        (props.data.onMatched.type.charAt(0).toUpperCase() + props.data.onMatched.type.slice(1))
                                                        :
                                                        null
                                            }
                                        />
                                        {OnMatchedRedirect}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" style={{ marginLeft: '100px', marginTop: '10px' }}>
                                <label style={{ fontWeight: '700' }}>{translate('if-not-matched')}</label>
                                <div >
                                    <div style={{ marginBottom: '10px' }}>
                                        <Dropdown
                                            items={inputItems}
                                            checkable
                                            getA11ySelectionMessage={{
                                                onAdd: item => `${item} has been selected.`,
                                            }}
                                            onChange={(e, data) => {
                                                let value = data.searchQuery.toLowerCase();
                                                props.setOnUnMatched(value, props.routingName);
                                                onChangeUnMatchedType(value)
                                            }}
                                            defaultValue={
                                                props.data.onUnMatched !== undefined && props.data.onUnMatched.type === "transfer" ?
                                                    props.data.onUnMatched.targetType === "user" ?
                                                        "Transfer to user"
                                                        :
                                                        "Transfer to phone"
                                                    :
                                                    props.data.onUnMatched !== undefined ?
                                                        (props.data.onUnMatched.type.charAt(0).toUpperCase() + props.data.onUnMatched.type.slice(1))
                                                        :
                                                        null
                                            }
                                        />
                                        {OnUnMatchedRedirect}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Flex>
        </>
    )
}
export default Time