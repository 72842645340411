import { Flex, Dropdown, Text, Input } from "@fluentui/react-northstar";
import React, {
  useState,
  useEffect,
} from "react";
import CallCenterName from "../../components/CallCenterComponents/CallCenterName";


const PromptContainer = (props) => {
  const inputItems = ["Queue", "Transfer to user", "Disconnect", "Routing", "Transfer to phone"];
  const [next, setNext] = useState();
  const setRedirectComponent = (val) => {
    switch (val) {
      case "Queue":
        setNext(
          <Flex gap="gap.small" vAlign="center">
            <CallCenterName
             scope={props.scope}
              itemName="promptQueue"
              displayName={props.data.next?.queueDisplayName}
              getData={props.getData}
              searchQueue
              searchactifProfils
              routingName={props.routingName}
            />
          </Flex>
        )
        break;
      case "Transfer to user":
        setNext(
          <Flex gap="gap.small" vAlign="center">
            <CallCenterName
              itemName="prompt transfer to user"
              getData={props.getData}
              searchactifProfils
              routingName={props.routingName}
              displayName={props.data.next?.userDisplayName}
            />
          </Flex>
        )
        break;
      case "Routing":
        let routingListName = Object.keys(props.routingList);

        for (var i = 0; i < routingListName.length; i++) {
          if (routingListName[i] === "0") {
            routingListName[i] = 'Start'
          }
        }

        setNext(
          <Dropdown
            defaultValue={props.data.next.routingName === "0" ? "Start" : props.data.next.routingName}
            className="StaticDropDown"
            items={routingListName}
            onChange={(e, data) => {
              props.getData(data.value, "promptRouting", null, props.routingName)
            }}
          />
        )
        break;
      case "Disconnect":
        props.getData(null, "promptDisconnect", null, props.routingName)
        setNext(null)
        break;
      case "Transfer to phone":
        setNext(
          <Flex gap="gap.small" vAlign="center">
            <Input
              defaultValue={props.data.next?.targetId}
              style={{ width: '253px' }}
              onChange={(e, data) => {
                props.getData(data.value, "prompt transfer to phone", null, props.routingName)
              }
              }
            />
          </Flex>
        )
        break;
      default:
        if (props.data.next.targetType === "user") {
          setNext(
            <Flex gap="gap.small" vAlign="center">
              <CallCenterName
                itemName="prompt transfer to user"
                getData={props.getData}
                searchactifProfils
                routingName={props.routingName}
                displayName={props.data.next?.userDisplayName}
              />
            </Flex>
          )
        } else if (props.data.next.targetType === "phone") {
          setNext(
            <Flex gap="gap.small" vAlign="center">
              <Input
                defaultValue={props.data.next?.targetId}
                style={{ width: '253px' }}
                onChange={(e, data) => {
                  props.getData(data.value, "prompt transfer to phone", null, props.routingName)
                }
                }
              />
            </Flex>
          )
        }
        break;
    }
  }
  useEffect(() => {
    setRedirectComponent(props.data.next.type.charAt(0).toUpperCase() + props.data.next.type.slice(1))
  }, [props.data.next.type]);
  return (
    <div style={{ display: 'flex' }}>
      <Dropdown
        defaultValue={
          props.data.next !== undefined && props.data.next.type === "transfer" ?
            props.data.next.targetType === "user" ?
              "Transfer to user"
              :
              "Transfer to phone"
            :
            props.data.next !== undefined ?
              (props.data.next.type.charAt(0).toUpperCase() + props.data.next.type.slice(1))
              :
              null
        }
        className="StaticDropDown"
        items={inputItems}
        checkable
        onChange={(e, val) => {
          setRedirectComponent(val.value)
        }}
      />
      <div style={{ marginLeft: '20px' }}> {next}</div>

    </div>

  );

};

export default PromptContainer;