/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CallMaxWaitTimeoutContainer from "../../containers/Queues/CallMaxWaitTimeContainer";
import {
  CloseIcon,
  AcceptIcon,
  TrashCanIcon,
  InfoIcon,
} from "@fluentui/react-icons-northstar";
import VipPriority from "./vipPriority";
import {
  Header,
  Dropdown,
  List,
  Avatar,
  Input,
  Button,
  RadioGroup,
  Checkbox,
  AddIcon,
  Table,
  Label,
  Text,
} from "@fluentui/react-northstar";
import { getAllAudioManagementUrl } from '../../urls'
import { getToken } from '../Graph'
import { useHistory } from "react-router-dom";
import AudioLabel from "../audioLabel";
import Axios from 'axios'
import { authContext } from "../../auth";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useIntl } from "react-intl";
import SearchAgent from "../../components/Queues/searchAgent";
import translate from "../../i18n/translate";
import QueueFallBackMessage from './QueueFallBackMessageComponent'
const HoldOnMusic = (props) => {
  const intl = useIntl();

  useEffect(() => {
    props.queryOnHoldMusic("text");
  }, [props.queryOnHoldMusic]);
  const getA11ySelectionMessage = {
    onAdd: (item) => {
      props.addHoldOnMusic(item);
    },
  };
  return (
    <Dropdown
      className="from-item"
      search
      items={props.musicSearchResults}
      placeholder={intl.formatMessage({ id: "start-typing-a-name" })}
      getA11ySelectionMessage={getA11ySelectionMessage}
      noResultsMessage={intl.formatMessage({
        id: "we-couldn't-find-any-matches",
      })}
    />
  );
};
const QueueComponent = (props) => {
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [successSave, setSuccessSave] = useState(false);
  const [errorSave, setErrorSave] = useState(false);
  const [audioList, setAudioList] = useState([]);
  const [scopeListName, setScopeListName] = useState([]);
  const [isNewVipPriority, setIsNewVipPriority] = useState(false);
  const [waitTimeInterval, setWaitTimeInterval] = useState(null);
  const [
    announceEstimatedWaitTimeErrorMessage,
    setAnnounceEstimatedWaitTimeErrorMessage,
  ] = useState("");

  const intl = useIntl();
  const params = useParams();
  const header = {
    items: [
      intl.formatMessage({ id: "agent-name" }),
      intl.formatMessage({ id: "email" }),
      intl.formatMessage({ id: "skill" }),
      intl.formatMessage({ id: "action" }),
    ],
  };
  const confirmeRemoveCallAnsewring = async (id, name) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message:
        intl.formatMessage({ id: "are-you-sure-to-remove" }) + name + "!",
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => props.deleteAnsweringAgent(id),
        },
        {
          label: intl.formatMessage({ id: "no" }),
        },
      ],
    });
  };
  const confirmeRemoveVipContact = async (id, name) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message:
        intl.formatMessage({ id: "are-you-sure-to-remove" }) + name + "!",
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => props.deleteVipContact(id),
        },
        {
          label: intl.formatMessage({ id: "no" }),
        },
      ],
    });
  };
  const getHuntMethodItems = () => {
    return [
      {
        name: "Attendant",
        key: "attendant",
        label: intl.formatMessage({ id: "attendant" }),
        value: "attendant",
      },
      {
        name: "Serial",
        key: "serial",
        label: intl.formatMessage({ id: "serial" }),
        value: "serial",
      },
      {
        name: "RoundRobin",
        key: "roundRobin",
        label: intl.formatMessage({ id: "roundRobin" }),
        value: "roundRobin",
      },
      {
        name: "longestIdle",
        key: "longestIdle",
        label: intl.formatMessage({ id: "longestIdle" }),
        value: "longestIdle",
      },
    ];
  };
  const HandleAgentSkill = (agentId, skill) => {
    props.setAgentSkill(agentId, skill);
  };
  const getItems = (agents) => {
    let items = [];
    if (agents) {
      for (let [key, value] of Object.entries(agents)) {
        items.push({
          key: key,
          items: [
            <div key={key}>
              <Avatar name={value?.displayName} /> {value?.displayName}{" "}
            </div>,
            <div>
              <span>{value?.email}</span>
            </div>,
            <div>
              {" "}
              <Input
                type="range"
                value={value?.skill}
                min="0"
                max="100"
                step="1"
                onChange={(data) => {
                  HandleAgentSkill(key, data.target.value);
                }}
              />{" "}
              {value?.skill + "%"}
            </div>,
            <Button
              icon={<TrashCanIcon />}
              onClick={(e, data) =>
                confirmeRemoveCallAnsewring(key, value?.displayName)
              }
              text
            />,
          ],
        });
      }
      return items;
    } else return [];
  };
  const History = useHistory();
  const handleChangeHuntmethod = (e, data) => {
    props.setHuntMethod(data.value);
  };
  const HandleMaxChange = (e) => props.setMaxCalls(e.target.value);
  const HandleMaxWaitTimeChange = (e) => props.setMaxWaitTime(e.target.value);
  const HandleQueueNameChange = (e) => props.setQueueName(e.target.value);
  const HandleQueueLanguageChange = (langue) => props.setQueueLanguage(langue);
  // const HandleMaxAlertTimeChange = (e) => props.setMaxAlertTime(e.target.value);
  const HandleSlaThreshold = (e) => props.setSlaThreshold(Number(e.target.value));
  const HandleSla = (e) => props.setSla(Number(e.target.value));
  const setAnnouncePositionInQueue = (IsOrder) =>
    props.setAnnouncePositionInQueue(IsOrder);
  const setAnnounceEstimatedWaitTime = (Boolean) =>
    props.setAnnounceEstimatedWaitTime(Boolean);
  const setVipContact = (contact, queueInfos) =>
    props.setVipContact(contact, queueInfos);
  const setVipPeriority = (enableVipPriority) =>
    props.setVipPeriority(enableVipPriority);
  const HandleQueueScopeChange = (scope) => props.setQueueScope(scope);
  const emptyQueueAgent = () => props.emptyQueueAgent();
  const inputItemsLanguage = [
    `es-ES`,
    "en-US",
    "fr-FR",
    "fr-CA",
    "en-EN",
    "it-IT",
    "de-DE",
  ];
  const authorizedActivityValuesItems = [
    "Available",
    "Away",
    "BeRightBack",
    "Busy",
    "InAConferenceCall",
    "Inactive",
    "Offline",
    "InAMeeting",
    "OffWork",
    "OutOfOffice",
    "PresenceUnknown",
    "Presenting",
    "UrgentInterruptionsOnly",
    "InACall"
  ];
  const getVipList = (vipContacts) => {
    let items = [];

    if (vipContacts) {
      for (let [key, value] of Object.entries(vipContacts)) {
        let name = value?.displayName;
        let title = name;
        if (value.type === "phone") {
          title = key;
        }
        if (name === "") {
          name = key;
        }
        items.push({
          key: key,
          media: <Avatar name={name} />,
          header: { content: name, title: title },
          headerMedia: {
            content: (
              <Button
                icon={<TrashCanIcon />}
                onClick={(e, data) => confirmeRemoveVipContact(key, name)}
                text
              />
            ),
            title: name,
          },
        });
      }
      return items;
    } else return [];
  };
  const getAudios = async () => {
    getToken(authContext).then(async (Token) => {
      const url = getAllAudioManagementUrl(
        authContext._user.profile.tid,
        Token
      );
      Axios.get(url).then((res) => {
        setAudioList(res.data);
      });
    });
  };
  useEffect(() => {
    if (props.scopeList) {
      let scopeListName = Object.keys(props.scopeList);
      for (const scope of scopeListName) {
        let adminIds = props.scopeList[scope].admins.adminsIds;
        if (adminIds.includes(authContext._user.profile.oid)) {
          setScopeListName((scopeListName) => [...scopeListName, scope]);
        }
      }
    }
  }, [props.scopeList]);
  useEffect(() => {
    if (props.queueInfos?.scope === "" && scopeListName.length > 0) {
      if (scopeListName.includes("default")) {
        HandleQueueScopeChange("default");
      } else {
        HandleQueueScopeChange(scopeListName[0]);
      }
    }
  }, [scopeListName]);
  useEffect(() => {
    getAudios()
  }, [])
  return (
    <div className="form-container">
      <div style={{ marginBottom: "25px", display: "flex" }}>
        <div>
          <Header
            as="h4"
            content={intl.formatMessage({ id: "queue-name" })}
            className="label-form_queue"
          />
          <Input
            className="from-item"
            value={props.queueInfos?.queueName}
            onChange={HandleQueueNameChange}
            required
          />
        </div>
        <div style={{ marginLeft: "50px" }}>
          <Header
            as="h4"
            content={intl.formatMessage({ id: "scope" })}
            className="label-form_queue"
          />
          <Dropdown
            className="from-item"
            value={props.queueInfos?.scope}
            items={scopeListName}
            placeholder={intl.formatMessage({ id: "scope" })}
            onChange={(e, props) => {
              emptyQueueAgent();
              HandleQueueScopeChange(props.value);
            }}
          />
        </div>
      </div>
      <div
        className="announce_position_in_queue"
        style={{ justifyContent: "space-between", width: "405px" }}
      >
        <Header
          as="h4"
          content={intl.formatMessage({ id: "announce-position-in-queue" })}
          className="label-form_queue header-announce_position_in_queue"
        />
        <Checkbox
          id="announce-position-checkbox"
          toggle
          defaultChecked={props.queueInfos?.announcePositionInQueue}
          onChange={(e, props) => {
            setAnnouncePositionInQueue(props.checked);
          }}
        />
      </div>
      <div
        className="announce_position_in_queue"
        style={{ justifyContent: "space-between", width: "405px" }}
      >
        <Header
          as="h4"
          content={intl.formatMessage({ id: "announce-estimated-wait-time" })}
          className="label-form_queue header-announce_position_in_queue"
        />
        <Button
          icon={<InfoIcon />}
          iconOnly
          text
          title={intl.formatMessage({ id: "waitTime_interval_hint" })}
        />
        <Checkbox
          id="announce-position-checkbox"
          toggle
          defaultChecked={props.queueInfos?.announceEstimatedWaitTime}
          onChange={(e, props) => {
            setAnnounceEstimatedWaitTime(props.checked);
          }}
        />
      </div>
      <Text color="red" content={announceEstimatedWaitTimeErrorMessage} />
      <div style={{ display: "flex", marginTop: "15px" }}>
        {props.queueInfos &&
          props.queueInfos.waitTimeIntervals &&
          props.queueInfos.waitTimeIntervals.length > 0
          ? props.queueInfos.waitTimeIntervals.map((index, i) => {
            return (
              <div className="waitTimeIntervals-label" key={i}>
                {" "}
                <Label
                  content={index}
                  icon={
                    <CloseIcon
                      className="delete-waitTimeInterval-btn"
                      onClick={() => {
                        props.deleteWaitTimeInterval(index);
                      }}
                    />
                  }
                  style={{ marginRight: "5px", marginBottom: "5px" }}
                />
              </div>
            )
          })
          : null}
        {(props.queueInfos &&
          props.queueInfos.announceEstimatedWaitTime === false) ||
          (props.queueInfos &&
            props.queueInfos.announceEstimatedWaitTime === true &&
            props.queueInfos.waitTimeIntervals &&
            props.queueInfos.waitTimeIntervals.length > 4) ? null : (
          <div style={{ marginBottom: "10px" }} className="waitTimeIntervals">
            <Input
              clearable
              type="number"
              onChange={(e) => {
                setWaitTimeInterval(e.target.value);
              }}
            />
            <Button
              primary
              icon={<AddIcon />}
              text
              content={intl.formatMessage({ id: "add" })}
              onClick={() => {
                if (
                  waitTimeInterval > 0 ||
                  (props.queueInfos &&
                    props.queueInfos.waitTimeIntervals &&
                    props.queueInfos.waitTimeIntervals.includes(
                      waitTimeInterval
                    ))
                ) {
                  setAnnounceEstimatedWaitTimeErrorMessage("");
                  props.setWaitTimeIntervals(waitTimeInterval);
                  setWaitTimeInterval(null);
                } else {
                  setAnnounceEstimatedWaitTimeErrorMessage(
                    translate("worning-time-interval-input")
                  );
                }
              }}
            />
          </div>
        )}
      </div>
      <Header
        id="language-header"
        as="h4"
        content={intl.formatMessage({ id: "language" })}
      />
      <div className="from-item">
        <Dropdown
          value={props.queueInfos?.language}
          items={inputItemsLanguage}
          placeholder={intl.formatMessage({ id: "language" })}
          checkable
          onChange={(e, props) => {
            HandleQueueLanguageChange(props.value);
          }}
          getA11ySelectionMessage={{
            onAdd: (item) => `${item} has been selected.`,
          }}
        />
      </div>
      <Header
        as="h4"
        content={intl.formatMessage({ id: "music-on-hold" })}
        className="label-form_queue"
      />
      <HoldOnMusic
        addHoldOnMusic={props.addHoldOnMusic}
        queryOnHoldMusic={props.queryOnHoldMusic}
        musicSearchResults={props.musicSearchResults}
      />
      {props.queueInfos?.musicOnHold.map((music, index) => {
        return (
          <span
            key={index}
            style={{
              marginLeft: "50px",
              display: "block",
              marginBottom: "15px",
            }}
          >
            <AudioLabel
              musicObj={music}
              deleteOnHoldMusic={props.deleteOnHoldMusic}
            />
          </span>
        );
      })}
      <Header
        as="h4"
        content={intl.formatMessage({ id: "max-wait-time" })}
        className="label-form_queue"
      />
      <Input
        className="from-item"
        clearable
        value={props.queueInfos?.maxWaitTime}
        onChange={HandleMaxWaitTimeChange}
      />
      <Header
        as="h4"
        content={intl.formatMessage({ id: "max-calls-in-queue" })}
        className="label-form_queue"
      />
      <Input
        className="from-item"
        clearable
        value={props.queueInfos?.maxInQueue}
        onChange={HandleMaxChange}
      />
      <div className="fallbackIfNoAgentConnected">
        <Header
          as="h4"
          content={intl.formatMessage({ id: "fallbackIfNoAgentConnected" })}
          className="label-form_queue"
        />
        <Checkbox
          id="announce-position-checkbox"
          toggle
          defaultChecked={props.queueInfos?.fallbackIfNoAgentConnected}
          onChange={(e, data) => {
            props.setFallbackIfNoAgentConnected(data.checked);
          }}
        />
      </div>
      <div className="playMessageBeforeQueueFallBack">
        <Header
          as="h4"
          content={intl.formatMessage({ id: "playMessageBeforeQueueFallBack" })}
          className="label-form_queue"
        />
        <Checkbox
          id="announce-position-checkbox"
          toggle
          defaultChecked={props.queueInfos?.queueFallBackMessage?.enabled}
          onChange={(e, data) => {
            props.playMessageBeforeQueueFallBack(data.checked);
          }}
        />
      </div>
      {
        props.queueInfos?.queueFallBackMessage && props.queueInfos?.queueFallBackMessage?.enabled === true ?
          <QueueFallBackMessage
            audioList={audioList}
            queueFallBackMessage={props.queueInfos?.queueFallBackMessage}
            setQueueFallBackMessage={props.setQueueFallBackMessage}
            defaultLanguage={props.queueInfos?.language}
            queueName={props.queueInfos?.queueName}
          />
          :
          null
      }

      <CallMaxWaitTimeoutContainer {...props} scope={props.queueInfos?.scope} />
      <Header
        as="h4"
        content={intl.formatMessage({ id: "authorizedActivityValues" })}
        className="label-form_queue"
      />
      <Dropdown
        className="authorizedActivityValues-dropDown  from-item"
        multiple
        search
        value={props.queueInfos?.authorizedActivityValues}
        items={authorizedActivityValuesItems}
        placeholder="Start typing a name"
        onChange={(e, data) => {
          props.setAuthorizedActivityValues(data.value);
        }}
      />
      <Header
        as="h4"
        content={intl.formatMessage({ id: "agents-list" })}
        className="label-form_queue"
      />
      <SearchAgent
        scope={props.queueInfos?.scope}
        addAnsweringAgent={props.addAnsweringAgent}
      />
      <div id="call-answering-div">
        <Table
          header={header}
          id="agents-list-table"
          rows={getItems(props.answeringAgents)}
          aria-label="Static table"
        />
      </div>
      <Header
        as="h4"
        content={intl.formatMessage({ id: "hunt-method" })}
        className="label-form_queue"
      />
      <RadioGroup
        className="from-item"
        vertical
        checkedValue={props.huntMethod}
        items={getHuntMethodItems()}
        onCheckedValueChange={handleChangeHuntmethod}
      />
      <Header
        as="h4"
        content={intl.formatMessage({ id: "WRAP_UP_TIME" })}
        className="label-form_queue"
      />
      <Input
        className='queue-number-input'
        value={props.queueInfos?.wrapUpTime}
        style={{ marginRight: "15px", marginLeft: '50px', width: '70px' }}
        onChange={(e, data) => {
          props.setwrapUpTime(Number(data.value))
        }}
        type="number"
      />
      <span> Seconds</span>
      <div className="announce_position_in_queue">
        <Header
          as="h4"
          content={intl.formatMessage({ id: "VIP-priority" })}
          className="label-form_queue header-announce_position_in_queue"
        />
        <Checkbox
          id="vip-priority-checkbox"
          toggle
          defaultChecked={props.queueInfos?.enableVipPriority}
          onChange={(e, props) => {
            setVipPeriority(props.checked);
          }}
        />
      </div>
      <div className="vip-priority-div" style={{ marginLeft: "50px" }}>
        {isNewVipPriority ? (
          <div className="VipPriority-div">
            <VipPriority
              VIPPRIORITY
              setVipContact={setVipContact}
              queueInfos={props.queueInfos}
              setIsNewVipPriority={setIsNewVipPriority}
            />
          </div>
        ) : (
          <div className="add-vip-contact-btn">
            <Button
              primary
              icon={<AddIcon />}
              text
              content={intl.formatMessage({ id: "add" })}
              onClick={() => setIsNewVipPriority(true)}
            />
          </div>
        )}
        <List
          className="from-item call-ansewering-list"
          items={getVipList(props.queueInfos?.vipDetails)}
        />
      </div>
      <Header as="h4" content="SLA" className="label-form_queue" />
      <div id="sla-div">
        <Header className="item-sla" as="h6" content="SLA" />
        <Input
          className='queue-number-input'
          value={props.queueInfos?.sla}
          style={{ marginRight: "15px", width: '70px' }}
          onChange={HandleSla}
          type="number"
        />
        <span> Seconds</span>
        <Header
          as="h6"
          className="item-sla"
          content={intl.formatMessage({ id: "SLA-threshold" })}
        />
        <Input
          className='queue-number-input'
          value={props.queueInfos?.slaThreshold}
          style={{ marginRight: "15px", width: '70px' }}
          onChange={HandleSlaThreshold}
          type="number"
        />
        <span> %</span>
      </div>
      {!isSaveLoading && successSave ? <AcceptIcon /> : ""}
      {!isSaveLoading && errorSave ? <CloseIcon /> : ""}
    </div>
  );
};
export default QueueComponent;
