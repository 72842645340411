/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from "react-redux";
import { setLicences } from "../../redux/actions";
import AgentComponent from "../../components/Agent/AgentComponent"
const mapStateToProps = (state, props) => {
    return {
        licences: state.licences,
        scopeList: state.scopeList,
        callFlows: state.callFlows
    };
};
const mapDispatchToProps = (dispatch) => ({
    setLicences: (Licences) => { dispatch(setLicences(Licences)) },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AgentComponent);
