import React, { useState } from "react";
import {
  RadioGroup,
  Header,
  Dropdown,
  Checkbox,
  Flex,
} from "@fluentui/react-northstar";
import Text2Speech from "./text2Speech";
import { useIntl } from 'react-intl';
import translate from '../../i18n/translate'

const AnnoucementComponent = ({ audiosList, ...props }) => {
  const [local, setLocal] = useState(props);
  const intl = useIntl();
  const items = [
    {
      name: "Text",
      key: "text",
      label: intl.formatMessage({ id: "text" }),
      value: "text2speech",
    },
    {
      name: "Audio",
      key: "audio",
      label: intl.formatMessage({ id: "audio" }),
      value: "Audiofile",
    },
  ];
  const handleChange = (e, val) => {
    setCheckbox(val.value);
    props.getData(e, val.value, "radio", props.annoucementName);
  };
  const getDropDValue = (audioList, url) => {
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const audioIndex = audioList.map((elm) => elm.header).indexOf(fileName);
    return audioIndex > -1 ? audioList[audioIndex] : "";
  };
  const handleToggleChange = (e, val) => {
    let value = val.checked;
    props.getData(e, val.checked, "enable", props.annoucementName);
    setLocal((prevState) => {
      return {
        ...prevState,
        data: { ...prevState.data, enabled: value },
      };
    });
  };
  const [checkboxValue, setCheckbox] = useState(local?.data?.type);
  return (
    <div className="hasChekbox">
      <Flex hAlign="start" vAlign="center" gap="gap.small">
        {!["ivr", "prompt"].includes(props.annoucementName) ? (
          <Header as="h4" content={intl.formatMessage({ id: local.headerTitle })} />
        ) : (
          ""
        )}
        {!["holidays", "openHours", "ivr", "prompt"].includes(props.annoucementName) ? (
          <Checkbox
            className="child-title-call-flow"
            toggle
            checked={local.data.enabled}
            onClick={handleToggleChange}
          />
        ) : (
          ""
        )}
      </Flex>
      {
        ["holidays"].includes(props.annoucementName) ?
          <Header as="h5" content={intl.formatMessage({ id: "holiday-message" })} className="child-title-call-flow holiday-message" />
          :
          null
      }
      {
        ["openHours"].includes(props.annoucementName) ?
          <Header as="h5" content={intl.formatMessage({ id: "out-of-business-hours-message" })} className="child-title-call-flow holiday-message" />
          :
          null
      }
      <div>
        <RadioGroup
          className="child-title-call-flow"
          checkedValue={checkboxValue}
          items={items}
          onCheckedValueChange={handleChange}
        />
        {(() => {
          if (checkboxValue !== "disabled") {
            if (checkboxValue === "text2speech") {
              return (
                <div>
                  <Text2Speech
                    routingName={props?.routingName}
                    getIvrText={props.getIvrText}
                    data={props.data}
                    className="child-title-call-flow"
                    annoucementName={props.annoucementName}
                    getData={props.getData}
                    directory={props.annoucementName === "ivr" || props.annoucementName === "prompt" ? props.routingName : local.headerTitle}
                    language={props.data.language}
                    text={props.data.text}
                    callFlowId={props.callFlowId}
                  />
                </div>
              );
            } else
              if (props.annoucementName === "ivr" || props.annoucementName === "prompt") {
                return (
                  <Dropdown
                    className="child-title-call-flow"
                    onChange={(e, data) =>
                      props.annoucementName === "ivr" ? props.getSelectedIvrData(data.value, props?.routingName) : props.getSelectedPromptData(data.value, props?.routingName)
                    }
                    value={getDropDValue(audiosList, props?.data?.audioFileUrl)}
                    items={audiosList}
                    placeholder={intl.formatMessage({ id: "select-audio" })}
                    noResultsMessage={intl.formatMessage({ id: "we-couldn't-find-any-matches" })}
                    getA11ySelectionMessage={{
                      onAdd: (item) => `${item} has been selected.`,
                    }}
                  />
                );
              } else {
                return (
                  <Dropdown
                    className="child-title-call-flow"
                    onChange={(e, data) =>
                      props.getData(e, data.value, "audio", props?.annoucementName)
                    }
                    value={getDropDValue(audiosList, props?.data?.audioFileUrl)}
                    items={audiosList}
                    placeholder={intl.formatMessage({ id: "select-audio" })}
                    noResultsMessage={intl.formatMessage({ id: "we-couldn't-find-any-matches" })}
                    getA11ySelectionMessage={{
                      onAdd: (item) => `${item} has been selected.`,
                    }}
                  />
                );
              }
          } else return <></>;
        })()}
      </div>
    </div>
  );
};
export default AnnoucementComponent;
