/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { authContext } from "../../auth";
import Axios from "axios";
import {
  TrashCanIcon,
  AddIcon,
  EditIcon,
} from "@fluentui/react-icons-northstar";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Loader } from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import { Table } from "@fluentui/react-northstar";
import {getToken} from "../Graph"
import { getAllChatFlowManagementUrl, removeChatFlowManagementUrl } from "../../urls"
import { useIntl } from 'react-intl';
import translate from '../../i18n/translate'
const ListExampleSelectable = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [chatFlowListTable, setChatFlowListTable] = useState([]);
  const history = useHistory();
  const intl = useIntl();
  const removeChatFlow = async (elm,chatFlowId,scope) => {
    let Token = await getToken(authContext);
    const url = removeChatFlowManagementUrl(authContext._user.profile.tid, Token, chatFlowId,scope);
    Axios.post(url).then((res) => {
      setChatFlowListTable((chatFlowListTable) => {
        const newState = chatFlowListTable.filter((data) => {
          return data.key !== elm;
        });
        return newState;
      });
      setIsLoading(false);
    });
  };
  const addChatFlow = () => {
    history.push(`/chat-flow/newChatFlow`);
  };
  const selectChatFlow = (event, data) => {
    history.push(`/chat-flow/${data.id}/${data.scope}`);
  };
  const getChatFlowData = async () => {
    let Token = await getToken(authContext);
    let scopeListName = Object.keys(props.scopeList)
    const promises = []
    for (let scope of scopeListName) {
      const url = getAllChatFlowManagementUrl(authContext._user.profile.tid, Token,scope);
      await Axios.get(url).then((res) => {
        promises.push(res.data)
      })
    }
    Promise.all(promises).then((results) => {
      var newChatflowList = []
      for (var result of results) {
        if (Object.keys(result).length > 0) {
          newChatflowList = newChatflowList.concat(result);   ;
        }
      }
      return newChatflowList
    }).then((newChatflowList) => {
      formatRawToList(newChatflowList)
      setIsLoading(false);
    })
  };
  const confirmeRemoveChatflow = (elm, chatFlowName,chatFlowId,scope) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + chatFlowName + '!',
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => removeChatFlow(elm,chatFlowId,scope)
        },
        {
          label: intl.formatMessage({ id: "no" }),
        }
      ]
    });
  };
  const formatRawToList = (data) => { 
    return data.map((elm, i) => {
      let rowTable = {
        key: i,
        items: [
          {
            content: elm.displayName,
            key: i + "-1",
          },
          {
            content: elm.scope,
            key: i + "-9",
          },
          {
            content: elm.timeZone,
            key: i + "-2",
          },
          {
            content: elm.language,
            key: i + "-3",
          },
          {
            content: elm.greeting.emergencyMessage.enabled.toString(),
            key: i + "-4",
          },
          {
            content: elm.greeting.welcomeMessage.enabled.toString(),
            key: i + "-5",
          },
          {
            content: elm.greeting.flashMessage.enabled.toString(),
            key: i + "-6",
          },
          {
            content: elm.greeting.customMessage.enabled.toString(),
            key:i + "-7",
          },
          {
            content: (
              <div>
                <Button
                  icon={<TrashCanIcon />}
                  text
                  iconOnly
                  title={intl.formatMessage({ id: "remove-chat-flow" })}
                  onClick={() => confirmeRemoveChatflow(i, elm.displayName, elm.id,elm.scope)}
                />
                <Button
                  icon={<EditIcon />}
                  text
                  iconOnly
                  title={intl.formatMessage({ id: "edit-chat-flow" })}
                  onClick={(event) => selectChatFlow(event, elm)}
                />
              </div>
            ),
            key: i + "-8",
          },
        ],
      };
      setChatFlowListTable((chatFlowListTable) => [
        ...chatFlowListTable,
        rowTable,
      ]);
      return chatFlowListTable;
    });
  };
  useEffect(() => {
    if(props.scopeList){
      getChatFlowData();
    }
  }, [props.scopeList]);
  const header = {
    key: "header",
    items: [
      {
        content: intl.formatMessage({ id: "resource-account" }),
        key: "Service costumer",
      },
      {
        content: intl.formatMessage({ id: "scope" }),
        key: "scope",
      },
      {
        content: intl.formatMessage({ id: "time-zone" }),
        key: "TimeZone",
      },
      {
        content: intl.formatMessage({ id: "language" }),
        key: "Language",
      },
      {
        content: intl.formatMessage({ id: "emergency-message" }),
        key: "Emergency Message",
      },
      {
        content: intl.formatMessage({ id: "welcome-message" }),
        key: "Welcome Message",
      },
      {
        content: intl.formatMessage({ id: "flash-message" }),
        key: "Flash Message",
      },
      {
        content: intl.formatMessage({ id: "custom-message" }),
        key: "Custom Message",
      },
      {
        content: intl.formatMessage({ id: "action" }),
        key: "Action",
      },
    ],
  };
  return (
    <div className="table-list">
      {isLoading ? (
        <Loader />
      ) : (
        <>
            <h2>{translate("chat-flow-list")}</h2>
          <div className="table-header">
            <Button
              icon={<AddIcon />}
              text
                content={intl.formatMessage({ id: "add" })} 
              primary
              onClick={addChatFlow}
            />
          </div>
          <Table
            className="table-call-flow"
            header={header}
            rows={chatFlowListTable}
          />
        </>
      )}
    </div>
  );
};

export default ListExampleSelectable;
