/* eslint-disable no-redeclare */
import update from "immutability-helper";
import { DATAKTP_EXECUTE_TARGET } from "office-ui-fabric-react";
function adminReducers(
  state = {
    licences: {},
    agentInfos: null,
    autoAttendant: [],
    autoAttendantComp: {
      musicOnHold: {
        isLoading: false,
      },
    },
  },
  action
) {
  const selectedQueue = state.autoAttendantComp.selectedQueue;
  switch (action.type) {
    case "SET_ADMIN":
      return {
        ...state,
        userIsAdmin: action.adminState,
      };
    case "SET_CALLFLOW_LIST":
      return {
        ...state,
        callFlows: action.list,
      };
    case "SET_CONSENTED_APPS":
      return {
        ...state,
        consentedApps: action.apps,
      };
    case "SET_GLOBAL_ADMINS":
      return {
        ...state,
        globalAdminUsers: action.globalAdmins,
      };
    case "SET_FUNCTIONAL_ADMINS":
      return {
        ...state,
        functionalAdminUsers: action.functionallAdmins,
      };
    case "SET_CALLQUEUELIST":
      if (state.CallQueueList == null) {
        let newCallQueueListInit = {};
        for (var key in action.QueueList) {
          newCallQueueListInit[key] = action.QueueList[key];
          newCallQueueListInit[key] = {
            ...newCallQueueListInit[key],
            mailNotifications: {
              notifMailList:
                JSON.parse(action.QueueList[key].mailNotifications)
                  .notifMailList || [],
              isChecked:
                JSON.parse(action.QueueList[key].mailNotifications).isChecked ||
                false,
            },
          };
        }
        return {
          ...state,
          CallQueueList: newCallQueueListInit,
        };
      } else {
        let newCallQueueList = {};
        for (var key in action.QueueList) {
          newCallQueueList[key] = action.QueueList[key];
          newCallQueueList[key] = {
            ...newCallQueueList[key],
            mailNotifications: {
              ...newCallQueueList[key].mailNotifications,
              notifMailList:
                action.QueueList[key].mailNotifications?.notifMailList || [],
            },
          };
          newCallQueueList[key] = {
            ...newCallQueueList[key],
            mailNotifications: {
              ...newCallQueueList[key].mailNotifications,
              isChecked:
                action.QueueList[key].mailNotifications?.isChecked || false,
            },
          };
        }
        return {
          ...state,
          CallQueueList: newCallQueueList,
        };
      }

    case "ADD_NEW_SCOPE":
      let scope = action.newScope.RowKey;
      const updateScopeList = update(state, {
        scopeList: {
          [scope]: { $set: action.newScope },
        },
      });
      return update(state, { $set: updateScopeList });
    case "SET_SCOPE_LIST":
      return {
        ...state,
        scopeList: action.scopeList,
      };
    case "SET_QUEUE_SCOPE":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { scope: { $set: action.scope } },
        },
      });
    case "SET_TECHNICAL_USER":
      return {
        ...state,
        technicalUser: action.technicalUser,
      };
    case "SET_ADMIN_SEARCH_RESULT":
      return {
        ...state,
        AdminSearchResult: action.adminSearchResult,
      };
    case "ADD_MAIL_TO_LIST":
      return {
        ...state,
        CallQueueList: {
          ...state.CallQueueList,
          [action.Mail.Queue]: {
            ...state.CallQueueList[action.Mail.Queue],
            mailNotifications: {
              ...state.CallQueueList[action.Mail.Queue].mailNotifications,
              notifMailList: [
                ...state.CallQueueList[action.Mail.Queue].mailNotifications
                  .notifMailList,
                action.Mail.value,
              ],
            },
          },
        },
      };
    case "DELETE_MAIL_FROM_LIST":
      const newState = state.CallQueueList[
        action.MailToDelete.Queue
      ].mailNotifications.notifMailList.filter(
        (elm) => elm !== action.MailToDelete.value
      );
      return {
        ...state,
        CallQueueList: {
          ...state.CallQueueList,
          [action.MailToDelete.Queue]: {
            ...state.CallQueueList[action.MailToDelete.Queue],
            mailNotifications: {
              ...state.CallQueueList[action.MailToDelete.Queue]
                .mailNotifications,
              notifMailList: newState,
            },
          },
        },
      };
    case "SET_IS_NOTIF_CHECKED":
      let checkValue = action.isChecked.value;
      return {
        ...state,
        CallQueueList: {
          ...state.CallQueueList,
          [action.isChecked.Queue]: {
            ...state.CallQueueList[action.isChecked.Queue],
            mailNotifications: {
              ...state.CallQueueList[action.isChecked.Queue].mailNotifications,
              isChecked: checkValue,
            },
          },
        },
      };
    case "SET_AUTOATTENDANT_INIT_STATE":
      return update(state, { autoAttendant: { $set: action.data } });
    case "IS_QUERY_LOADING":
      return update(state, {
        autoAttendantComp: {
          musicOnHold: { isLoading: { $set: action.isLoading } },
        },
      });
    case "ADD_HOLD_ON_MUSIC":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { musicOnHold: { $push: [action.music.value] } },
        },
      });
    case "DELETE_ON_HOLD_MUSIC":
      let index = state.autoAttendant[selectedQueue].musicOnHold.indexOf(
        action.id
      );
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { musicOnHold: { $splice: [[index, 1]] } },
        },
      });
    case "SET_QUERY_MUSIC_RESULTS":
      let newMusicList = action.MusicOnHoldRes.concat([
        {
          header: "default.wav",
          url: "https://callcenterconf.blob.core.windows.net/tenants/de5e3c28-141c-4d3e-90ea-aa3326abe67d/default/media/Urban Sunrise.wav",
        },
      ]);
      return update(state, {
        autoAttendantComp: {
          musicOnHold: { searchResults: { $set: newMusicList } },
        },
      });
    case "SET_SELECTED_USER_TRANSFERT_ID":
      if (action.infos.id) {
        let itemToUpdate = "targetId";
        if (
          state.autoAttendant[selectedQueue].queueOverflow.targetType ===
          "queue"
        ) {
          itemToUpdate = "queueId";
        }
        const updateUserOrQueueId = update(state, {
          autoAttendant: {
            [selectedQueue]: {
              queueOverflow: { [itemToUpdate]: { $set: action.infos.id } },
            },
          },
        });
        const updateUserOrQueueDisplayName = update(updateUserOrQueueId, {
          autoAttendant: {
            [selectedQueue]: {
              queueOverflow: {
                displayName: { $set: action.infos.header },
              },
            },
          },
        });

        return update(state, { $set: updateUserOrQueueDisplayName });
      }
      break;
    case "SET_SELECTED_USER_TRANSFERT_TYPE":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            queueOverflow: {
              targetType: {
                $set: action.overflowType,
              },
            },
          },
        },
      });
    case "SET_SELECTED_OVERFLOW_TYPE":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            queueOverflow: { type: { $set: action.actionType } },
          },
        },
      });
    case "SET_SELECTED_OVERFLOW_QUEUE":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            queueOverflow: {
              queueId: {
                $set: action.queueId,
              },
              queueDisplayName: {
                $set: action.queueDisplayName,
              },
            },
          },
        },
      });
    case "SET_MAXWAITTIME":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { maxWaitTime: { $set: action.maxWaitTime } },
        },
      });
    case "SET_SLA_THRESHOLD":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { slaThreshold: { $set: action.slaThreshold } },
        },
      });
    case "SET_WRUPUP_TIME":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { wrapUpTime: { $set: action.wrapUpTime } },
        },
      });
    case "SET_SLA":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { sla: { $set: action.sla } },
        },
      });
    case "SET_QUEUE_LANGUAGE":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { language: { $set: action.language } },
        },
      });
    case "SET_MAXCALLS":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { maxInQueue: { $set: action.maxCalls } },
        },
      });
    case "SET_QUEUENAME":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { queueName: { $set: action.queueName } },
        },
      });
    case "SET_MAX_ALERTTIME":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { maxAlertTime: { $set: action.maxAlertTime } },
        },
      });
    case "SET_ANNOUNCE_POSITION_IN_QUEUE":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            announcePositionInQueue: { $set: action.announcePositionInQueue },
          },
        },
      });
    case "SET_ANNOUNCE_ESTIMATED_WAIT_TIME":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            announceEstimatedWaitTime: {
              $set: action.announceEstimatedWaitTime,
            },
          },
        },
      });
    case "SET_WAIT_TIME_INTERVALS":
      if (state.autoAttendant[selectedQueue].waitTimeIntervals) {
        let newWaitTimeIntervalsArray =
          state.autoAttendant[selectedQueue].waitTimeIntervals;
        newWaitTimeIntervalsArray.push(action.interval);
        if (newWaitTimeIntervalsArray.length > 1) {
          newWaitTimeIntervalsArray = newWaitTimeIntervalsArray.sort((a, b) => {
            return a - b;
          });
        }
        return update(state, {
          autoAttendant: {
            [selectedQueue]: {
              waitTimeIntervals: { $set: newWaitTimeIntervalsArray },
            },
          },
        });
      } else {
        let DATA = [];
        DATA.push(action.interval);
        return update(state, {
          autoAttendant: {
            [selectedQueue]: { waitTimeIntervals: { $set: DATA } },
          },
        });
      }

    case "SET_AUTHORIZED_ACTIVITY_VALUES":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            authorizedActivityValues: { $set: action.values },
          },
        },
      });
    case "DELETE_WAIT_TIME_INTERVAL":
      let newWaitTimeIntervals = state.autoAttendant[
        selectedQueue
      ].waitTimeIntervals.filter((item) => item !== action.interval);
      newWaitTimeIntervals = newWaitTimeIntervals.sort((a, b) => {
        return a - b;
      });
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            waitTimeIntervals: { $set: newWaitTimeIntervals },
          },
        },
      });
    case "SET_FALL_BACK_IF_NO_AGENT_CONNECTED":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            fallbackIfNoAgentConnected: { $set: action.value },
          },
        },
      });
    case "SET_PLAY_MESSAGE_BEFORE_QUEUE_FALLBACK":
      if (state.autoAttendant[selectedQueue].queueFallBackMessage) {
        return update(state, {
          autoAttendant: {
            [selectedQueue]: {
              queueFallBackMessage: { enabled: { $set: action.value } },
            },
          },
        });
      } else {
        return update(state, {
          autoAttendant: {
            [selectedQueue]: {
              queueFallBackMessage: {
                $set: {
                  "enabled": action.value,
                  "fileName": "",
                  "type": "",
                  "language": "",
                  "text": "",
                  "audioFileUrl": ""
                }
              },
            },
          },
        });
      }


    case "SET_QUEUE_FALL_BACK_MESSAGE":

      if (state.autoAttendant[selectedQueue] && state.autoAttendant[selectedQueue].queueFallBackMessage) {
        return update(state, {
          autoAttendant: {
            [selectedQueue]: {
              queueFallBackMessage: { $set: action.value },
            },
          },
        });
      } else {
        return state
      }

    case "SET_VIP_PRIORITY_IN_QUEUE":
      if (action.enableVipPriority !== null) {
        return update(state, {
          autoAttendant: {
            [selectedQueue]: {
              enableVipPriority: { $set: action.enableVipPriority },
            },
          },
        });
      }
    case "SET_VIP_CONTACT":
      let contactId = action.contact.id;
      let vipDetails = action.queueInfos?.vipDetails;
      let vipIds = action.queueInfos?.vipIds;
      if (vipIds === undefined && vipDetails === undefined) {
        const setVipId = update(state, {
          autoAttendant: {
            [selectedQueue]: { vipIds: { $set: [action.contact.id] } },
          },
        });
        const setIdOnVipDetails = update(setVipId, {
          autoAttendant: {
            [selectedQueue]: {
              vipDetails: { $set: { [action.contact.id]: {} } },
            },
          },
        });
        return update(setIdOnVipDetails, {
          autoAttendant: {
            [selectedQueue]: {
              vipDetails: {
                [action.contact.id]: {
                  $set: {
                    displayName: action.contact.name,
                    type: action.contact.type,
                  },
                },
              },
            },
          },
        });
      } else {
        vipIds.push(contactId);
        vipDetails = {
          ...vipDetails,
          [contactId]: {
            displayName: action.contact.name,
            type: action.contact.type,
          },
        };
        const setVipId = update(state, {
          autoAttendant: {
            [selectedQueue]: { vipIds: { $set: vipIds } },
          },
        });
        return update(setVipId, {
          autoAttendant: {
            [selectedQueue]: {
              vipDetails: {
                [action.contact.id]: {
                  $set: {
                    displayName: action.contact.name,
                    type: action.contact.type,
                  },
                },
              },
            },
          },
        });
      }
    case "SET_ORDER":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { order: { $set: action.order } },
        },
      });
    case "ADD_ANSWERING_AGENT":
      let Now = Math.round(Date.now() / 1000);
      const setId = update(state, {
        autoAttendant: {
          [selectedQueue]: { agentsIds: { $push: [action.infos.id] } },
        },
      });
      const setIdOnAgentsDetails = update(setId, {
        autoAttendant: {
          [selectedQueue]: { agentDetails: { [action.infos.id]: {} } },
        },
      });
      return update(setIdOnAgentsDetails, {
        autoAttendant: {
          [selectedQueue]: {
            agentDetails: {
              [action.infos.id]: {
                $set: {
                  displayName: action.infos.header,
                  email: action.infos.email,
                  skill: 100,
                  state: "READY",
                  lastUpdated: Now
                },
              },
            },
          },
        },
      });
    case "EMPTY_QUEUE_AGENT":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            agentDetails: { $set: {} },
            agentsIds: { $set: [] },
          },
        },
      });
    case "SET_AGENT_SKILL":
      let skill = action.skill;
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            agentDetails: {
              [action.agentId]: {
                skill: { $set: skill },
              },
            },
          },
        },
      });
    case "DELETE_ANSWERING_AGENT":
      const indexAgent = state.autoAttendant[selectedQueue].agentsIds.indexOf(
        action.id
      );
      const removeAgentId = update(state, {
        autoAttendant: {
          [selectedQueue]: { agentsIds: { $splice: [[indexAgent, 1]] } },
        },
      });

      return update(removeAgentId, {
        autoAttendant: {
          [selectedQueue]: { agentDetails: { $unset: [action.id] } },
        },
      });
    case "DELETE_VIP_CONTACT":
      const indexContact = state.autoAttendant[selectedQueue].vipIds.indexOf(
        action.id
      );

      const removeVipContactId = update(state, {
        autoAttendant: {
          [selectedQueue]: { vipIds: { $splice: [[indexContact, 1]] } },
        },
      });
      return update(removeVipContactId, {
        autoAttendant: {
          [selectedQueue]: { vipDetails: { $unset: [action.id] } },
        },
      });
    case "SET_HUNT_METHOD":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: { huntMethod: { $set: action.method } },
        },
      });
    case "SAVE_SELECTED_QUEUE":
      return update(state, {
        autoAttendantComp: { selectedQueue: { $set: action.queueId.value } },
      });
    case "CREATE_NEW_QUEUE":

      const emptyQueue = {
        agentDetails: {},
        agentsIds: [],
        scope: "",
        language: "en-US",
        huntMethod: "RoundRobin",
        maxAlertTime: "",
        maxInQueue: "",
        announcePositionInQueue: false,
        announceEstimatedWaitTime: false,
        fallbackIfNoAgentConnected: false,
        queueFallBackMessage: {
          "enabled": false,
          "fileName": "",
          "type": "",
          "language": "",
          "text": "",
          "audioFileUrl": ""
        },
        authorizedActivityValues: ["Available"],
        waitTimeIntervals: [],
        sla: 60,
        wrapUpTime: 0,
        slaThreshold: 50,
        tasks: {},
        calls: {},
        musicOnHold: [
          {
            header: "default.wav",
            url: "https://callcenterconf.blob.core.windows.net/tenants/de5e3c28-141c-4d3e-90ea-aa3326abe67d/default/media/Urban Sunrise.wav",
          },
        ],
        queueName: "New Queue",
        queueOverflow: {
          type: "disconnect",
          targetType: "user",
          targetId: "",
          queueId: "",
          queueDisplayName: "",
        },
        maxWaitTime: 1200,
        integrationParams: {
          servicetype: "none",
          serviceApiKey: "",
          serviceURL: "",
          resultServiceURL: "",
          serviceParameters: [],
        },
        channels: { call: true },
      };
      return update(state, {
        autoAttendant: { newQueue: { $set: emptyQueue } },
      });
    case "REMOVE_QUEUE":
      return update(state, { autoAttendant: { $unset: [action.queueId] } });
    case "SET_QUEUE_TYPE":
      if (
        state.autoAttendant[selectedQueue].channels &&
        action.queueType === "chat"
      ) {
        return update(state, {
          autoAttendant: {
            [selectedQueue]: { channels: { chat: { $set: action.value } } },
          },
        });
      } else if (
        state.autoAttendant[selectedQueue].channels &&
        action.queueType === "call"
      ) {
        return update(state, {
          autoAttendant: {
            [selectedQueue]: { channels: { call: { $set: action.value } } },
          },
        });
      }
      break;
    case "SET_QUEUE_INFOS":
      let newQueueInfos = action.queueInfos;
      if (!newQueueInfos.hasOwnProperty("integrationParams")) {
        newQueueInfos["integrationParams"] = {
          servicetype: "",
          serviceApiKey: "",
          serviceURL: "",
          resultServiceURL: "",
          serviceParameters: [],
        };
      }
      return update(state, {
        autoAttendant: {
          $set: { [action.queueInfos.RowKey]: newQueueInfos },
        },
      });
    case "IS_SAVE_LOADING":
      return update(state, {
        autoAttendantComp: {
          save: { isLoading: { $set: [action.loading] } },
        },
      });
    case "SET_LICENCES":
      return {
        ...state,
        licences: action.licences,
      };
    case "SET_LICENCES_NAME":
      return {
        ...state,
        licencesName: action.licences,
      };
    case "SAVE_SELECTED_AGENT":
      return {
        ...state,
        agentInfos: action.agentInfos,
      };
    case "SET_SERVICE_TYPE":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            integrationParams: { servicetype: { $set: action.serviceType } },
          },
        },
      });
    case "SET_SERVICE_API_KEY":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            integrationParams: { serviceApiKey: { $set: action.apiKey } },
          },
        },
      });
    case "SET_SERVICE_URL":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            integrationParams: { serviceURL: { $set: action.url } },
          },
        },
      });
    case "SET_SERVICE_PARAMETRES":
      if (state.autoAttendant[selectedQueue]?.integrationParams !== undefined) {
        return update(state, {
          autoAttendant: {
            [selectedQueue]: {
              integrationParams: {
                serviceParameters: {
                  [action.key]: { $set: action.parameters },
                },
              },
            },
          },
        });
      } else {
        return state;
      }
    case "ADD_NEW_SERVICE_PARAM":
      let newParam = { label: "", type: "", paramName: "", value: "" };
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            integrationParams: { serviceParameters: { $push: [newParam] } },
          },
        },
      });
    case "DELETE_SERVICE_PARAMETRE":
      return update(state, {
        autoAttendant: {
          [selectedQueue]: {
            integrationParams: {
              serviceParameters: { $splice: [[action.key, 1]] },
            },
          },
        },
      });
    case "SET_SERVICE_RESULT_URL":
      if (state.autoAttendant[selectedQueue] !== undefined) {
        return update(state, {
          autoAttendant: {
            [selectedQueue]: {
              integrationParams: {
                resultServiceURL: { $set: action.resultUrl },
              },
            },
          },
        });
      } else {
        return state;
      }
    default:
      // need this for default case
      return state;
  }
}
export default adminReducers;
