import React, { useEffect, useState } from "react";
import { Button, Input, Dropdown, TrashCanIcon, RadioGroup, Header, Checkbox } from '@fluentui/react-northstar'
import { authContext } from "../../auth";
import { useIntl } from "react-intl";
import { confirmAlert } from 'react-confirm-alert';
import SearchAgent from './SearchAgent'
const AddGroup = (props) => {
    const intl = useIntl();
    const [newGroup, setNewGroup] = useState({
        PartitionKey: authContext._user.profile.tid,
        groupName: "",
        scope: "",
        agentsIds: [],
        agentDetails: {},
        supervisionMode: 'default',
        supervisorListenToCalls: false,
        supervisorListenToP2PCalls: false
    })
    const [isUserScopeAdmin, setIsUserScopeAdmin] = useState(false)
    const supervisionModeListe = [
        {
            key: "default",
            header: intl.formatMessage({ id: "DEFAULT" })
        },
        {
            key: "SUPERVISE_PSTN_ONLY",
            header: intl.formatMessage({ id: "SUPERVISE_PSTN_ONLY" })
        },
        {
            key: "PERMANENT_IN_DASHBORD",
            header: intl.formatMessage({ id: "PERMANENT_IN_DASHBORD" })
        }
    ]
    const confirmeRemoveAgent = (agentId, agentName) => {
        confirmAlert({
            title: intl.formatMessage({ id: "confirmation" }),
            message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + agentName + '!',
            buttons: [
                {
                    label: intl.formatMessage({ id: "yes" }),
                    onClick: () => deleteAgentFromNewGroup(agentId)
                },
                {
                    label: intl.formatMessage({ id: "no" }),
                }
            ]
        });
    }
    const deleteAgentFromNewGroup = (agentId) => {
        const newGroupIds = newGroup.agentsIds.filter(id => id !== agentId);
        let newAgentDetails = newGroup.agentDetails;
        delete newAgentDetails[agentId];
        setNewGroup({ ...newGroup, agentsIds: newGroupIds, agentDetails: newAgentDetails })
    }
    const setSupervisor = (contact) => {
        let newGroupIds = newGroup.agentsIds
        newGroupIds.push(contact.id)
        let newAgentDetails = newGroup.agentDetails;
        newAgentDetails[contact.id] = { "displayName": contact.header, "isSupervisor": false, "email": contact.email }
        setNewGroup({ ...newGroup, agentsIds: newGroupIds, agentDetails: newAgentDetails })
    }
    useEffect(() => {
        if (newGroup.scope !== "" && props.scopeList)
            setIsUserScopeAdmin(props.isUserAdminScope(props.scopeList[newGroup.scope], authContext._user.profile.oid))
    }, [newGroup, props.scopeList])
    return (
        <>
            {
                newGroup && newGroup !== null ?
                    <div >
                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: "20px", float: 'right' }}>
                            <Button
                                content={intl.formatMessage({ id: "add" })}
                                title={intl.formatMessage({ id: "add" })}
                                primary
                                onClick={() => {
                                    props.addNewGroup(newGroup)
                                }}
                            />
                            <Button
                                style={{ marginLeft: '15px' }}
                                content={intl.formatMessage({ id: "cancel" })}
                                title={intl.formatMessage({ id: "cancel" })}
                                primary
                                onClick={() => {
                                    props.setIsAddGroup(false)
                                }}
                            />
                        </div>
                        <Header
                            as="h4"
                            content={intl.formatMessage({ id: "group-name" })}
                            className="label-form_queue"
                        />
                        <Input
                            className="from-item"
                            onChange={(e, data) => {
                                setNewGroup({ ...newGroup, groupName: data.value })
                            }}
                        />
                        <Header
                            as="h4"
                            content={intl.formatMessage({ id: "scope" })}
                            className="label-form_queue"
                        />
                        <Dropdown
                            className="from-item"
                            items={Object.keys(props.scopeList)}
                            onChange={(e, data) =>
                                setNewGroup({ ...newGroup, scope: data.value, agentsIds: [], agentDetails: {} })
                            }
                        />
                        <Header
                            as="h4"
                            content={intl.formatMessage({ id: "SUPERVISION_MODE" })}
                            className="label-form_queue"
                        />
                        <Dropdown
                            className="from-item"
                            items={supervisionModeListe}
                            onChange={(e, data) =>
                                setNewGroup({ ...newGroup, supervisionMode: data.value.key })
                            }
                        />
                        {
                            isUserScopeAdmin ?
                                <>
                                    <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '420px' }}>
                                        <Header
                                            as="h4"
                                            content={intl.formatMessage({ id: "SUPERVISOR_LISTEN_TO_CALLS" })}
                                            className="label-form_queue"
                                        />
                                        <Checkbox toggle
                                            defaultChecked={false}
                                            onChange={(e, props) => {
                                                setNewGroup({ ...newGroup, supervisorListenToCalls: props.checked })
                                            }}
                                        />
                                    </div>
                                    {
                                        newGroup.supervisorListenToCalls && newGroup.supervisorListenToCalls === true ?
                                            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '420px' }}>
                                                <Header
                                                    as="h4"
                                                    content={intl.formatMessage({ id: "SUPERVISOR_LISTEN_TO_P2P_CALLS" })}
                                                    className="label-form_queue"
                                                />
                                                <Checkbox toggle
                                                    defaultChecked={false}
                                                    onChange={(e, props) => {
                                                        //props.checked
                                                        setNewGroup({ ...newGroup, supervisorListenToP2PCalls: props.checked })
                                                    }}
                                                />
                                            </div>
                                            :
                                            null
                                    }
                                </>
                                :
                                null
                        }
                        <Header
                            as="h4"
                            content={intl.formatMessage({ id: "agent-list" })}
                            className="label-form_queue"
                        />
                        <SearchAgent
                            scope={newGroup?.scope}
                            addAnsweringAgent={setSupervisor}
                        />
                        <table className="table-supervisor-agent">
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: "name" })}</th>
                                    <th>{intl.formatMessage({ id: "email" })}</th>
                                    <th>{intl.formatMessage({ id: "supervisor" })}</th>
                                    <th>{intl.formatMessage({ id: "action" })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(newGroup.agentDetails).map((elm, i) => {
                                        return (
                                            <tr key={i + "-add"}>
                                                <td>
                                                    <p>{newGroup.agentDetails[elm].displayName}</p>
                                                </td>
                                                <td>
                                                    <p>{newGroup.agentDetails[elm].email}</p>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        onChange={(e, data) => {
                                                            setNewGroup((newGroup) => ({
                                                                ...newGroup,
                                                                agentDetails: {
                                                                    ...newGroup.agentDetails,
                                                                    [elm]: { ...newGroup.agentDetails[elm], isSupervisor: data.checked },
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Button
                                                        icon={<TrashCanIcon />}
                                                        text
                                                        title={intl.formatMessage({ id: "delete" })}
                                                        iconOnly
                                                        onClick={() => {
                                                            confirmeRemoveAgent(elm, newGroup.agentDetails[elm].displayName)
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    null

            }
        </>
    )
}
export default AddGroup;