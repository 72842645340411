import React from "react";
import CallFlowListComponent from "../../components/CallFlowListComponents/callFlowList";
import { connect } from "react-redux";
import { setCallFlowList } from '../../redux/actions'
const CallFlowListContainer = (props) => {
  return <CallFlowListComponent scopeList={props.scopeList} setCallFlowList={props.setCallFlowList}></CallFlowListComponent>;
};
const mapStateToProps = (state) => ({
  queuesInfos: state.autoAttendant,
  scopeList: state.scopeList,
})
const mapDispatchToProps = (dispatch) => ({
  setCallFlowList: (list) => { dispatch(setCallFlowList(list)) },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallFlowListContainer);