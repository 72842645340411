/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setAdmin, setConsentedApps, setLicences, setLicencesName, setScopeList, setCallFlowList } from "../redux/actions";
import { authContext } from "../auth";
import Admin from "./Admin/Admin";
import WelcomeMessageComponent from "../WelcomeComponent/WelcomeMessageComponent";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Axios from "axios";
import logo from "../assets/heedify-logo.png";
import "../css/Welcome.css";
import { Provider as ProviderNorthComp } from "@fluentui/react-northstar";
import { Flex, Layout, Header, Avatar, Popup, Label, Image } from "@fluentui/react-northstar";
import { GlobeIcon } from '@fluentui/react-icons-northstar'
import { checkConsentUrl } from "../urls"
import translate from "../i18n/translate";
import { getToken } from "../components/Graph";
import { getAllScopsUrl, getAllLicences, getAllCallFlowManagementUrl } from "../urls";

import { I18nProvider, LOCALES } from '../i18n';
import detectBrowserLanguage from 'detect-browser-language';
const theme = {
  componentVariables: {
    // 💡 `colorScheme` is the object containing all color tokens
    Layout: ({ colorScheme }) => ({
      // `brand` contains all design tokens for the `brand` color
      color: colorScheme.brand.foreground3,
      backgroundColor: "#755d91",
      // `foreground3` and `background3` are theme-dependent tokens that should
      // be used as value in styles, you can define own tokens 💪
    }),
    Label: ({ colorScheme }) => ({
      // `brand` contains all design tokens for the `brand` color
      color: colorScheme.brand.foreground3,
      backgroundColor: "#755d91",
      // `foreground3` and `background3` are theme-dependent tokens that should
      // be used as value in styles, you can define own tokens 💪
    }),
  },
  componentStyles: {
    Layout: {
      // 🚀 We recomend to use `colorScheme` from variables mapping
      root: ({ variables }) => ({
        color: variables.color,
        backgroundColor: variables.backgroundColor,
      }),
    }
  },
};
function GetAvatar(user) {
  return <mgt-login></mgt-login>;
}
function GetLogo() {
  return (
    <div className="welcome-logo">
      <img
        alt="Heedify"
        src={logo}
      ></img>
    </div>
  );
}
const Welcome = (props) => {
  authContext.handleWindowCallback();
  let user = authContext.getCachedUser();
  let userIsAdmin = props.userIsAdmin;
  let checkuserAdmind = props.isAdmin;
  var langueOfBrowser = detectBrowserLanguage();
  const [langue, setLangue] = useState(LOCALES.ENGLISH)
  useEffect(() => {
    if (langueOfBrowser.includes('fr') || langueOfBrowser.includes('FR')) {
      setLangue(LOCALES.FRENCH)
    }
  }, [langueOfBrowser])
  const getLicenceList = async () => {
    let scopeListName = Object.keys(props.scopeList)
    const promises = []
    let Token = await getToken(authContext);
    for (let scope of scopeListName) {
      const url = getAllLicences(authContext._user.profile.tid, Token, scope);
      await Axios.get(url).then((Res) => {
        promises.push(Res.data)
      });
    }
    Promise.all(promises).then((results) => {
      var stateNew = {}
      for (var result of results) {
        stateNew[result.RowKey] = result;
      }
      return stateNew
    }).then((stateNew) => {
      props.setLicences(stateNew)
    })

  };
  const getScopListFromAsure = async () => {
    getToken(authContext).then(async (Token) => {
      const url = getAllScopsUrl(authContext._user.profile.tid, Token);
      Axios.get(url).then((Res) => {
        props.setScopList(Res.data)
        return Res.data
      })
    });
  };
  const getCallFlowData = async () => {
    let scopeListName = Object.keys(props.scopeList)
    const promises = []
    for (let scope of scopeListName) {
      const url = getAllCallFlowManagementUrl(authContext._user.profile.tid, authContext._user.profile.oid, scope);
      await Axios.get(url).then((res) => {
        promises.push(res.data)
      })
    }
    Promise.all(promises).then((results) => {
      var stateNew = {}
      for (var result of results) {
        if (Object.keys(result).length > 0) {
          stateNew = Object.assign({}, stateNew, result);
        }
      }
      return stateNew
    }).then((stateNew) => {
      props.setCallFlowList(stateNew)
    })

  };
  useEffect(() => {
    if (userIsAdmin) {
      getScopListFromAsure();
    }
  }, [userIsAdmin]);
  useEffect(() => {
    if (props.scopeList && props.scopeList !== null) {
      getCallFlowData()
      getLicenceList();
    }
  }, [props.scopeList]);
  if (user) {
    //object static of licences to change with result of axios
    if (userIsAdmin === undefined) {
      checkuserAdmind(user.profile.tid, user.profile.oid);
      return (
        <div id="Welcome">
          <Flex hAlign="center" vAlign="center">
            <Header as="h1" content="loading..." />
          </Flex>
        </div>
      );
    } else {
      if (userIsAdmin) {
        /**
         *    <Popup
                    trigger={<Button icon={<GlobeIcon />} title="Show popup" />}
                    content="This popup is rendered next to the trigger."
                    inline
                  />
         */

        return (
          <div className="page-container">
            <I18nProvider locale={langue}>
              <ProviderNorthComp theme={theme}>
                <Layout
                  className="header"
                  start={GetLogo()}
                  main={translate("Heedify admin portal")}
                  end={<div style={{ display: 'flex', alignItems: 'center' }}>
                    <Popup
                      trigger={<div>
                        <Label
                          circular
                          content={langue === "en-us" ? "EN" : "FR"}
                        />
                      </div>}
                      content={
                        <>
                          <div>
                            <Avatar className="langue-icone" onClick={() => { setLangue(LOCALES.FRENCH) }} size="small" name="F R" />
                          </div>
                          <div>
                            <Avatar className="langue-icone" onClick={() => { setLangue(LOCALES.ENGLISH) }} size="small" name="E N" />
                          </div>
                        </>
                      }
                      inline
                    />
                    <mgt-login></mgt-login>
                  </div>}
                />
              </ProviderNorthComp>
              <Admin />
            </I18nProvider>
          </div>
        );
      } else {
        return <Redirect to="/Consent" />;
      }
    }
  } else {
    return (
      <div id="Welcome">
        <WelcomeMessageComponent />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userIsAdmin: state?.userIsAdmin,
    scopeList: state?.scopeList
  }
}
const mapDispatchToProps = (dispatch) => ({
  isAdmin: async (tenantId, userId) => {
    var result = false;
    try {
      result = await axios.post(checkConsentUrl, {
        tenantId: tenantId,
        userId: userId,
      });
      if (result.data.IsAdmin) {
        dispatch(setAdmin(true));
        dispatch(setConsentedApps(result.data.consentedApp));
      } else {
        dispatch(setAdmin(false));
      }
    } catch (e) {
      dispatch(setAdmin(false));
    }
    return;
  },
  setLicences: (Licences) => {
    dispatch(setLicences(Licences))
  },
  setCallFlowList: (list) => { dispatch(setCallFlowList(list)) },
  setScopList: (scopeList) => { dispatch(setScopeList(scopeList)) },
  setLicencesName: (Licences) => {
    dispatch(setLicencesName(Licences))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);