/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button, List, AddIcon } from '@fluentui/react-northstar'
import { useHistory } from 'react-router-dom';
import Axios from "axios";
import { authContext } from "../../auth";
import { getAllAudioManagementUrl, deleteAudioManagementUrl } from "../../urls"
import { TrashCanIcon } from "@fluentui/react-icons-northstar";
import { confirmAlert } from 'react-confirm-alert';
import { getToken } from "../../components/Graph";
import translate from '../../i18n/translate';
import { useIntl } from 'react-intl';
const Media = () => {
    const intl = useIntl();
    const history = useHistory();
    var [mediaList, setMediaList] = useState([]);
    const getMediasList = async () => {
        getToken(authContext).then(async (Token) => {
            const url = getAllAudioManagementUrl(authContext._user.profile.tid, Token);
            Axios.get(url).then((Res) => {
                formatList(Res.data);
            });
        });
    };
    const confirmeRemoveMedia = (key, MediaName) => {
        confirmAlert({
            title: intl.formatMessage({ id: "confirmation" }),
            message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + MediaName + '!',
            buttons: [
                {
                    label: intl.formatMessage({ id: "yes" }),
                    onClick: () => removeMedia(key, MediaName)
                },
                {
                    label: intl.formatMessage({ id: "no" }),
                }
            ]
        });
    };
    const removeMedia = async (key, MediaName) => {
        getToken(authContext).then(async (Token) => {
            Axios.post(deleteAudioManagementUrl(authContext._user.profile.tid, MediaName, Token)).then(() => {
                setMediaList((state) => {
                    const newState = state.filter((data) => {
                        return data.key !== key;
                    });
                    return newState;
                });
            });
        });


    };
    const formatList = (list) => {

        const formattedList = list.map((elm, i) => {
            const rowTable = {
                key: i,
                content: <div>
                    {elm.header}
                    <Button
                        style={{ float: 'right' }}
                        key={i}
                        icon={<TrashCanIcon />}
                        text
                        iconOnly
                        title={intl.formatMessage({ id: "remove-media" })}
                        onClick={() => confirmeRemoveMedia(i, elm.header)}
                    />
                </div>,
            }

            setMediaList(mediaList => [...mediaList, rowTable]);
            return mediaList
        });
        return formattedList;
    };
    useEffect(() => {
        getMediasList();
    }, []);
    const addMedia = () => {
        history.push(`/media/newMedia`);
    }

    return (
        <div className="table-list">
            <h2>{translate('medias-list')}</h2>
            <div className="table-header">
                <Button icon={<AddIcon />} text content={intl.formatMessage({ id: "add" })} primary onClick={addMedia} />
            </div>
            <List id="media-list" items={mediaList} selectable />
        </div>
    )
}

export default (Media);