import { LOCALES } from "../locales";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.FRENCH]: {
    langue: "fr",
    globalSettings: "Paramètres globaux",
    Administrators: "Administrateurs",
    queues: "Files d'attente",
    call_queue: "File d'attente d'appels",
    chat_queues: "Files d'attente de chat",
    call_flow: "Flux d'appels",
    Flows: "Flux",
    chat_flow: "Flux de chat",
    agents: "Agents",
    media: "Média",
    licences: "Licences",
    "Heedify admin portal": "Portail d'administration Heedify",
    "Heedify service application consent":
      "Consentement des applications Heedify",
    "Heedify Call Center and Receptionist Console Application":
      "Application Heedify de gestion des appels",
    "Heedify Presence Application":
      "Application Heedify de gestion de presence",
    "Consent Permissons": "Consentement",
    "Technical Teams account": "Compte technique Teams",
    "This technical teams account is used by Heedify BackEnd services to track presence status of Agents":
      "Ce compte techniques Teams est utilisé par les services Cloud Heedify pour monitorer le statut de présence des Agents",
    "Please note that we dont support MFA (Multi-Fator Authentication) for this account":
      "Nous ne prenons pas en charge MFA (Multi-Fator Authentication) pour ce compte",
    "The defined technical user is": "L'utilisateur technique défini est:",
    "The group id is": "L'identifiant du groupe est : ",
    Submit: "Envoyer",
    password: "Mot de passe",
    userName: "Nom d'utilisateur",
    "Global Administrators": "Administrateurs globaux",
    "Global Administrators have all permissions to create, delete, list and update all Heedify services":
      "Les administrateurs globaux ont toutes les autorisations pour créer, supprimer, répertorier et mettre à jour tous les services Heedify",
    "start-typing-queue-name": "Commencez à saisir le nom de la file d'attente",
    "we-couldn't-find-any-matches":
      "Nous n'avons trouvé aucune correspondance.",
    "start-typing": "Commencer à écrire",
    confirmation: "Confirmation",
    "are-you-sure-to-remove": "Vous validez la supression?",
    yes: "Oui",
    no: "Non",
    "start-typing-a-name": "Commencez à taper un nom",
    "start-typing-name": "Commencez à saisir le nom",
    "chat-queues-list": "Liste des files d'attente de chat",
    add: "Ajouter",
    name: "Nom",
    type: "Type",
    language: "Langue",
    "max-wait-time": "Temps d'attente maximum",
    "number-of-agents": "Nombre d'agents",
    action: "Action",
    "remove-chat-queue": "Supprimer la file d'attente de chat",
    "edit-chat-queue": "Modifier la file d'attente de chat",
    save: "Enregistrer",
    "queue-name": "Nom de la file d'attente",
    "type-here...": "Écrivez ici...",
    "text-on-hold": "Texte en attente",
    "Max wait time (seconds)": "Temps d'attente maximal (secondes)",
    "agents-list": "Liste des agents",
    "queues-list": "Liste des files d'attente",
    "remove-queue": "Supprimer la file d'attente",
    "edit-queue": "Modifier la file d'attente",
    "hunt-method": "Méthode de sélection des agents",
    "number-of-agents": "Nombre d'agents",
    "queue-size": "Taille de la file d'attente",
    "announce-position-in-queue": "Annoncer la position dans la file d'attente",
    "Longest-Idle": "Le moins sollicité",
    "music-on-hold": "Musique d'attente",
    "max-calls-in-queue": "Nombre d'appels maximum",
    "VIP-priority": "Priorité VIP",
    "SLA-threshold": "Seuil niveau de service",
    "queue-fallback": "Débordement de la file d'attente",
    "transfert- type": "Type de transfert",
    "transfert-type": "Type de transfert",
    disconnect: "Déconnecter",
    queue: "File d'attente",
    "transfert-to": "Transférer",
    attendant: "Parallèle",
    serial: "En série",
    roundRobin: "Aléatoire",
    "queue-configuration": "Configuration de la file d'attente",
    "agent-configuration": "Configuration de l'agent",
    "service-integration": "Intégration des services",
    phone: "Téléphone",
    user: "Utilisateur",
    "phone-number": "Numéro de téléphone",
    "select-type": "Sélectionner le type",
    "api-key": "Clé API",
    "URL-parameters": "Paramètres d'URL",
    "add-new-parametre": "Ajouter de nouveaux paramètres",
    "show-details": "Afficher les détails",
    "new-parametre": "Nouveau paramètre",
    "delete-parametre": "Supprimer le paramètre",
    label: "Titre",
    "parametre-name": "Nom du paramètre",
    value: "Valeur",
    "select-value": "Sélectionner une valeur",
    "medias-list": "Liste des médias",
    "remove-media": "Supprimer le média",
    "add-media": "Ajouter des médias",
    "Filename:": "Nom du fichier : ",
    "Filetype:": "Type de fichier : ",
    "Size in bytes:": "Taille en octets : ",
    lastModifiedDate: "Dernière modification : ",
    "The audio file should be a .wav":
      "Le fichier audio doit avoir une extension .wav",
    "The audio content in the .wav file should be: 16-bits per sample, little-endian, 16000 sampling rate, mono/single-channel.":
      "Le format du fichier .wav doit être : 16-bits per sample, little-endian, 16000 sampling rate, mono/single-channel.",
    licences: "Licences",
    "licence-type": "Type de licence",
    licence: "licence",
    quantity: "Quantité",
    used: "Utilisé",
    scope: "Entité",
    "licence-level": "Niveau de licence",
    "remove-agent": "Supprimer l'agent",
    "edit-agent": "Modifier l'agent",
    "search-agent": "Recherche d'agents",
    "outbound-call": "Appel sortant",
    USER_PRINCIPAL_CALLFLOW: "Flux d'appels principal de l'utilisateur",
    "add-agent": "Ajouter l'agent",
    "remove-call-flow": "Supprimer le flux d'appels",
    "edit-call-flow": "Modifier le flux d'appels",
    "resource-account": "Compte de ressources",
    "time-zone": "Fuseau horaire",
    "Emergency Message": "Message d'urgence",
    "Welcome Message": "Message de bienvenue",
    "Flash Message": "Message Flash",
    "Custom Message": "Message personnalisé",
    "emergency-message": "Message d'urgence",
    "welcome-message": "Message de bienvenue",
    "flash-message": "Message Flash",
    "custom-message": "Message personnalisé",
    "call-flow-list": "Liste de flux d'appels",
    greeting: "Message d'accueil",
    Holidays: "Horaires d'ouvertures",
    routing: "Routage",
    "functional-admins": "Administrateurs fonctionnels",
    recording: "Enregistrement",
    text: "Texte",
    audio: "Audio",
    "holiday-message": "Message d'absence vacances",
    "out-of-business-hours-message": "Message d'absence horaires d'ouverture",
    "select-audio": "Sélectionnez un audio",
    dataLocation: "Emplacement des données",
    "generate-audio": "Générer un audio",
    "transform-to-audio": "Transformer en audio",
    weekend: "Weekend",
    "holidays-fallback": "Débordement vacances",
    "select-holidays": "Sélectionnez les jours fériés",
    "add-new-holidays": "Ajouter de nouveaux jours fériés",
    "start-date": "Date de début",
    "end-date": "Date de fin",
    "select-weekend-days": "Sélectionnez les jours de week-end",
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercedi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche",
    "open-hours": "Horaires d'ouverture",
    "Open Hours": "Horaires d'ouverture",
    "open-hours-fallback": "Débordement aprés horaires d'ouverture",
    "start-time": "Heure de début",
    "end-time": "Heure de fin",
    "disable-recording": "Enregistrement d'appels",
    "delete-routing": "Supprimer le routage",
    "routing-type": "Type de routage",
    "prompt-name": "Titre",
    "text-to-speech": "Text to speech",
    next: "Suivant",
    "choise-name": "Choisissez un nom",
    "IVR-name": "Nom du SVI",
    "add-new-dial-key": "Ajouter une touche DTMF",
    "select-dial-key": "Sélectionner une touche",
    "action-type": "Type d'action",
    delete: "Supprimer",
    "select-routing": "Sélectionnez le routage",
    "redirect-to": "Rediriger vers",
    matched: "Correspondance",
    "not-matched": "Pas de correspondance",
    "Invalid regular expression !": "Expression régulière invalide !",
    "You can not verify an empty input !":
      "Vous ne pouvez pas vérifier une entrée vide !",
    "queue-name": "Nom de la file d'attente",
    "if-not-matched": "Sinon",
    "if-matched": "Si correspndance",
    "add-new-routing": "Ajouter un nouveau routage",
    ENABLE_SURVEY: "Activer une enquête de satisfaction client",
    "The routing name should not contain a space ' ' ":
      "Le nom du routage ne doit pas contenir d'espace ' ' ",
    "The IVR name should not contain a space ' ' ":
      "Le nom du IVR ne doit pas contenir d'espace ' ' ",
    result: "Résultat...",
    test: "Test",
    "chat-flow-list": "Liste des flux de chat",
    "remove-chat-flow": "Supprimer le flux de chat",
    "edit-chat-flow": "Modifier le flux de chat",
    "add-new-chat-flow": "Ajouter un nouveau flux de chat",
    "bot-key": "Clé du robot",
    "bot-id": "Identifiant du robot",
    "chat-flow-id": "ID de flux de chat",
    "chat-flow-name": "Nom du flux de chat",
    "chat-flow-type": "Type de flux de chat",
    "card-name": "Nom de la carte",
    "add-postBack": "Ajouter un message Retour",
    "Start time: You need respect this form hh:mm !":
      "Heure de début : Vous devez respecter le format hh:mm",
    "End time: You need respect this form hh:mm !":
      "Heure de fin : Vous devez respecter le format hh:mm",
    "agent-name": "Nom d'agent",
    skill: "Compétence",
    "last-digit": "Le client termine la saisie avec #",
    "digits-length": "Nombre de chiffres attendus",
    AVAILABLE_ATTENDANT: "Receptionniste",
    AVAILABLE_CALLCENTER: "Standard",
    CX_ATTENDANT: "CX Attendant",
    CX_ESSENTIALS: "CX Essentials",
    CX_EXPERIENCE: "CX Experience",
    CX_ELEVATE: "CX Elevate",

    ATTENDANT: "Receptionniste",

    CALLCENTER: "Standard",
    PREMIUM: "Prémium",
    "save-agent": "Enregistrer l'agent",
    NULL: "nulle",
    cancel: "Annuler",
    greeting_home_page: "Bienvenue au portail Heedify",
    home_page_message: ".",
    "Good-morning": "Bonjour !",
    "Good-afternoon": "Bonjour !",
    "Good-evening": "Bonsoir !",
    calls: "Appels",
    chat: "Chat",
    "audio-transcribe": "Web service de transcription (Azure Speech) ",
    "chat-queue": "Canaux",
    "scopes-managemant": "Gestion des périmètres",
    "scopes-introduction":
      "Les entités d'organisation agissent comme un conteneur pour les configurations du portail d'administration Heedify, vous pouvez par exemple créer des entités pour présenter chacune des filiales de votre entreprise",
    "scope-name": "Nom : ",
    "admin-list": "Liste des administrateurs",
    close: "Fermer",
    scope: "Entité",
    edit: "Éditer",
    Error: "Erreur",
    "To add an agent you must fill in all the fields":
      "Pour agouter un agent vous devez remplire tous les champs !",
    "link-to-callflow": "Lien vers le flux d'appels",
    voice: "Voix",
    customerChoice: "Choix du client",
    "announce-estimated-wait-time": "Annoncer le temps d'attente estimé",
    longestIdle: "Le moins sollicité",
    authorizedActivityValues: "Statut de présence de disponiblité",
    waitTime_interval_hint:
      "Le temps d'attente est annoncé selon des intervalles défini par l'administrateur. Exemple : Si les intervalles sont [3, 5, 10] si le temps d'attente prévu pour un appel est de 4 minutes, le client entendra 'votre temps d'attente prévu est inférieur à 5 minutes' ",
    "worning-time-interval-input":
      "La valeur doit être > 0 et unique pas dans la liste",
    fallbackIfNoAgentConnected: "Débordement si aucun agent connecté",
    playMessageBeforeQueueFallBack: "Lire un message avant le debordment",
    voice: "Voix",
    customerChoice: "Choix du client",
    "add-DTMF-without-copy": "Ajouter",
    "DTMF-EXISTANCE-ERROR-MESSAGE": "Ce DTMF existe dans la liste",
    "DTMF-EMPTY-ERROR-MESSAGE": "Veuillez entrer un DTMF",
    "copy-from-other-routing": "Copier",
    "add-new-DTMF": "Ajouter un nouveau DTMF",
    "DTMF-NUMBER-ERROR-MESSAGE": "Le DTMF doit être un numéro.",
    form: "Formulaire",
    "add-item": "Ajouter une nouvelle entrée",
    placeholder: "Placeholder",
    date: "Date",
    "Azure-ad-agent-provisioning-title":
      "Groupe de provisionnement d'agent de publicité Azure",
    "Azure-ad-agent-provisioning-discription":
      "Description du provisionnement de l'agent de publicité Azure",
    "label-Provisioning-group": "Label Provisioning group",
    agents2: "Agent",
    "Failed to add agent": "Échec de l'ajout de l'agent",
    groups: "Groupes",
    "groups-management": "Groupes d'agents",
    "groups-introduction":
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    "agent-list": "Liste des agents",
    "group-name": "Nom de groupe",
    supervisors: "Superviseurs",
    email: "Email",
    supervisor: "Superviseur",
    available: "Disponible",
    total: "Totale",
    "agent-queues": "Files d'attente de l'agent",
    agent_Queues_hint:
      "    Quand l'agent est disponible, les files d'attente seront notifiés selon l'ordre ci-dessous ",
    Survey: "Enquête",
    timeout: "Temps de l'enquête",
    ADD_NEW_QUEUESTION: "Ajouter une nouvelle question",
    QUESTION_TEXT: "Question",
    VALUE_RANGE_INF: "Range inf",
    VALUE_RANGE_SUP: "Range sup",
    YES_OR_NO: "Oui ou Non",
    RATING: "Notation",
    NUMERICAL: "Numérique",
    ANNOUCEMENT: "Question",
    text2speech: "Text2speech",
    CALLFLOW_NUMERICAL_CASE_ERRORMESSAGE:
      "La valeur d'interval inf doit être inférieur a celle d'interval sup.",
    ADVANCED: "Avancée",
    AGENT_ACTIVE_RECORDING: "Activer l'enregistrement d'appels",
    ENABLE_ALL_CALLS_REPORTING: "Ajouter les appels Point à Point aux rapports",
    CALL_RECORDING_SCOPE: "Enregistrer les appels",
    AGENT_ACTIVE_ALL_CALLS_RECORDING:
      "Ajouter les appels Point à Point aux rapports",
    RECORDING_WEBHOOK: "Webhook aprés enregistrement",
    ALL: "Tous les appels",
    FLOW_CALLS_ONLY: "Uniquement les appels de flux",
    RANGE_VALUES: "Interval de valeur attendue",
    NO_DIGIT: "Nombre de 'Non' attendus",
    YES_DIGIT: "Chiffre signifiant 'Oui'",
    QUESTION_ORDRE: 'Ordre de la question',
    ORDER_ERROR_MESSAGE: "L'ordre ne doit pas être vide ni avoir été utilisé dans une autre question.",
    SERVY_CLOSER_MESSAGE: "Message de clôture",
    GOOD_BYE_MESSAGE: "Message de fermeture du questionnaire",
    HOVER_DELETE_QUESTION_MESSAGE: "Supprimer la question",
    HOVER_HIDE_DETAILS_BTN_MESSAGE: 'Afficher les détails',
    HOVER_SHOW_DETAILS_BTN_MESSAGE: 'Cacher les détails',
    SECONDES: "Secondes",
    ADD_QUESTION_ERROR_MESSAGE: "Le champ ne peut pas être vide ni contenir d'espace ' ' ",
    ADD_ROUTING_ERROR_MESSAGE: "Le champ ne peut pas être vide ni contenir d'espace ' ' ",
    YES_DIGIT_ERROR_MESSAGE: "Le champ doit contenir un numero entre 1 et 9 ou ' * ' ou ' @ ' ",
    WEB_PAGE: 'Page web',
    NONE: 'Aucun',
    IN_AGENT_CONSOLE: "Ouvir dans la console agent",
    OPEN_NEW_BROWSER_TAB: 'Ouvrir dans un onglet de navigateur',
    WEB_HOOK: 'Webhook',
    CRM: 'CRM',
    SUPERVISION_MODE: 'Mode de surveillance',
    PERMANENT_IN_DASHBORD: 'Superviser tous',
    SUPERVISE_PSTN_ONLY: 'Superviser les appels PSTN',
    DEFAULT: 'Default',
    PSTN: 'PSTN',
    P2P: 'P2P',
    WRAP_UP_TIME: 'Temps post-appel',
    SUPERVISOR_LISTEN_TO_P2P_CALLS: 'Ecoute discrète des appels Point à Point',
    SUPERVISOR_LISTEN_TO_CALLS: 'Ecoute discrète des appels'
  },
};
