/* eslint-disable no-unused-vars */
import React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import App from "./components/App";
import adminReducers from "./redux/reducers";
import {
  Provider as ProviderNorth,
  teamsTheme,
} from "@fluentui/react-northstar";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadTheme } from "@fluentui/react";
import { ThemeProvider } from "@fluentui/react-theme-provider";
import thunk from "redux-thunk";
import { authContext } from "./auth";
import "../src/css/index.css";
import {
  Providers,
  SimpleProvider,
  ProviderState,
} from "@microsoft/mgt";
function getAccessToken(scopes) {
  return new Promise((resolve, reject) => {
    authContext.acquireToken(
      "https://graph.microsoft.com",
      function (errDesc, token, err, tokenType) {
        if (token) {
          Providers.globalProvider.setState(ProviderState.SignedIn);
          resolve(token);
        }
      }
    );
  });
}
function login() {
  authContext.handleWindowCallback();

  authContext.login().then(() => {
    Providers.globalProvider.setState(ProviderState.SignedIn);
  });
}
function logout() {
  authContext.logOut();
}
let provider = new SimpleProvider(getAccessToken, login, logout);
Providers.globalProvider = provider;
authContext.handleWindowCallback();
let user = authContext.getCachedUser();
if (user) {
  Providers.globalProvider.setState(ProviderState.SignedIn);
} else {
}
const store = createStore(
  adminReducers,
  composeWithDevTools(applyMiddleware(thunk))
);
const myTheme = loadTheme({
  palette: {
    themePrimary: "#7c60a8",
    themeLighterAlt: "#f9f7fc",
    themeLighter: "#e7e1f1",
    themeLight: "#d3c7e5",
    themeTertiary: "#ab97cb",
    themeSecondary: "#896fb3",
    themeDarkAlt: "#6f5697",
    themeDark: "#5e4980",
    themeDarker: "#45365e",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});
const theme = {
  componentVariables: {
    // 💡 `colorScheme` is the object containing all color tokens
    Layout: ({ colorScheme }) => ({
      // `brand` contains all design tokens for the `brand` color
      color: colorScheme.brand.foreground3,
      backgroundColor: colorScheme.brand.background3,
      // `foreground3` and `background3` are theme-dependent tokens that should
      // be used as value in styles, you can define own tokens 💪
    }),
  },
  componentStyles: {
    Box: {
      // 🚀 We recomend to use `colorScheme` from variables mapping
      root: ({ variables }) => ({
        color: variables.color,
        backgroundColor: variables.backgroundColor,
      }),
    },
  },
};

render(
  <Provider store={store}>
    <ProviderNorth theme={teamsTheme}>
      <ThemeProvider theme={myTheme}>
        <App />
      </ThemeProvider>
      </ProviderNorth>
  </Provider>,
  document.getElementById("root")
);
