import { AuthenticationContext } from "react-adal";

export const config = {
  //clientId: '2eaa21a7-3855-4be3-9bb3-926ce86da20c',
  clientId: "73c1c68a-cdfd-458d-b3ca-140b159931a1",
  // redirectUri must be in the list of redirect URLs for the Azure AD app
  cacheLocation: "localStorage",
  redirectUri: "https://admin.heedify.io",
  //redirectUri: "http://localhost:3000/",
};

export const authContext = new AuthenticationContext(config);
