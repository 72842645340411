/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, Button, Checkbox } from "@fluentui/react-northstar";
import { authContext } from "../../auth";
import {
    searchContactFullinfos,
    getPersonPhoto,
} from "../Graph";
import translate from "../../i18n/translate";
import { useIntl } from 'react-intl';
import AudioTranscribe from './AudioTranscribe'
const Recording = (props) => {
    authContext.handleWindowCallback();
    const [suggestedItems, setSuggestedItems] = useState([]);
    const [callRecordingIsEnabled, setCallRecordingIsEnabled] = useState()
    const [searchQuery, setSearchQuery] = useState();
    const intl = useIntl();
    useEffect(() => {
        setSearchQuery(props.target?.displayName);
        setCallRecordingIsEnabled(props.callRecordingEnabled)
    }, [props.target?.displayName]);

    const handleQuery = async (e, search) => {
        setSearchQuery(search.searchQuery);
        if (search.searchQuery.length > 3) {
            const contactsSuggest = await getUser(search.searchQuery);
            let formatedItems = [];
            for (let contact of contactsSuggest) {
                let photo = await getUserPhoto(contact.id);
                formatedItems.push({
                    header: contact.displayName,
                    image: photo === null ? "" : window.URL.createObjectURL(photo),
                    id: contact.id,
                    content: (
                        <div>
                            {" "}
                            <Button
                                primary
                                size="small"
                                content={intl.formatMessage({ id: 'add' })}
                                onClick={() => {
                                    props.setRecordingData(contact);
                                }}
                            />{" "}
                        </div>
                    ),
                });
            }
            setSuggestedItems(() => formatedItems);
        }
    };
    const getUser = async (query) => {
        return await searchContactFullinfos(authContext, query, true);
    };
    const getUserPhoto = async (id) => {
        return await getPersonPhoto(authContext, id);
    };
    const handleToggleChange = (e, val) => {
        let value = val.checked;
        if (value === true) {
            setCallRecordingIsEnabled(true)
            props.setCallRecordingEnabled(true)
        } else {
            setCallRecordingIsEnabled(false)
            props.setCallRecordingEnabled(false)
        }
    };
    return (
        <>
        <div id='recording-state' >
                <label >{translate("disable-recording")}</label>
            <Checkbox
                id='checkbox-recording'
                defaultChecked={props.callRecordingEnabled}
                toggle
                onChange={handleToggleChange}
            />
            </div>
            {callRecordingIsEnabled ?
                <>
                    <AudioTranscribe
                        setAudioTranscribeEnabled={props.setAudioTranscribeEnabled}
                        audioTranscribeEnabled={props.audioTranscribeEnabled}
                        setAudioTranscribeData={props.setAudioTranscribeData}
                        audioTranscribeDetails={props.audioTranscribeDetails}
                    />
                <Dropdown
                    className="from-item"
                    search
                    searchQuery={searchQuery}
                    items={suggestedItems}
                    placeholder={intl.formatMessage({ id: 'start-typing-name' })}
                    noResultsMessage={intl.formatMessage({ id: "we-couldn't-find-any-matches" })}
                    onSearchQueryChange={(e, textQuery) => handleQuery(e, textQuery)}
                    getA11ySelectionMessage={{
                        onAdd: (item) => `${item} has been selected.`,
                    }}
                    />
                </>
                :
                null
            }

        </>
    );
};
export default Recording;