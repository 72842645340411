import React, { useState } from "react";
import { Dropdown } from "@fluentui/react-northstar";
import { searchContact } from "../Graph";
import { authContext } from "../../auth";
import Axios from "axios";
import { getToken } from "../Graph"
import { getAllQueueManagementUrl } from '../../urls'
const CallCenter = (props) => {
  const [inputItems, setInputItems] = useState([]);
  var dielKey = props?.dielKey;
  const searchCallQueue = async (searchWord) => {
    let list = [];
    let rawList = [];
    
    if (props.searchQueue) {
        let Token = await getToken(authContext);
      let rawList = await Axios.get(getAllQueueManagementUrl(authContext._user.profile.tid,authContext._user.profile.oid, props.scope));
      list = Object.keys(rawList.data).map((elm) => {
        return {
          header: rawList.data[elm].queueName,
          key: rawList.data[elm].RowKey,
        };
      });
    } else {
      rawList = await searchContact(
        authContext,
        searchWord,
        props.searchactifProfils
      );
      list = Object.keys(rawList).map((key, i) => {
      
        return {
          header: rawList[key].displayName,
          key: key,
          userprincipalname: rawList[key].userPrincipalName,
        };
      });
    }
    setInputItems(list);
  };
  return (
    <>
      {/* disabled Service costumer input if is an edit status */}
      {props.status !== "undefined" && props.status === true ? (
        <Dropdown
          disabled
          className="child-title-call-flow StaticDropDown"
          items={inputItems}
          defaultSearchQuery={props?.displayName?.toString()}
          checkable
          search
          onSearchQueryChange={(event, data) => {
            
            if (data.searchQuery.length > 2) {
              searchCallQueue(data.searchQuery);
            }
          }}
          onChange={(e, data) => {
            if (data.searchQuery.length > 2) {
              if (props.routingName !== "undefined") {
                props.getData(data.value, props.itemName, dielKey, props.routingName)
              } else {
                props.getData(data.value, props.itemName, dielKey)
              }

            }
          }
          }
          defaultValue={props?.displayName}
        />
      ) : (
        <Dropdown
          className="child-title-call-flow StaticDropDown"
          items={inputItems}
          defaultSearchQuery={props?.displayName}
          checkable
          search
          onSearchQueryChange={(event, data) => {
            if (data.searchQuery.length > 2) {
              searchCallQueue(data.searchQuery);
            }
          }}
          onChange={(e, data) => {
            if (data.searchQuery.length > 2) {
              if (props.routingName !== "undefined") {
                props.getData(data.value, props.itemName, dielKey, props.routingName)
              } else {
                props.getData(data.value, props.itemName, dielKey)
              }

            }
          }
          }
          defaultValue={props?.displayName || props?.data}
        />
      )}
    </>
  );
};
export default CallCenter;
