
import React, { useState, useEffect } from "react";
import { Button, Input, Dropdown, TrashCanIcon, Checkbox, Header } from '@fluentui/react-northstar'
import { useIntl } from "react-intl";
import { confirmAlert } from 'react-confirm-alert';
import SearchAgent from './SearchAgent';
import { authContext } from "../../auth";
const UpdateGroup = (props) => {
    const intl = useIntl();
    const [group, setGroup] = useState(props.data)
    const [isUserScopeAdmin, setIsUserScopeAdmin] = useState(false)
    const supervisionModeListe = [
        {
            key: "default",
            header: intl.formatMessage({ id: "DEFAULT" })
        },
        {
            key: "SUPERVISE_PSTN_ONLY",
            header: intl.formatMessage({ id: "SUPERVISE_PSTN_ONLY" })
        },
        {
            key: "PERMANENT_IN_DASHBORD",
            header: intl.formatMessage({ id: "PERMANENT_IN_DASHBORD" })
        }
    ]
    const confirmeRemoveAgent = (agentId, agentName) => {
        confirmAlert({
            title: intl.formatMessage({ id: "confirmation" }),
            message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + agentName + '!',
            buttons: [
                {
                    label: intl.formatMessage({ id: "yes" }),
                    onClick: () => deleteAgentFromgroup(agentId)
                },
                {
                    label: intl.formatMessage({ id: "no" }),
                }
            ]
        });
    }
    const deleteAgentFromgroup = (agentId) => {
        let groupIds = group.agentsIds.filter(id => id !== agentId);
        groupIds = [...new Set(groupIds)];
        let newAgentDetails = group.agentDetails;
        delete newAgentDetails[agentId];
        setGroup({ ...group, agentsIds: groupIds, agentDetails: newAgentDetails })
    }
    const setSupervisor = (contact) => {
        let groupIds = group.agentsIds
        groupIds = [...new Set(groupIds)];
        groupIds.push(contact.id)
        let newAgentDetails = group.agentDetails;
        newAgentDetails[contact.id] = { "displayName": contact.header, "isSupervisor": false, "email": contact.email }
        setGroup({ ...group, agentsIds: groupIds, agentDetails: newAgentDetails })
    }
    useEffect(() => {
        if (group.scope !== "" && props.scopeList)
            setIsUserScopeAdmin(props.isUserAdminScope(props.scopeList[group.scope], authContext._user.profile.oid))
    }, [group, props.scopeList])
    return (
        <>
            {
                group && group !== null ?
                    <div >
                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: "20px", float: 'right' }}>
                            <Button
                                content={intl.formatMessage({ id: "save" })}
                                title={intl.formatMessage({ id: "save" })}
                                primary
                                onClick={() => {
                                    props.updateGroup(group)
                                }}
                            />
                            <Button
                                style={{ marginLeft: '15px' }}
                                content={intl.formatMessage({ id: "cancel" })}
                                title={intl.formatMessage({ id: "cancel" })}
                                primary
                                onClick={() => {
                                    props.setIsUpdateGroup(false)
                                }}
                            />
                        </div>

                        <Header
                            as="h4"
                            content={intl.formatMessage({ id: "group-name" })}
                            className="label-form_queue"
                        />
                        <Input
                            className="from-item"
                            defaultValue={group.groupName}
                            onChange={(e, data) => {
                                setGroup({ ...group, groupName: data.value })
                            }}
                        />
                        <Header
                            as="h4"
                            content={intl.formatMessage({ id: "scope" })}
                            className="label-form_queue"
                        />
                        <Dropdown
                            className="from-item"
                            defaultValue={group.scope}
                            items={Object.keys(props.scopeList)}
                            onChange={(e, data) =>
                                setGroup({ ...group, scope: data.value, agentsIds: [], agentDetails: {} })
                            }
                        />
                        <Header
                            as="h4"
                            content={intl.formatMessage({ id: "SUPERVISION_MODE" })}
                            className="label-form_queue"
                        />
                        <Dropdown
                            className="from-item"
                            value={
                                group.supervisionMode ?
                                    supervisionModeListe.filter(item => item.key === group.supervisionMode).length > 0 &&
                                    supervisionModeListe.filter(item => item.key === group.supervisionMode)[0].header :
                                    'default'
                            }
                            items={supervisionModeListe}
                            onChange={(e, data) =>
                                setGroup({ ...group, supervisionMode: data.value.key })
                            }
                        />
                        {
                            isUserScopeAdmin ?
                                <>
                                    <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '420px' }}>
                                        <Header
                                            as="h4"
                                            content={intl.formatMessage({ id: "SUPERVISOR_LISTEN_TO_CALLS" })}
                                            className="label-form_queue"
                                        />
                                        <Checkbox toggle
                                            defaultChecked={group?.supervisorListenToCalls}
                                            onChange={(e, props) => {
                                                setGroup({ ...group, supervisorListenToCalls: props.checked })
                                            }}
                                        />
                                    </div>
                                    {
                                        group.supervisorListenToCalls && group.supervisorListenToCalls === true ?
                                            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '420px' }}>
                                                <Header
                                                    as="h4"
                                                    content={intl.formatMessage({ id: "SUPERVISOR_LISTEN_TO_P2P_CALLS" })}
                                                    className="label-form_queue"
                                                />

                                                <Checkbox toggle
                                                    defaultChecked={group?.supervisorListenToP2PCalls}
                                                    onChange={(e, props) => {
                                                        //props.checked
                                                        setGroup({ ...group, supervisorListenToP2PCalls: props.checked })
                                                    }}
                                                />
                                            </div>
                                            :
                                            null
                                    }
                                </>
                                :
                                null
                        }
                        <Header
                            as="h4"
                            content={intl.formatMessage({ id: "agent-list" })}
                            className="label-form_queue"
                        />
                        <SearchAgent
                            scope={group?.scope}
                            addAnsweringAgent={setSupervisor}
                        />
                        <table className="table-supervisor-agent">
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: "name" })}</th>
                                    <th>{intl.formatMessage({ id: "email" })}</th>
                                    <th>{intl.formatMessage({ id: "supervisor" })}</th>
                                    <th>{intl.formatMessage({ id: "action" })}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(group.agentDetails).map((elm, i) => {
                                        return (
                                            <tr key={i + '-updates'}>

                                                <td>
                                                    <p>{group.agentDetails[elm].displayName}</p>
                                                </td>
                                                <td>
                                                    <p>{group.agentDetails[elm].email}</p>
                                                </td>
                                                <td >
                                                    <Checkbox
                                                        defaultChecked={group.agentDetails[elm].isSupervisor}
                                                        onChange={(e, data) => {
                                                            setGroup((group) => ({
                                                                ...group,
                                                                agentDetails: {
                                                                    ...group.agentDetails,
                                                                    [elm]: { ...group.agentDetails[elm], isSupervisor: data.checked },
                                                                }
                                                            }));
                                                        }}
                                                    />

                                                </td>
                                                <td >
                                                    <Button
                                                        icon={<TrashCanIcon />}
                                                        text
                                                        title={intl.formatMessage({ id: "delete" })}
                                                        iconOnly
                                                        onClick={() => {
                                                            confirmeRemoveAgent(elm, group.agentDetails[elm].displayName)
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>


                    </div>
                    :
                    null

            }
        </>
    )
}
export default UpdateGroup;