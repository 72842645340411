import React, { useState } from "react";
import "../css/AgentConfig.css";
import { authContext } from "../auth";
import { useHistory } from "react-router-dom";
import { post } from "axios";
import {
  FormInput,
  Form,
  FormButton,
} from "@fluentui/react-northstar";
import FormData from "form-data";
import { uploadMediaUrl } from "../urls"
import translate from '../i18n/translate';
import { useIntl } from 'react-intl';
authContext.handleWindowCallback();
const MediaComponent = (props) => {
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const intl = useIntl();
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };
  const handleSubmission = () => {
    setIsSaveLoading(true);
    var formData = new FormData();
    formData.append("File", selectedFile);
    var headers = {
      "content-type": "multipart/form-data",
    };
    const url = uploadMediaUrl(authContext._user.profile.tid, authContext._user.profile.oid, selectedFile.name);
    post(url, formData, headers)
      .then(function (response) {
        setIsSaveLoading(false);
        history.push(`/media`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="form-container">
      <Form onSubmit={() => { }}>
        <h2>{translate('add-media')}</h2>
        {isSelected ? (
          <div>
            <p> {translate('Filename:')} {selectedFile.name}</p>
            <p> {translate('Filetype:')} {selectedFile.type}</p>
            <p> {translate('Size in bytes:')} {selectedFile.size}</p>
            <p>
              {translate('lastModifiedDate')}
              {selectedFile.lastModifiedDate.toLocaleDateString()}
            </p>
          </div>
        ) : (
          <div>
              <p> {translate('The audio file should be a .wav')}</p>
              <p>{translate('The audio content in the .wav file should be: 16-bits per sample, little-endian, 16000 sampling rate, mono/single-channel.')}</p>
          </div>
        )}
        <FormInput type="file" onChange={changeHandler} />
        <hr />
        <FormButton
          primary
          loading={isSaveLoading}
          content={intl.formatMessage({ id: "add" })}
          type="submit"
          onClick={handleSubmission}
        />
      </Form>
    </div >
  );
};
export default MediaComponent;
