import React, { useState, useEffect } from "react";
import { List, Button, EditIcon, TrashCanIcon, AddIcon } from '@fluentui/react-northstar'
import { authContext } from "../../auth";
import { deleteScopeUrl, getAllScopsUrl } from "../../urls"
import { getToken } from "../../components/Graph";
import { confirmAlert } from 'react-confirm-alert';
import UpdateScope from './UpdateScope'
import AddScope from './AddScope'
import Axios from "axios";
import { useIntl } from 'react-intl';
const ScopeComponent = (props) => {
    const intl = useIntl();
    const [formattedScopsList, setFormattedScopList] = useState([]);
    const [isAddScope, setIsAddScope] = useState(false);
    const [isUpdateScope, setIsUpdateScope] = useState(false);
    const [scopeToUpdate, setScopeToUpdate] = useState();
    const formatScopesList = (list) => {
        const formattedList = Object.keys(list).map((elm, i) => {
            const rowTable = {
                key: i,
                header: elm,
                headerMedia: <div>
                    <Button
                        icon={<EditIcon />}
                        text
                        title={intl.formatMessage({ id: 'edit' })}
                        iconOnly
                        onClick={() => {
                            setIsUpdateScope(true);
                            setScopeToUpdate(list[elm]);
                        }
                        }
                    />
                    <Button
                        icon={<TrashCanIcon />}
                        text
                        title={intl.formatMessage({ id: 'delete' })}
                        iconOnly
                        disabled={elm === "default" ? true : false}
                        onClick={() => confirmeRemoveScope(i, elm)}
                    />
                </div>,
            }
            setFormattedScopList(formattedScopsList => [...formattedScopsList, rowTable]);
            return formattedScopsList
        });
        return formattedList;
    };
    const confirmeRemoveScope = (key, scope) => {
        confirmAlert({
            title: intl.formatMessage({ id: "confirmation" }),
            message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + scope + '!',
            buttons: [
                {
                    label: intl.formatMessage({ id: "yes" }),
                    onClick: () => removeScope(key, scope)
                },
                {
                    label: intl.formatMessage({ id: "no" }),
                }
            ]
        });
    };
    const getScopListFromAsure = async () => {
        getToken(authContext).then(async (Token) => {
            const url = getAllScopsUrl(authContext._user.profile.tid, Token);
            Axios.get(url).then((Res) => {
                props.setScopList(Res.data)
            })
        });
    };
    const removeScope = async (key, scope) => {
        getToken(authContext).then(async (Token) => {
            Axios.post(deleteScopeUrl(authContext._user.profile.tid, scope, Token)).then(() => {
                setFormattedScopList([])
                getScopListFromAsure()
            });
        });
    };
    useEffect(() => {
        formatScopesList(props.scopeList)
    }, [props.scopeList])
    return (
        <div className="table-list scope-Component">
            <div style={{ width: "40%" }}>
                <div >
                    <Button 
                    disabled = {Object.keys(props.scopeList).length > 0 ? false : true}
                    icon={<AddIcon />} 
                    text 
                    content={intl.formatMessage({ id: 'add' })}
                     title={intl.formatMessage({ id: 'add' })} 
                     primary 
                     onClick={() => { setIsAddScope(true) }} />
                </div>
                <List items={formattedScopsList} />
            </div>
            <div style={{ marginLeft: "30px" }}>
                {
                    isAddScope && Object.keys(props.scopeList).length > 0 ?
                        <AddScope
                            setIsAddScope={setIsAddScope}
                            setFormattedScopList={setFormattedScopList}
                            setScopList={props.setScopList}
                            addNewScope={props.addNewScope}
                            scopeList={props.scopeList}
                        />
                        :
                        <div>
                            {
                                isUpdateScope ?
                                    <UpdateScope
                                        data={scopeToUpdate}
                                        setIsUpdateScope={setIsUpdateScope}
                                        setFormattedScopList={setFormattedScopList}
                                        setScopList={props.setScopList}
                                        removeAdminUser={props.removeAdminUser}
                                        addScopeAdminUser={props.addScopeAdminUser}
                                    />
                                    :
                                    null
                            }
                        </div>
                }
            </div>
        </div >
    )
}
export default ScopeComponent