import React, { useState, useEffect } from "react";
import {
  Dropdown,
  TextArea,
  Button,
  Flex,
  Loader,
} from "@fluentui/react-northstar";
import Axios from "axios";
import { authContext } from "../../auth";
import { text2speechApiUrl } from "../../urls"
import { useIntl } from 'react-intl';
import translate from '../../i18n/translate'
const t2sList = [
  {
    header: "fr-FR",
    voice: "DeniseNeural",
  },
  {
    header: "fr-CA",
    voice: "SylvieNeural",
  },
  {
    header: "en-US",
    voice: "AriaNeural",
  },
  {
    header: "de-DE",
    voice: "KatjaNeural",
  },
  {
    header: "es-ES",
    voice: "ElviraNeural",
  },
  {
    header: "it-IT",
    voice: "ElsaNeural",
  },
  {
    header: "pt-PT",
    voice: "RaquelNeural",
  },

];
const Text2Speech = (props) => {
  const intl = useIntl();
  const [dropdownValue, setDropdownValue] = useState(
    () => t2sList.filter((elm) => elm.header === props.language)[0]
  );
  const [textAreaVal, setTextAreaVal] = useState(props.text);
  const [isT2sLoading, setIsT2sLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const clickHandle = () => {
    const url = text2speechApiUrl;
    const data = {
      language: dropdownValue?.header,
      voice: dropdownValue?.voice,
      text: textAreaVal,
      directory: `${authContext._user.profile.tid}/${props.callFlowId
        }/${props.directory.toString().replace(/\s/g, "").toLowerCase()}`,
    };
    setIsT2sLoading(true);
    Axios.post(url, data).then((response, err) => {
      setIsT2sLoading(false);
      if (!err) {
        props.getData(
          null,
          textAreaVal,
          "textToSpeechText",
          props.annoucementName
        );
        if (props.annoucementName === 'ivr' || props.annoucementName === 'prompt') {
          props.getIvrText(response.data, dropdownValue?.header, textAreaVal, props.routingName)
        } else {
          props.getData(null, response.data, "audio", props.annoucementName);
        }
      }
    });
  };
  const handleLanguageChange = (e, data) => {
    setDropdownValue(data.value);
    props.getData(
      e,
      data.value.header,
      "textToSpeechLanguage",
      props.annoucementName
    );
  };
  const inputChanges = (e, data) => {
    setTextAreaVal(data.value);
  };
  //if langue is null or testArea is empty the button(MicIcon) is disabled
  useEffect(() => {
    if (textAreaVal.length > 0 && (props.language.length || dropdownValue?.header.length > 0)) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [textAreaVal, props, dropdownValue])
  return (
    <>
      <Flex column gap="gap.small" className="child-title-call-flow">
        <Dropdown
          items={t2sList}
          placeholder={intl.formatMessage({ id: "language" })}
          checkable
          defaultValue={props.language}
          onChange={handleLanguageChange}
          getA11ySelectionMessage={{
            onAdd: (item) => `${item} has been selected.`,
          }}
        />
        <div>
          <TextArea defaultValue={props.text} onChange={inputChanges} />
        </div>
        <div>
          <Button
            content={isT2sLoading ? <Loader size="smallest" /> : intl.formatMessage({ id: "generate-audio" })}
            primary
            title={intl.formatMessage({ id: "transform-to-audio" })} 
            onClick={clickHandle}
            disabled={isDisabled}
          />
        </div>
      </Flex>
    </>
  );
};
export default Text2Speech;
