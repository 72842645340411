import React, { useState, useEffect } from "react";
import { Header, Dropdown, List, Avatar, Input, Button, RadioGroup, Checkbox, AddIcon } from "@fluentui/react-northstar";
import ServiceIntegrationParametre from './ServiceIntegrationParametre';
import { useIntl } from 'react-intl';
const ServiceIntegrationComponent = (props) => {
    const intl = useIntl();
    const [resultUrl, setResultUrl] = useState('')
    const inputItems = [
        {
            key: "none",
            header: intl.formatMessage({ id: "NONE" })
        },
        {
            key: "webHook",
            header: intl.formatMessage({ id: "WEB_HOOK" })

        },
        {
            key: "CRM",
            header: intl.formatMessage({ id: "CRM" })

        },
        {
            key: "inAgentConsole",
            header: intl.formatMessage({ id: "IN_AGENT_CONSOLE" })
        },
        {
            key: "openNewBrowserTab",
            header: intl.formatMessage({ id: "OPEN_NEW_BROWSER_TAB" })
        }
    ]
    useEffect(() => {

        let url = props.queueInfos.integrationParams.serviceURL;
        if (url !== '') {

            props.queueInfos.integrationParams.serviceParameters.map(function (item, i) {
                if (item.paramName !== '' && item.value !== '') {
                    if (i === 0) {
                        url = url + '?' + item.paramName + '=' + item.value
                    } else {
                        url = url + '&' + item.paramName + '=' + item.value
                    }
                    props.setServiceResultUrl(url)
                }

            })
        }
    }, [props.queueInfos.integrationParams.serviceParameters])
    return (
        <div className="form-container ">
            {
                props.queueInfos?.integrationParams?.resultServiceURL !== undefined && props.queueInfos?.integrationParams?.resultServiceURL !== '' ?
                    <div
                        className="alert alert-success "
                        style={{
                            width: '440px',
                            position: 'fixed',
                            right: '168px',
                            top: '98px',
                        }}
                    >
                        <p>{props.queueInfos?.integrationParams?.resultServiceURL}</p>
                    </div>
                    :
                    null
            }
            <Header as="h4" content={intl.formatMessage({ id: "type" })} className="label-integration" />
            <Dropdown
                className="from-item"
                items={inputItems}
                placeholder={intl.formatMessage({ id: "select-type" })}
                value={
                    inputItems.filter(item => item.key === props.queueInfos?.integrationParams?.servicetype).length > 0 &&
                        inputItems.filter(item => item.key === props.queueInfos?.integrationParams?.servicetype)[0].header ?
                        inputItems.filter(item => item.key === props.queueInfos?.integrationParams?.servicetype)[0].header :
                        intl.formatMessage({ id: "NONE" })
                }
                checkable
                onChange={(e, data) => {
                    console.log(data.value.key)
                    props.setServicetype(data.value.key)
                }}
                getA11ySelectionMessage={{
                    onAdd: item => `${item} has been selected.`,
                }}
            />
            <Header as="h4" content={intl.formatMessage({ id: "api-key" })} className="label-integration" />
            <Input
                value={props.queueInfos?.integrationParams?.serviceApiKey}
                className="from-item"
                onChange={(e, data) => {
                    props.setServiceApiKey(data.value)
                }}
            />
            <Header as="h4" content="URL" className="label-integration" />
            <Input
                className="from-item"
                value={props.queueInfos?.integrationParams?.serviceURL}
                onChange={(e, data) => {
                    props.setServiceURL(data.value)
                }}
            />
            <Header as="h3" content={intl.formatMessage({ id: "URL-parameters" })} className="label-integration" />
            {
                props.queueInfos?.integrationParams?.serviceParameters.map(function (item, i) {
                    return (
                        <ServiceIntegrationParametre
                            key={i}
                            index={i}
                            queueInfos={props.queueInfos}
                            data={item}
                            setServiceParameters={props.setServiceParameters}
                            deleteServiceParametre={props.deleteServiceParametre}
                        />
                    )
                })
            }
            <Button
                primary
                icon={<AddIcon />}
                text
                content={intl.formatMessage({ id: "add-new-parametre" })} onClick={() => { props.addNewServiceParam() }}
            />
        </div>
    )
}
export default ServiceIntegrationComponent;