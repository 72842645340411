
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, Button } from "@fluentui/react-northstar";
import { authContext } from "../../auth";
import {
    searchContactFullinfos,
    getPersonPhoto,
} from "../Graph";
import { useIntl } from 'react-intl';
const FunctionAdmins = (props) => {
    authContext.handleWindowCallback();
    const [suggestedItems, setSuggestedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState();
    const intl = useIntl();
    useEffect(() => {
        setSearchQuery(props.target?.displayName);
    }, [props.target?.displayName]);

    const handleQuery = async (e, search) => {
        setSearchQuery(search.searchQuery);
        if (search.searchQuery.length > 3) {
            const contactsSuggest = await getUser(search.searchQuery);
            let formatedItems = [];
            for (let contact of contactsSuggest) {
                let photo = await getUserPhoto(contact.id);
                formatedItems.push({
                    header: contact.displayName,
                    image: photo === null ? "" : window.URL.createObjectURL(photo),
                    id: contact.id,
                    content: (
                        <div>
                            {" "}
                            <Button
                                primary
                                size="small"
                                content={intl.formatMessage({ id: "add" })}
                                onClick={() => {
                                    props.getData(contact);
                                }}
                            />{" "}
                        </div>
                    ),
                });
            }
            setSuggestedItems(() => formatedItems);
        }
    };
    const getUser = async (query) => {
        return await searchContactFullinfos(authContext, query, true);
    };
    const getUserPhoto = async (id) => {
        return await getPersonPhoto(authContext, id);
    };
    return (
        <>
            <Dropdown
                className="from-item"
                search
                searchQuery={searchQuery}
                items={suggestedItems}
                placeholder={intl.formatMessage({ id: "start-typing-a-name" })}
                noResultsMessage={intl.formatMessage({ id: "we-couldn't-find-any-matches" })}
                onSearchQueryChange={(e, textQuery) => handleQuery(e, textQuery)}
                getA11ySelectionMessage={{
                    onAdd: (item) => `${item} has been selected.`,
                }}
            />
        </>
    );
};
export default FunctionAdmins;