/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { EditIcon, Table } from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  createNewQueue,
  getAutoAttendantInitState,
  removeQueueFromDB,
} from "../../redux/actions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AddIcon } from "@fluentui/react-icons-northstar";
import { Button } from "@fluentui/react-northstar";
import { TrashCanIcon } from "@fluentui/react-icons-northstar";
import translate from "../../i18n/translate";
import { useIntl } from 'react-intl';
const QueuesListContainer = (props) => {
  var [rowsPlain, setRowsPlain] = useState([]);
  const history = useHistory();
  const intl = useIntl();
  const getQueuesList = async () => {
    formatList(props.queuesInfos);
  };
  const confirmeRemoveQueue = (elm, queueName, scope) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + queueName + '!',
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => removeQueue(elm, scope)
        },
        {
          label: intl.formatMessage({ id: "no" }),
        }
      ]
    });
  };
  const removeQueue = (elm, scope) => {
    props.removeQueueFromDB(elm, scope);
    setRowsPlain((state) => {
      const newState = state.filter((data) => {
        return data.key !== elm;
      });
      return newState;
    });
  };
  const formatList = (list) => {
    const listArr = Object.keys(list);
    setRowsPlain([])
    return listArr.map((elm, i) => {
      if (list[elm]?.queueName) {
        let NumberOfAgents = 0;
        if (list[elm]?.agentsIds) {
          NumberOfAgents = list[elm].agentsIds.length;
        }
        let rowTable = {
          key: i,
          items: [
            {
              content: list[elm].queueName,
              key: i + "-1",
            },
            {
              content: list[elm].scope,
              key: i + "-7",
            },
            {
              content: list[elm].huntMethod,
              truncateContent: true,
              key: i + "-2",
            },
            {
              content: list[elm].maxInQueue,
              key: i + "-3",
            },
            {
              content: list[elm].maxWaitTime,
              key: i + "-4",
            },
            {
              content: NumberOfAgents,
              key: i + "-5",
            },
            {
              content:
                <div>
                  <Button
                    icon={<TrashCanIcon />}
                    text
                    iconOnly
                    title={intl.formatMessage({ id: "remove-queue" })}
                    onClick={() => confirmeRemoveQueue(elm, list[elm].queueName, list[elm].scope)}
                  />
                  <Button
                    icon={<EditIcon />}
                    text
                    iconOnly
                    title={intl.formatMessage({ id: "edit-queue" })}
                    onClick={(event) => selectQueue(event, elm)}
                  />
                </div>
              ,
              key: i + "-6",
            },
          ],
        };
        setRowsPlain((rowsPlain) => [...rowsPlain, rowTable]);
        return rowsPlain;
      }
      return rowsPlain;
    });
  };
  const selectQueue = (event, data) => {
    history.push(`/queues/${data}`);
  }
  useEffect(() => {
    if (props.scopeList) {
      let scopeListName = Object.keys(props.scopeList)
      props.getAutoAttendantInitState(scopeListName);
    }
  }, [props.scopeList]);
  useEffect(() => {
    getQueuesList();
  }, [props.queuesInfos]);
  const addQueue = () => {
    history.push(`/queues/newQueue`);
    props.createNewQueue();
  };
  const header = {
    key: "header",
    items: [
      {
        content: intl.formatMessage({ id: "name" }),
        key: "name",
      },
      {
        content: intl.formatMessage({ id: "scope" }),
        key: "scope",
      },
      {
        content: intl.formatMessage({ id: "hunt-method" }),
        key: "huntMethod",
      },
      {
        content: intl.formatMessage({ id: "queue-size" }),
        key: "maxInQueue",
      },
      {
        content: intl.formatMessage({ id: "max-wait-time" }),
        key: "maxWaitTime ",
      },
      {
        content: intl.formatMessage({ id: "number-of-agents" }),
        key: "agentsIdsLegth",
      },
      {
        content: intl.formatMessage({ id: "action" }),
        key: "action",
      },
    ],
  };
  return (
    <div className="table-list">
      <h2>{translate("queues-list")}</h2>
      <div className="table-header">
        <Button
          icon={<AddIcon />}
          text
          content={intl.formatMessage({ id: "add" })}
          primary
          onClick={addQueue}
        />
      </div>
      <Table
        className="table-agent"
        variables={{
          cellContentOverflow: "none",
        }}
        header={header}
        rows={rowsPlain}
        aria-label="Static table"
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  queuesInfos: state.autoAttendant,
  scopeList: state.scopeList,
})
const mapDispatchToProps = (dispatch) => ({
  getAutoAttendantInitState: (scopeListName) => dispatch(getAutoAttendantInitState(scopeListName)),
  createNewQueue: () => dispatch(createNewQueue()),
  removeQueueFromDB: (value, scope) => dispatch(removeQueueFromDB(value, scope)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueuesListContainer);
