import React from "react";
import { Dropdown } from "@fluentui/react-northstar";
const inputItems = [
  'fr-FR',
  'fr-CA',
  'en-US',
  'de-DE',
  'es-ES',
  'it-IT', 
  'pt-PT',
];
const Language = (props) => {
  return (
    <>
      <Dropdown
        className="child-title-call-flow"
        items={inputItems}
        placeholder="Language"
        checkable
        defaultValue={props.language}
        onChange={props.getData}
        getA11ySelectionMessage={{
          onAdd: (item) => `${item} has been selected.`,
        }}
      />
    </>
  );
};
export default Language;
