import React, { useState, useEffect } from "react";
import AudioComponent from "./AudioComponent";
import { authContext } from "../../auth";
const QueueFallBackMessageComponent = (props) => {
    const [audiofile, setAudiofile] = useState();
    const [audioType, setAudioType] = useState();
    const [language, setLanguage] = useState();
    const [audio, setAudio] = useState();
    const [queueFallBackMessage, setQueueFallBackMessage] = useState({
        "enabled": props.queueFallBackMessage?.enabled,
        "type": props.queueFallBackMessage?.type,
        "language": props.queueFallBackMessage && props.queueFallBackMessage.language && props.queueFallBackMessage.language !== "" ? props.queueFallBackMessage.language : props.defaultLanguage,
        "text": props.queueFallBackMessage?.text,
        "audioFileUrl": props.queueFallBackMessage?.audioFileUrl,
        "fileName": props.queueFallBackMessage?.fileName
    });
    useEffect(() => {
        if (language && language.header) {
            setQueueFallBackMessage(queueFallBackMessage => ({
                ...queueFallBackMessage,
                language: language.header
            }));
        }
    }, [language])
    useEffect(() => {
        if (audiofile && audiofile.url) {
            setQueueFallBackMessage(queueFallBackMessage => ({
                ...queueFallBackMessage,
                audioFileUrl: audiofile.url,
                fileName: audiofile.header
            }));
        }
    }, [audiofile])
    useEffect(() => {
        if (audioType) {
            setQueueFallBackMessage(queueFallBackMessage => ({
                ...queueFallBackMessage,
                type: audioType
            }));
        }
    }, [audioType])
    useEffect(() => {
        if (audio) {
            setQueueFallBackMessage(queueFallBackMessage => ({
                ...queueFallBackMessage,
                type: audio.type,
                language: audio.language,
                text: audio.text,
                audioFileUrl: audio.audioFileUrl,
                fileName: ""
            }));
        }
    }, [audio])
    useEffect(() => {
        props.setQueueFallBackMessage(queueFallBackMessage)
    }, [queueFallBackMessage])
    return (
        <div className='audio-QueueFallBack'>
            <AudioComponent
                audioList={props.audioList}
                queueName={props.queueName}
                fileLocationUnderCallflow={"/queues/queueFallBackMessage/" + props.queueName}
                setAudioType={setAudioType}
                setAudiofile={setAudiofile}
                setAudioLangue={setLanguage}
                setAudio={setAudio}
                data={queueFallBackMessage}
            />
        </div>
    )
};
export default QueueFallBackMessageComponent;