import React, { useState } from "react";
import {
  RadioGroup,
  Header,
  Dropdown,
  Checkbox,
  Flex,
  TextArea
} from "@fluentui/react-northstar";
import Text2Speech from "../CallCenterComponents/text2Speech";
import { useIntl } from 'react-intl';
import translate from '../../i18n/translate'
const items = [
  {
    name: "Text",
    key: "text",
    label: "Text",
    value: "text2speech",
  }
];
const AnnoucementComponent = ({ audiosList, ...props }) => {
  const [local, setLocal] = useState(props);
  const intl = useIntl();
  const handleToggleChange = (e, val) => {
    let value = val.checked;
    props.getData(e, val.checked, "enable", props.annoucementName);
    setLocal((prevState) => {
      return {
        ...prevState,
        data: { ...prevState.data, enabled: value },
      };
    });
  };
  const [checkboxValue, setCheckbox] = useState(local?.data?.type);
  return (
    <div className="hasChekbox">
      <Flex hAlign="start" vAlign="center" gap="gap.small">
        {!["icr"].includes(props.annoucementName) ? (
          <Header as="h4" content={local.headerTitle} />
        ) : (
          ""
        )}
        {!["holidays", "openHours", "icr"].includes(props.annoucementName) ? (
          <Checkbox
            className="child-title-call-flow"
            toggle
           defaultChecked={local.defaultValue.enabled}
            onClick={handleToggleChange}
          />
        ) : (
          ""
        )}
      </Flex>
      {
        ["holidays"].includes(props.annoucementName) ?
          <Header as="h5" content="Holiday message" className="child-title-call-flow holiday-message" />
          :
          null
      }
      {
        ["openHours"].includes(props.annoucementName) ?
          <Header as="h5" content="Out of business hours message" className="child-title-call-flow holiday-message" />
          :
          null
      }
      <div>
        {(() => { 
          if (checkboxValue !== "disabled") {
            if (props.annoucementName === "icr" || props.annoucementName === "prompt") {
              return (
                <TextArea className='chat-flow-textarea' placeholder={intl.formatMessage({ id: "type-here..." })} />
                );
              } else {
                return (
                <TextArea 
                className='chat-flow-textarea'
                    placeholder={intl.formatMessage({ id: "type-here..." })}
                defaultValue={local.defaultValue.text}
                onChange={(e,data)=>{
                    let value = data.value;
                    props.getData(e,value, "text", props.annoucementName);
                }}
                />
                );
              }
          } else return <></>;
        })()}
      </div>
    </div>
  );
};
export default AnnoucementComponent;
