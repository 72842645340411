/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { EditIcon, Table } from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  createNewQueue,
  getAutoAttendantInitState,
  removeQueueFromDB,
} from "../../redux/actions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AddIcon } from "@fluentui/react-icons-northstar";
import { Button } from "@fluentui/react-northstar";
import { TrashCanIcon } from "@fluentui/react-icons-northstar";
import {getToken} from "../../components/Graph";
import { getAllChatQueuesManagementUrl, removeChatQueueManagementUrl } from "../../urls";
import { authContext }  from '../../auth';
import Axios from "axios";
import translate from "../../i18n/translate";
import { useIntl } from 'react-intl';
const ChatQueuesListContainer = (props) => {
  var [rowsPlain, setRowsPlain] = useState([]);
  const history = useHistory();
  const intl = useIntl();
  const getQueuesList = async () => {
    let Token = await getToken(authContext);
    const url = getAllChatQueuesManagementUrl(authContext._user.profile.tid, Token);
    Axios.get(url).then((res) => {
      if(Object.keys(res.data).length > 0 ){
        formatList(res.data);
      }
    });
    
  };
  const confirmeRemoveQueue = (elm, queueId,queueName) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + queueName + '!',
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => removeQueue(elm, queueId)
        },
        {
          label: intl.formatMessage({ id: "no" }),
        }
      ]
    });
  };
  const removeQueue = async (elm,queueId) => {
    let Token = await getToken(authContext);
    const url = removeChatQueueManagementUrl(authContext._user.profile.tid, Token, queueId);
    Axios.post(url).then((res) => {
      setRowsPlain((state) => {
        const newState = state.filter((data) => {
          return data.key !== elm;
        });
        return newState;
      });
     });
    }
  const formatList = (list) => {
    setRowsPlain([])
    return list.map((elm, i) => {
      if (elm.queueName) {
        let NumberOfAgents = 0;
        if (list[elm]?.agentsIds) {
          NumberOfAgents = list[elm].agentsIds.length;
        }
        let rowTable = {
          key: i,
          items: [
            {
              content: elm.queueName,
              key: i + "-1",
            },
            {
              content: elm.queueType,
              key: i + "-2",
            },
            {
              content: elm.language,
              key: i + "-3",
            },
            {
              content: elm.maxWaitTime,
              key: i + "-4",
            },
            {
              content: elm.agentsIds.length,
              key: i + "-5",
            },
            {
              content:
                <div>
                  <Button
                    icon={<TrashCanIcon />}
                    text
                    iconOnly
                    title={intl.formatMessage({ id: "remove-chat-queue" })}
                    onClick={() => confirmeRemoveQueue(i,  elm.id, elm.queueName)}
                  />
                  <Button
                    icon={<EditIcon />}
                    text
                    iconOnly
                    title={intl.formatMessage({ id: "edit-chat-queue" })}
                    onClick={(event) => selectQueue(event, elm.id)}
                  />
                </div>
              ,
              key: i + "-6",
            },
          ],
        };
        setRowsPlain((rowsPlain) => [...rowsPlain, rowTable]);
        return rowsPlain;
      }
      return rowsPlain;
    });
  };
  const selectQueue = (event, id) => {
    history.push(`/chat-queues/${id}`);
  }
  useEffect(() => {
    getQueuesList();
  }, []);
  const addQueue = () => {
    history.push(`/chat-queues/newChatQueue`);
  };
  const header = {
    key: "header",
    items: [
      {
        content: intl.formatMessage({ id: "name" }),
        key: "name",
      },
      {
        content: intl.formatMessage({ id: "type" }),
        key: "type",
      },
      {
        content: intl.formatMessage({ id: "language" }),
        key: "language",
      },
      {
        content: intl.formatMessage({ id: "max-wait-time" }),
        key: "maxWaitTime ",
      },
      {
        content: intl.formatMessage({ id: "number-of-agents" }),
        key: "agentsIdsLegth",
      },
      {
        content: intl.formatMessage({ id: "action" }),
        key: "action",
      },
    ],
  };
  return (
    <div className="table-list">
      <h2>{translate('chat-queues-list')}</h2>
      <div className="table-header">
        <Button
          icon={<AddIcon />}
          text
          content={intl.formatMessage({ id: "add" })}
          primary
          onClick={addQueue}
        />
      </div>
      <Table
        className="table-agent"
        variables={{
          cellContentOverflow: "none",
        }}
        header={header}
        rows={rowsPlain}
        aria-label="Static table"
      />
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return {
    queuesInfos: state.autoAttendant,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAutoAttendantInitState: () => dispatch(getAutoAttendantInitState()),
  createNewQueue: () => dispatch(createNewQueue()),
  removeQueueFromDB: (value) => dispatch(removeQueueFromDB(value)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatQueuesListContainer);
