import React, { useState, useRef, useEffect, } from "react";
import { Dropdown, Input, Header, Flex, Button, AddIcon, TrashCanIcon, Checkbox } from "@fluentui/react-northstar";
import CallCenterName from "./CallCenterName";
import IvrContainer from "../../containers/CallCenterContainer/IvrContainer";
import PromptContainer from "../../containers/CallCenterContainer/PromptContainer";
import AnnoucementComponent from "../../components/CallCenterComponents/AnnoucementComponent";
import Regexp from "./RegExp";
import Time from "./Time";
import { confirmAlert } from 'react-confirm-alert';
import translate from '../../i18n/translate';
import { useIntl } from 'react-intl';
import { MenuButton } from '@fluentui/react-northstar'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
const Routing = (props) => {
  const childRef = useRef();
  const [dialKey, setDialKey] = useState(false)
  const items = ["Queue", "Disconnect", "IVR", "Prompt", "Regexp", "Time"]
  const [routingType, setRoutingType] = useState(props.data.type === 'ivr' ? 'IVR' : props.data.type.charAt(0).toUpperCase() + props.data.type.slice(1));
  const [dialKeyItems, setDialKeyItems] = useState(["0", 1, 2, 3, 4, 5, 6, 7, 8, 9, "#", "*"]);
  const [showRoutingDetails, setShowRoutingDetails] = useState();
  const [dialKeyErrorMessage, setDialKeyErrorMessage] = useState("");
  const [dialKeyValue, setDialKeyValue] = useState(null);
  const [dialKeyList, setDialKeyList] = useState([]);
  const intl = useIntl();
  const handleChange = (e, data) => {
    setRoutingType(data.value);
    let selectValue = data.value.toLowerCase()
    props.getRadioData(selectValue, "radio", null, props.routingName);
  };
  dialKeyItems.forEach(element => {
    if (props.data.options !== undefined && typeof props.data.options === "object") {
      if (Object.keys(props.data.options).includes(element.toString())) {
        var newDialKeyItems = dialKeyItems.filter(DialKey => DialKey !== element);
        setDialKeyItems(newDialKeyItems)
      }
    }
  });
  const toggleHandler = (routingName) => {
    if (showRoutingDetails === routingName) {
      setShowRoutingDetails('')
    } else {
      setShowRoutingDetails(routingName)
    }
  }
  const confirmeRemoveRouting = async (name) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + name + '!',
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => props.deleteRouting(name)
        },
        {
          label: intl.formatMessage({ id: "no" }),
        }
      ]
    });
  }
  const ValidateUSNumber = (string) => {
    if (string !== null && string.length > 0) {
      var regExp = /^[0-9-*#]+$/;
      var phone = string.match(regExp);
      if (phone) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const addDialKeyCopy = (dialKeyValue) => {
    setDialKeyList([])
    Object.keys(props.data.options).map((elm, i) => {
      let isNumber = ValidateUSNumber(dialKeyValue);
      let item = {
        key: i,
        content:
          <p
            onClick={() => {
              if (dialKeyValue !== null && Object.keys(props.data.options).includes(dialKeyValue)) {
                setDialKeyErrorMessage(translate("DTMF-EXISTANCE-ERROR-MESSAGE"))
              } else if (dialKeyValue === "" || dialKeyValue === null) {
                setDialKeyErrorMessage(translate("DTMF-EMPTY-ERROR-MESSAGE"))
              } else if (isNumber === false) {
                setDialKeyErrorMessage(translate("DTMF-NUMBER-ERROR-MESSAGE"))
              } else {
                props.copyIvr(props.routingName, elm, dialKeyValue)
                setDialKeyErrorMessage('')
                setDialKey(false)
                setDialKeyValue(null)
              }
            }}>{elm}
          </p>
      }
      setDialKeyList(dialKeyList => [...dialKeyList, item]);

    });
  }
  return (
    <div className="list-routing">
      <div className='header-routing' >
        {props.routingName !== "0" ?
          <div className="name-routing">
            {showRoutingDetails === props.routingName ?
              <Button
                primary
                size="smallest"
                icon={<BsChevronUp />}
                text
                title="Show details"
                onClick={() => { toggleHandler(props.routingName) }}
              />
              :
              <Button
                primary
                size="smallest"
                icon={<BsChevronDown />}
                text
                title="Show details"
                onClick={() => { toggleHandler(props.routingName) }}
              />
            }
            <Header className="title-routing" content={props.routingName} />
            {showRoutingDetails === props.routingName ?
              <Button
                size="smallest"
                primary
                icon={<TrashCanIcon />}
                text
                title={intl.formatMessage({ id: "delete-routing" })}
                onClick={() => confirmeRemoveRouting(props.routingName)}
              />
              :
              null
            }
          </div>
          :
          <div className='start-routing'>
            {showRoutingDetails === props.routingName ?
              <Button
                primary
                size="smallest"
                icon={<BsChevronUp />}
                text
                title="Show details"
                onClick={() => { toggleHandler(props.routingName) }}
              />
              :
              <Button
                primary
                size="smallest"
                icon={<BsChevronDown />}
                text
                title="Show details"
                onClick={() => { toggleHandler(props.routingName) }}
              />
            }
            <Header className='title-start-routing' content="Start" />
          </div>
        }
      </div>
      {
        showRoutingDetails === props.routingName ?
          <div className='select-routing' style={{ marginLeft: '70px' }}>
            <label style={{ marginLeft: '50px', fontWeight: '700' }}>{translate("routing-type")}</label>
            <Dropdown
              style={{ marginBottom: '10px' }}
              className="child-title-call-flow"
              vertical="true"
              value={routingType}
              items={items}
              onChange={handleChange}
            />
            {
              (() => {
                if (routingType !== "disconnect" || routingType !== "Disconnect") {
                  if (routingType === "queue" || routingType === "Queue") {
                    return (
                      <Flex>
                        <div className="child-title-call-flow">
                          <CallCenterName
                            searchQueue
                            itemName={"queue"}
                            getData={props.getRadioData}
                            displayName={props.data.queueDisplayName}
                            routingName={props.routingName}
                            scope={props.scope}
                          />
                        </div>
                      </Flex>
                    );
                  } else if (routingType === "ivr" || routingType === "IVR") {
                    return (
                      <>
                        <label style={{ marginLeft: '50px', fontWeight: '700' }} >{translate("IVR-name")}</label>
                        <div style={{ marginBottom: '10px', color: 'red', marginLeft: '50px' }}>{props.IVRErrorMessage}</div>
                        <Input
                          style={{ fontWeight: '700' }}
                          className="ivr-name-input"
                          onChange={(e, data) => {
                            props.setIvrName(data.value, props.routingName);
                          }}
                          value={props.data.displayName}
                        />

                        <div className="last-digit-div">
                          <label>{intl.formatMessage({ id: "last-digit" })} </label>
                          <Checkbox
                            id="checkbox-IVR"
                            toggle
                            defaultChecked={props.data.lastTone === "#" ? true : false}
                            onChange={(e, val) => {
                              let value = ""
                              if (val.checked) {
                                value = "#"
                              }
                              props.setLastTone(props.routingName, value)
                            }} />
                        </div>
                        <div className="digits-length-div" >
                          <label>{intl.formatMessage({ id: "digits-length" })}  </label>
                          <Input
                            type="number"
                            min="1"
                            max="15"
                            value={props.data?.expectedDigitNumber}
                            onChange={(data) => {
                              props.setExpectedDigitNumber(props.routingName, data.target.value)
                            }} />
                        </div>
                        <AnnoucementComponent
                          routingName={props.routingName}
                          getSelectedIvrData={props.getSelectedIvrData}
                          getIvrText={props.getIvrText}
                          headerTitle="ivr"
                          annoucementName="ivr"
                          getData={props.getRadioData}
                          data={props.data.audio}
                          audiosList={props.audiosList}
                          callFlowId={props.callFlowId}
                        />
                        <Flex>
                          <Flex column >
                            <div className="ivr-div">
                              <Flex.Item inline align="end">
                                <Button
                                  icon={<AddIcon />}
                                  text
                                  primary
                                  content={intl.formatMessage({ id: "add-new-dial-key" })}
                                  onClick={() => setDialKey(true)}
                                />
                              </Flex.Item>
                              {
                                dialKey === true ?
                                  <>
                                    <Input
                                      onChange={(e, data) => {
                                        setDialKeyValue(data.value)
                                      }}
                                    />
                                    <MenuButton
                                      onOpenChange={() => {
                                        addDialKeyCopy(dialKeyValue)
                                      }}
                                      trigger={<Button icon={<AddIcon />} title={intl.formatMessage({ id: 'add-new-DTMF' })} />}
                                      menu={[
                                        <p
                                          key="1"
                                          onClick={() => {
                                            let isNumber = ValidateUSNumber(dialKeyValue);
                                            if (dialKeyValue !== null && Object.keys(props.data.options).includes(dialKeyValue)) {
                                              setDialKeyErrorMessage(translate("DTMF-EXISTANCE-ERROR-MESSAGE"))
                                            } else if (dialKeyValue === "" || dialKeyValue === null) {
                                              setDialKeyErrorMessage(translate("DTMF-EMPTY-ERROR-MESSAGE"))
                                            } else if (isNumber === false) {
                                              setDialKeyErrorMessage(translate("DTMF-NUMBER-ERROR-MESSAGE"))
                                            } else {
                                              setDialKeyErrorMessage('')
                                              childRef.current.addIvr(dialKeyValue)
                                              setDialKey(false)
                                              setDialKeyValue(null)
                                            }
                                          }}>{translate("add-DTMF-without-copy")}</p>,
                                        {
                                          key: "2",
                                          content: intl.formatMessage({ id: 'copy-from-other-routing' }),
                                          menu: dialKeyList,
                                        },

                                      ]}
                                    />


                                  </>
                                  :
                                  null
                              }
                            </div>
                            <div style={{ marginBottom: '10px', color: 'red', marginLeft: '50px' }}>{dialKeyErrorMessage}</div>
                            <IvrContainer
                              scope={props.scope}
                              routingList={props.routingList}
                              routingName={props.routingName}
                              getDialKeyInfos={props.getData}
                              getData={props.getRadioData}
                              data={props.data}
                              removeIvr={props.removeIvr}
                              ref={childRef}
                              setDisconnectIvr={props.setDisconnectIvr}
                            />
                          </Flex>

                        </Flex>
                      </>
                    );
                  } else if (routingType === "prompt" || routingType === "Prompt") {
                    return (
                      <>
                        <label style={{ marginLeft: '50px', fontWeight: '700' }}>{translate("prompt-name")}</label>
                        <Input
                          className="ivr-name-input routing-child"
                          onChange={(e, data) => {
                            props.setPromptName(data.value, props.routingName);
                          }}
                          defaultValue={props.data.promptDisplayName}
                        />
                        <label style={{ marginLeft: '50px', fontWeight: '700' }}>{translate("text-to-speech")}</label>
                        <AnnoucementComponent
                          className="routing-child"
                          routingName={props.routingName}
                          getSelectedPromptData={props.getSelectedPromptData}
                          getIvrText={props.getIvrText}
                          headerTitle="prompt"
                          annoucementName="prompt"
                          getData={props.getRadioData}
                          data={props.data.audio}
                          audiosList={props.audiosList}
                          callFlowId={props.callFlowId}
                        />
                        <Flex>
                          <Flex column >
                            <div style={{ marginLeft: '50px' }}>
                              <Flex.Item inline align="end">
                                <label style={{ fontWeight: '700' }}>{translate("next")}</label>
                              </Flex.Item>
                              <PromptContainer
                                scope={props.scope}
                                routingList={props.routingList}
                                routingName={props.routingName}
                                getDialKeyInfos={props.getData}
                                getData={props.getRadioData}
                                data={props.data}
                              />
                            </div>

                          </Flex>

                        </Flex>
                      </>
                    );
                  } else if (routingType === "Regexp" || routingType === "regexp") {
                    return (
                      <Regexp
                        scope={props.scope}
                        getData={props.getRadioData}
                        routingName={props.routingName}
                        setRegexpValue={props.setRegexpValue}
                        setOnUnMatched={props.setOnUnMatched}
                        setOnMatched={props.setOnMatched}
                        routingList={props.routingList}
                        data={props.data}
                      />
                    )
                  } else if (routingType === "Time" || routingType === "time") {
                    return (
                      <Time
                        scope={props.scope}
                        setStartTime={props.setStartTime}
                        setEndTime={props.setEndTime}
                        routingName={props.routingName}
                        data={props.data}
                        routingList={props.routingList}
                        setOnMatched={props.setOnMatched}
                        setOnUnMatched={props.setOnUnMatched}
                        getData={props.getRadioData}
                      />
                    )
                  }
                } else return <></>;
              })()
            }
          </div>
          :
          null
      }
      <hr></hr>
    </div>
  );
};
export default Routing;
