import React, { useEffect, useState } from "react";
import {
  Datepicker,
  Header,
  Flex,
  Button,
  AddIcon,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import moment from "moment-timezone";
import update from "immutability-helper";
import { v4 as uuidv4 } from "uuid";
import { useIntl } from 'react-intl';
import translate from '../../i18n/translate';
const Holidays = (props) => {
  const [state, setState] = useState({});
  const intl = useIntl();
  useEffect(() => {
    props.daysOffList.forEach((element) => {
      element.id = uuidv4();
    });
    setState(props);
  }, [props]);
  const addNewHoliday = (e) => {
    var date = new Date();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    const obj = { start: day + "/" + month, end: day + "/" + month, id: uuidv4() };
    setState((state) => {
      const newState = update(state, { daysOffList: { $push: [obj] } });
      props.getData(e, newState.daysOffList, "daysOff", props.annoucementName);
      return newState;
    });
  };
  const deleteHoliday = (e, id) => {
    const objects = state.daysOffList.filter((elm) => elm.id !== id);
    setState((state) => {
      const newState = update(state, { daysOffList: { $set: objects } });
      props.getData(e, newState.daysOffList, "daysOff", props.annoucementName);
      return newState;
    });
  };
  const updateHolidaystate = (e, data, id, position) => {
    setState((state) => {
      const lineindx = state.daysOffList.findIndex((elm) => elm.id === id);
      const newState = update(state, {
        daysOffList: {
          [lineindx]: {
            [position]: { $set: moment(data.value).format("DD/MM") },
          },
        },
      });
      props.getData(e, newState.daysOffList, "daysOff", props.annoucementName);
      return newState;
    });
  };
  useEffect(() => {
  }, [state.daysOffList]);
  return (
    <>
      <Header as="h5" content={intl.formatMessage({ id: "select-holidays" })} />
      <div className="child-title-call-flow">
        <Button
          primary
          icon={<AddIcon />}
          text
          content={intl.formatMessage({ id: "add-new-holidays" })}
          onClick={() => addNewHoliday(null, state)}
        />
        {state?.daysOffList?.map((elm, i) => {
          return (
            <Flex className="holidays" key={elm.id} gap="gap.small">
              <>
                <Header as="h6" content={intl.formatMessage({ id: "start-date" })} />
                <Datepicker
                  onDateChange={(e, val) =>
                    updateHolidaystate(e, val, elm.id, "start")
                  }
                  defaultSelectedDate={moment(
                    `${elm.start}/${new Date().getFullYear()}`,
                    "DD/MM/YYYY"
                  ).toDate()}
                />
              </>
              <>
                <Header as="h6" content={intl.formatMessage({ id: "end-date" })} />
                <Datepicker
                  onDateChange={(e, val) =>
                    updateHolidaystate(e, val, elm.id, "end")
                  }
                  defaultSelectedDate={moment(
                    `${elm.end}/${new Date().getFullYear()}`,
                    "DD/MM/YYYY"
                  ).toDate()}
                />
              </>
              <Button
                primary
                icon={<TrashCanIcon />}
                text
                onClick={(e) => deleteHoliday(e, elm.id)}
              />
            </Flex>
          );
        })}
      </div>
    </>
  );
};
export default Holidays;
