import React from "react";
import { Dropdown } from "@fluentui/react-northstar";
const inputItems = [
  'United States',
  'Canada',
  'Europe',
  'Australia',
  'Singapore'
];
const DataLocation = (props) => {
  return (
    <>
      <Dropdown
        className="child-title-call-flow"
        items={inputItems}
        placeholder="DataLocation"
        checkable
        defaultValue={props.dataLocation}
        onChange={props.getData}
        getA11ySelectionMessage={{
          onAdd: (item) => `${item} has been selected.`,
        }}
      />
    </>
  );
};
export default DataLocation;
