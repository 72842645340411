import React, { useState, useEffect } from "react";
import { Dropdown, Input, Flex, Label, Button } from "@fluentui/react-northstar";
import CallCenterName from './CallCenterName';
import translate from '../../i18n/translate';
import { useIntl } from 'react-intl';
const Regexp = (props) => {
    const inputItems = ["Queue", "Transfer to user", "Disconnect", "Routing", "Transfer to phone"]
    const [message, setMessage] = useState();
    const [testInput, setTestInput] = useState()
    const [isTesting, setIsTesting] = useState(false)
    const [OnMatchedRedirect, setOnMatchedRedirect] = useState(null);
    const [OnUnMatchedRedirect, setOnUnMatchedRedirect] = useState();
    const parseRegex = require("regex-parser")
    const [regex, setRegex] = useState();
    const intl = useIntl();
    function regexPhoneNumber(str) {

        if (str && str.length > 0) {

            try {
                const regexNumber = parseRegex(regex);
                if (str.match(regexNumber)) {
                    setMessage(
                        <div className=" alert alert-success" style={{ marginTop: '10px', minHeight: '90px' }}>
                            <p >{translate("matched")}</p>
                        </div>
                    )
                } else {
                    setMessage(
                        <div className="alert alert-danger" style={{ marginTop: '10px', minHeight: '90px' }}>
                            <p >{translate("not-matched")}</p>
                        </div>
                    )

                }
            } catch (e) {
                setMessage(
                    <div className="alert alert-danger" style={{ marginTop: '10px', minHeight: '90px' }}>
                        <p >{translate("Invalid regular expression !")}</p>
                    </div>
                )
            }
        } else {
            setMessage(
                <div className="alert alert-danger" style={{ marginTop: '10px', minHeight: '90px' }}>
                    <p >{translate("You can not verify an empty input !")}</p>
                </div>
            )
        }
    }
    const onChangeMatchedType = (value) => {
        switch (value) {
            case "queue":
                setOnMatchedRedirect(
                    <div>
                        <label>{translate("queue-name")}</label>
                        <CallCenterName
                        scope={props.scope}
                            from="regexp"
                            searchQueue
                            itemName="OnMatchedRegexpQueue"
                            getData={props.getData}
                            routingName={props.routingName}
                            displayName={props.data.onMatched !== undefined ? (props.data.onMatched?.queueDisplayName) : ''}
                        />
                    </div>

                )
                break;
            case "transfer to user":
                setOnMatchedRedirect(
                    <div>
                        <label>User name</label>
                        <CallCenterName
                            itemName="OnMatched transfer to user"
                            getData={props.getData}
                            searchactifProfils
                            routingName={props.routingName}
                            displayName={props.data.onMatched !== undefined ? (props.data.onMatched?.userDisplayName) : ''}
                        />
                    </div>
                )
                break;
            case "disconnect":

                props.getData(null, "OnMatchedDisconnect", null, props.routingName)
                setOnMatchedRedirect(null)
                break;
            case "routing":
                let routingListName = Object.keys(props.routingList);
                for (var i = 0; i < routingListName.length; i++) {
                    if (routingListName[i] === "0") {
                        routingListName[i] = 'Start'
                    }
                }
                setOnMatchedRedirect(
                    <div>
                        <label>Routing name</label>
                        <Dropdown
                            className="StaticDropDown"
                            items={routingListName}
                            onChange={(e, data) => {
                                props.getData(data.value, "OnMatchedRouting", null, props.routingName)
                            }}
                            defaultValue={props.data.onMatched !== undefined ? (props.data.onMatched.routingName === "0" ? "Start" : props.data.onMatched.routingName) : ''}
                        />
                    </div>

                )
                break;
            case "transfer to phone":
                setOnMatchedRedirect(
                    <div className="regexp-transfer-to-phone-input">
                        <label>Phone number</label>
                        <Input
                            onChange={(e, data) => {
                                props.getData(data.value, "OnMatched transfer to phone", null, props.routingName)
                            }}
                            defaultValue={props.data.onMatched !== undefined ? (props.data.onMatched?.targetId) : ''}
                        />
                    </div>
                )
                break;
            default:
                if (props.data.onMatched.targetType === "user") {
                    setOnMatchedRedirect(
                        <div>
                            <label>User name</label>
                            <CallCenterName
                                itemName="OnMatched transfer to user"
                                getData={props.getData}
                                searchactifProfils
                                routingName={props.routingName}
                                displayName={props.data.onMatched !== undefined ? (props.data.onMatched?.userDisplayName) : ''}
                            />
                        </div>
                    )
                } else if (props.data.onMatched.targetType === "phone") {
                    setOnMatchedRedirect(
                        <div className="regexp-transfer-to-phone-input">
                            <label>Phone number</label>
                            <Input
                                onChange={(e, data) => {
                                    props.getData(data.value, "OnMatched transfer to phone", null, props.routingName)
                                }}
                                defaultValue={props.data.onMatched !== undefined ? (props.data.onMatched?.targetId) : ''}
                            />
                        </div>
                    )
                }
                break;
        }
    }
    const onChangeUnMatchedType = (value) => {
        switch (value) {
            case "queue":
                setOnUnMatchedRedirect(
                    <div>
                        <label>Queue name</label>
                        <CallCenterName
                        scope={props.scope}
                            from="regexp"
                            searchQueue
                            itemName="OnUnMatchedRegexpQueue"
                            getData={props.getData}
                            routingName={props.routingName}
                            displayName={props.data.onUnMatched !== undefined && props.data.onUnMatched.queueDisplayName !== undefined ? (props.data.onUnMatched.queueDisplayName) : ''}
                        />
                    </div>

                )
                break;
            case "transfer to user":
                setOnUnMatchedRedirect(
                    <div>
                        <label>User name</label>
                        <CallCenterName
                            itemName="OnUnMatched transfer to user"
                            getData={props.getData}
                            searchactifProfils
                            routingName={props.routingName}
                            displayName={props.data.onUnMatched !== undefined && props.data.onUnMatched.userDisplayName !== undefined ? (props.data.onUnMatched.userDisplayName) : ''}
                        />
                    </div>

                )
                break;
            case "disconnect":
                props.getData(null, "OnUnMatchedDisconnect", null, props.routingName)
                setOnUnMatchedRedirect()
                break;
            case "routing":
                let routingListName = Object.keys(props.routingList);

                for (var i = 0; i < routingListName.length; i++) {
                    if (routingListName[i] === "0") {
                        routingListName[i] = 'Start'
                    }
                }
                setOnUnMatchedRedirect(
                    <div>
                        <label>Routing name</label>
                        <Dropdown
                            className="StaticDropDown"
                            items={routingListName}
                            onChange={(e, data) => {
                                props.getData(data.value, "OnUnMatchedRouting", null, props.routingName)
                            }}
                            defaultValue={props.data.onUnMatched !== undefined ? (props.data.onUnMatched.routingName === "0" ? "Start" : props.data.onUnMatched.routingName) : null}
                        />
                    </div>

                )
                break;
            case "transfer to phone":
                setOnUnMatchedRedirect(
                    <div className="regexp-transfer-to-phone-input">
                        <label>Phone number</label>
                        <Input
                            onChange={(e, data) => {
                                props.getData(data.value, "OnUnMatched transfer to phone", null, props.routingName)
                            }}
                            defaultValue={props.data.onUnMatched !== undefined && props.data.onUnMatched.targetId !== undefined ? (props.data.onUnMatched.targetId) : ''}
                        />
                    </div>
                )
                break;
            default:
                if (props.data.onUnMatched.targetType === "user") {
                    setOnUnMatchedRedirect(
                        <div>
                            <label>User name</label>
                            <CallCenterName
                                itemName="OnUnMatched transfer to user"
                                getData={props.getData}
                                searchactifProfils
                                routingName={props.routingName}
                                displayName={props.data.onUnMatched !== undefined && props.data.onUnMatched.userDisplayName !== undefined ? (props.data.onUnMatched.userDisplayName) : ''}
                            />
                        </div>

                    )
                } else if (props.data.onUnMatched.targetType === "phone") {
                    setOnUnMatchedRedirect(
                        <div className="regexp-transfer-to-phone-input">
                            <label>Phone number</label>
                            <Input
                                onChange={(e, data) => {
                                    props.getData(data.value, "OnUnMatched transfer to phone", null, props.routingName)
                                }}
                                defaultValue={props.data.onUnMatched !== undefined && props.data.onUnMatched.targetId !== undefined ? (props.data.onUnMatched.targetId) : ''}
                            />
                        </div>
                    )
                }
                break;
        }
    }
    useEffect(() => {
        setRegex(props.data.regExp)
    }, [props.data.regExp]);
    useEffect(() => {
        if (props.data.onMatched !== undefined) {
            onChangeMatchedType(props.data.onMatched.type)
        }
    }, []);
    useEffect(() => {
        if (props.data.onUnMatched !== undefined) {
            onChangeUnMatchedType(props.data.onUnMatched.type)
        }
    }, []);
    return (
        <>
            <Flex>
                <div className="row">
                    <div className="col-5">
                        <label style={{ marginLeft: '50px', fontWeight: '700' }}>RegExp</label>
                        <Input
                            className="child-title-call-flow"
                            style={{ width: '355px' }}
                            onChange={(e, data) => { props.setRegexpValue(data.value, props.routingName) }}
                            defaultValue={props.data.regExp}
                        />
                        <div className="row" style={{ justifyContent: 'space-around', marginTop: '10px' }}>
                            <div className="col-12" style={{ marginLeft: '100px' }}>
                                <label style={{ fontWeight: '700' }}>{translate('if-matched')}</label>
                                <div >
                                    <div style={{ marginBottom: '10px' }}>
                                        <Dropdown
                                            items={inputItems}
                                            checkable
                                            getA11ySelectionMessage={{
                                                onAdd: item => `${item} has been selected.`,
                                            }}
                                            onChange={(e, data) => {
                                                let value = data.searchQuery.toLowerCase();
                                                props.setOnMatched(value, props.routingName);
                                                onChangeMatchedType(value);
                                            }}
                                            defaultValue={
                                                props.data.onMatched !== undefined && props.data.onMatched.type === "transfer" ?
                                                    props.data.onMatched.targetType === "user" ?
                                                        "Transfer to user"
                                                        :
                                                        "Transfer to phone"
                                                    :
                                                    props.data.onMatched !== undefined ?
                                                        (props.data.onMatched.type.charAt(0).toUpperCase() + props.data.onMatched.type.slice(1))
                                                        :
                                                        null
                                            }
                                        />
                                    </div>
                                    {OnMatchedRedirect}
                                </div>
                            </div>
                            <div className="col-12" style={{ marginLeft: '100px', marginTop: '10px' }}>
                                <label style={{ fontWeight: '700' }}>{translate('if-not-matched')}</label>
                                <div >
                                    <div style={{ marginBottom: '10px' }}>
                                        <Dropdown
                                            items={inputItems}
                                            checkable
                                            getA11ySelectionMessage={{
                                                onAdd: item => `${item} has been selected.`,
                                            }}
                                            onChange={(e, data) => {
                                                let value = data.searchQuery.toLowerCase();
                                                props.setOnUnMatched(value, props.routingName);
                                                onChangeUnMatchedType(value)
                                            }}
                                            defaultValue={
                                                props.data.onUnMatched !== undefined && props.data.onUnMatched.type === "transfer" ?
                                                    props.data.onUnMatched.targetType === "user" ?
                                                        "Transfer to user"
                                                        :
                                                        "Transfer to phone"
                                                    :
                                                    props.data.onUnMatched !== undefined ?
                                                        (props.data.onUnMatched.type.charAt(0).toUpperCase() + props.data.onUnMatched.type.slice(1))
                                                        :
                                                        null
                                            }
                                        />
                                    </div>
                                    {OnUnMatchedRedirect}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-5" style={{ marginTop: '-70px' }}>
                        <div className="row" style={{ marginTop: '25px' }}>
                            <Input
                                className="col-12"
                                onChange={(e, data) => {
                                    setTestInput(data.value)
                                    setIsTesting(false)
                                }}
                            />
                            <Button style={{ marginTop: '10px', marginLeft: '15px' }} className="col-3" content={intl.formatMessage({ id: "test" })} loader="Index sensor" primary onClick={() => { regexPhoneNumber(testInput); setIsTesting(true) }} />

                        </div>
                        {
                            isTesting ?
                                message
                                :
                                <div className="alert alert-secondary" style={{ marginTop: '10px', minHeight: '90px' }}>
                                    <p >{translate('result')}</p>
                                </div>
                        }

                    </div>
                </div>
            </Flex>
        </>
    );
};
export default Regexp;
