import React, { useEffect, useState } from "react";
import { List, Button  } from '@fluentui/react-northstar'
import { ArrowDownIcon,ArrowUpIcon  } from '@fluentui/react-icons-northstar'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
const AgentQueues = (props) => {
    const [agentQueueIds, setAgentQueueIds] = useState(props.agent.agentQueueIds);
    const [queueList, setQueueList] = useState([]);
    const [isOrderUpdate, setIsOrderUpdate] = useState(false);
    const formatQueuesList = () => {
        for (const queue of agentQueueIds) {
            let item = {
                key: queue,
                header: props.agent.agentQueueDetails[queue].displayName,
                headerMedia:
                    <div>
                        <Button icon={<ArrowUpIcon />} text iconOnly title="Up"
                         disabled={agentQueueIds.indexOf(queue) === 0 ? true : false}
                            onClick={() => {
                                let newOrderQueue = agentQueueIds;
                                const fromIndex = newOrderQueue.indexOf(queue);
                                const toIndex = fromIndex - 1;
                                const element = newOrderQueue.splice(fromIndex, 1)[0];
                                newOrderQueue.splice(toIndex, 0, element);
                                setAgentQueueIds(newOrderQueue)
                                setIsOrderUpdate(true)

                            }}
                        />
                    </div>,
            }
            setQueueList(queueList => [...queueList, item]);
        }
    }
    useEffect(() => {
        if (props.agent && props.agent.agentQueueIds && props.agent.agentQueueIds.length > 0) {
            formatQueuesList()
        }
    }, [agentQueueIds])
    useEffect(() => {
        if (isOrderUpdate) {
            setQueueList([])
            formatQueuesList()
            props.setAgent({ ...props.agent, agentQueueIds: agentQueueIds })
            setIsOrderUpdate(false)
        }
    }, [isOrderUpdate])
    return (
        <div style={{ width: '50%' }}>
            <List items={queueList} />
        </div>

    )
}
export default AgentQueues;