import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authContext } from "../auth";

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return isAuthorized() ? (
        <Component {...rest} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: props.redirectTo,
            state: {
              from: props.location,
            },
          }}
        />
      );
    }}
  ></Route>
);

const isAuthorized = () => {
  const cachedUser = authContext.getCachedUser();
  return cachedUser.userName.length > 0;
};
export default ProtectedRoute;
