import axios from "axios";
import { authContext } from "../auth";
import { getToken } from "../components/Graph";
import { getAllQueueManagementUrl, RemoveQueueManagementUrl, getQueueManagementURL, getAllAudioManagementUrl } from "../urls"
export const setAdmin = (adminState) => ({
  type: "SET_ADMIN",
  adminState,
});
export const setConsentedApps = (apps) => ({
  type: "SET_CONSENTED_APPS",
  apps,
});
export const setCallQueueList = (QueueList) => ({
  type: "SET_CALLQUEUELIST",
  QueueList,
});
export const setTechnicalUser = (technicalUser) => ({
  type: "SET_TECHNICAL_USER",
  technicalUser,
});
export const setAdminSearchResult = (adminSearchResult) => ({
  type: "SET_ADMIN_SEARCH_RESULT",
  adminSearchResult,
});
export const addMailToList = (Mail) => ({
  type: "ADD_MAIL_TO_LIST",
  Mail,
});
export const addHoldOnMusic = (music) => ({
  type: "ADD_HOLD_ON_MUSIC",
  music,
});
export const deleteMailFromList = (MailToDelete) => ({
  type: "DELETE_MAIL_FROM_LIST",
  MailToDelete,
});
export const setMaxWaitTime = (maxWaitTime) => ({
  type: "SET_MAXWAITTIME",
  maxWaitTime,
});
export const setSlaThresholdInStore = (slaThreshold) => ({
  type: "SET_SLA_THRESHOLD",
  slaThreshold,
});
export const setWrapUpTime = (wrapUpTime) => ({
  type: "SET_WRUPUP_TIME",
  wrapUpTime,
});
export const setSlaInStore = (sla) => ({
  type: "SET_SLA",
  sla,
});
export const setMaxAlertTime = (maxAlertTime) => ({
  type: "SET_MAX_ALERTTIME",
  maxAlertTime,
});
export const setQueueName = (queueName) => ({
  type: "SET_QUEUENAME",
  queueName,
});
// set annaouncement position in queue in the state (true/false)
export const setAnnouncePositionInQueue = (announcePositionInQueue) => ({
  type: "SET_ANNOUNCE_POSITION_IN_QUEUE",
  announcePositionInQueue,
});
export const setAnnounceEstimatedWaitTime = (announceEstimatedWaitTime) => ({
  type: "SET_ANNOUNCE_ESTIMATED_WAIT_TIME",
  announceEstimatedWaitTime,
});
export const setWaitTimeIntervals = (interval) => ({
  type: "SET_WAIT_TIME_INTERVALS",
  interval
})
export const deleteWaitTimeInterval = (interval) => ({
  type: "DELETE_WAIT_TIME_INTERVAL",
  interval
})

export const setFallbackIfNoAgentConnected = (value) => ({
  type: "SET_FALL_BACK_IF_NO_AGENT_CONNECTED",
  value
})
export const playMessageBeforeQueueFallBack = (value) => ({
  type: "SET_PLAY_MESSAGE_BEFORE_QUEUE_FALLBACK",
  value
})
export const setQueueFallBackMessage = (value) => ({
  type: "SET_QUEUE_FALL_BACK_MESSAGE",
  value
})
export const setAuthorizedActivityValues = (values) => ({
  type: "SET_AUTHORIZED_ACTIVITY_VALUES",
  values
})
export const setVipPeriority = (enableVipPriority) => ({
  type: "SET_VIP_PRIORITY_IN_QUEUE",
  enableVipPriority,
})
export const setVipContact = (contact, queueInfos) => ({
  type: "SET_VIP_CONTACT",
  contact,
  queueInfos,
})
export const setSelectedPhoneNumber = (phoneNumber) => ({
  type: "SET_PHONENUMBER",
  phoneNumber,
});

export const setMaxCalls = (maxCalls) => ({
  type: "SET_MAXCALLS",
  maxCalls,
});

export const setIsNotifChecked = (isChecked) => ({
  type: "SET_IS_NOTIF_CHECKED",
  isChecked,
});
export const setSelectedUserTransfertId = (infos) => {
  return {
    type: "SET_SELECTED_USER_TRANSFERT_ID",
    infos,
  };
};
export const addAnsweringAgent = (infos) => ({
  type: "ADD_ANSWERING_AGENT",
  infos,
});
export const setSelectedUserTransfertType = (overflowType) => ({
  type: "SET_SELECTED_USER_TRANSFERT_TYPE",
  overflowType,
});
export const setSelectedOverflowQueue = (queueId, queueDisplayName) => ({
  type: "SET_SELECTED_OVERFLOW_QUEUE",
  queueId,
  queueDisplayName
})
export const setSelectedOverflowType = (actionType) => ({
  type: "SET_SELECTED_OVERFLOW_TYPE",
  actionType,
});
export const deleteAnsweringAgent = (id) => ({
  type: "DELETE_ANSWERING_AGENT",
  id,
});
export const deleteVipContact = (id) => ({
  type: "DELETE_VIP_CONTACT",
  id,
});
export const setHuntMethod = (method) => ({
  type: "SET_HUNT_METHOD",
  method,
});

export const saveSelectedQueue = (queueId) => ({
  type: "SAVE_SELECTED_QUEUE",
  queueId,
});
export const setGlobalAdmins = (globalAdmins) => ({
  type: "SET_GLOBAL_ADMINS",
  globalAdmins,
});
export const setFunctionalAdmins = (functionallAdmins) => ({
  type: "SET_FUNCTIONAL_ADMINS",
  functionallAdmins,
});
export const createNewQueue = () => ({
  type: "CREATE_NEW_QUEUE",
});
export const removeQueue = (queueId) => ({
  type: "REMOVE_QUEUE",
  queueId,
});
export const setQueueInfos = (queueInfos) => ({
  type: "SET_QUEUE_INFOS",
  queueInfos,
});
export const setSaveLoading = (queueId) => ({
  type: "IS_SAVE_LOADING",
  queueId,
});
export const setScopeList = (scopeList) => ({
  type: "SET_SCOPE_LIST",
  scopeList,
})
export const addNewScope = (newScope) => ({
  type: "ADD_NEW_SCOPE",
  newScope,
})
/*
// Actions for AutoAtenndant Page   ////Soon should split action file.
*/
export const getAutoAttendantInitState = (scopeListName) => async (dispatch) => {

  const promises = []
  for (let scope of scopeListName) {
    await axios.get(getAllQueueManagementUrl(authContext._user.profile.tid, authContext._user.profile.oid, scope)).then((res) => {
      promises.push(res.data)
    },
      (error) => console.log({ error })
    );
  };
  Promise.all(promises).then((results) => {
    var stateNew = {}
    for (var result of results) {
      if (Object.keys(result).length > 0) {
        stateNew = Object.assign({}, stateNew, result);
      }
    }
    return stateNew

  }).then((stateNew) => {
    dispatch(setAutoAttendantInitState(stateNew));
  })

};
export const removeQueueFromDB = (queueId, scope) => (dispatch) => {
  // get Attendant page initial data ==> last saved data.
  axios
    .post(RemoveQueueManagementUrl(authContext._user.profile.tid, authContext._user.profile.oid, queueId, scope)).then(
      (res) => {
        dispatch(removeQueue(queueId));
      },
      (error) => console.log({ error })
    );
};
export const setAutoAttendantInitState = (data) => ({
  type: "SET_AUTOATTENDANT_INIT_STATE",
  data,
});
export const setCallFlowList = (list) => ({
  type: "SET_CALLFLOW_LIST",
  list,
});
/*
// Actions for music on hold
*/
export const queryOnHoldMusic = (queryText) => (dispatch, getState) => {
  //  trigger request to get music list 2 possiblities
  //1) get all results once then the component built-in filter feature  filters list or 2) get results depending on taped text => query every typed char
  dispatch(isQueryLoading(true));
  getToken(authContext).then(async (Token) => {
    const url = getAllAudioManagementUrl(authContext._user.profile.tid, Token);
    axios
      .get(url) // fake data from server
      .then(
        (res) => {
          dispatch(setQueryMusicResults(res.data));
        },
        (error) => console.log({ error })
      );
  })

};
export const getQueueInfosById = (queueId, scope) => (dispatch, getState) => {
  //  trigger request to get music list 2 possiblities
  //1) get all results once then the component built-in filter feature  filters list or 2) get results depending on taped text => query every typed char
  //dispatch(isQueryLoading(true))
  let url;
  if (!scope) {
    url = getQueueManagementURL(authContext._user.profile.tid, authContext._user.profile.oid, queueId, "default")
  } else {
    url = getQueueManagementURL(authContext._user.profile.tid, authContext._user.profile.oid, queueId, scope)
  }

  axios
    .post(url)
    .then((res) => {
      let queue = res.data;
      if (queue.channels !== undefined) {
        queue.channels = queue.channels
      } else {
        queue["channels"] = { call: true }
      }
      dispatch(setQueueInfos(queue));
    },
      (error) => console.log(error)
    );
};

export const deleteOnHoldMusic = (id) => ({
  // Delete on hold music
  type: "DELETE_ON_HOLD_MUSIC",
  id,
});

export const isQueryLoading = (isLoading) => ({
  // Delete on hold music
  type: "IS_QUERY_LOADING",
  isLoading,
});

export const setQueryMusicResults = (MusicOnHoldRes) => ({
  // set the query results in the state
  type: "SET_QUERY_MUSIC_RESULTS",
  MusicOnHoldRes,
});

// set the queue language in the state
export const setQueueLanguage = (language) => ({
  type: "SET_QUEUE_LANGUAGE",
  language,
})
export const setQueueScope = (scope) => ({
  type: "SET_QUEUE_SCOPE",
  scope,
})
export const emptyQueueAgent = () => ({
  type: "EMPTY_QUEUE_AGENT",
})
// set the queue selected Agent in the state
export const saveSelectedAgent = (agentInfos) => ({
  type: "SAVE_SELECTED_AGENT",
  agentInfos,
});
//set licences in the store
export const setLicences = (licences) => ({
  type: "SET_LICENCES",
  licences
})
export const setLicencesName = (licences) => ({
  type: "SET_LICENCES_NAME",
  licences
})
//////////////set service //////////////
export const setServicetype = (serviceType) => ({
  type: "SET_SERVICE_TYPE",
  serviceType,
});
export const setServiceApiKey = (apiKey) => ({
  type: "SET_SERVICE_API_KEY",
  apiKey,
});
export const setServiceURL = (url) => ({
  type: "SET_SERVICE_URL",
  url,
});
export const setServiceParameters = (key, parameters) => ({
  type: "SET_SERVICE_PARAMETRES",
  key,
  parameters,
});
export const addNewServiceParam = () => ({
  type: "ADD_NEW_SERVICE_PARAM",
});
export const deleteServiceParametre = (key) => ({
  type: "DELETE_SERVICE_PARAMETRE",
  key,
});
export const setServiceResultUrl = (resultUrl) => ({
  type: "SET_SERVICE_RESULT_URL",
  resultUrl,
});
export const setAgentSkill = (agentId, skill) => ({
  type: "SET_AGENT_SKILL",
  agentId,
  skill,
})
export const setQueueType = (queueType, value) => ({
  type: "SET_QUEUE_TYPE",
  queueType,
  value
})
///////////////

export default saveSelectedAgent;