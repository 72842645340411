import React, {
    useState,
    useRef,
} from "react";
import {
    Dropdown,
    Input,
    Header,
    Flex,
    Button,
    AddIcon,
    TrashCanIcon
} from "@fluentui/react-northstar";
import CallCenterName from "../CallCenterComponents/CallCenterName";
import FormComponent from "./FormComponent";
import Icr from "./Icr";
import { BsCaretDown } from 'react-icons/bs';
import { BiUpArrow } from 'react-icons/bi';
import { useIntl } from 'react-intl';
import translate from '../../i18n/translate';
import { confirmAlert } from 'react-confirm-alert';
const Routing = (props) => {
    const childRef = useRef();
    const [routingType, setRoutingType] = useState(props.data.type);
    const [newRef, setNewRef] = useState()
    const [showRoutingDetails, setShowRoutingDetails] = useState();
    const intl = useIntl();
    const items = [
        {
            header: intl.formatMessage({ id: "queue" }),
            key: "queue"
        },
        {
            header: intl.formatMessage({ id: "customerChoice" }),
            key: "customerChoice"
        },
        {
            header: intl.formatMessage({ id: "form" }),
            key: "form",
        }
    ];
    const handleChange = (e, data) => {
        let value = data.value.key;
        switch (value) {
            case "customerChoice":
                setRoutingType("customerChoice");
                props.getRadioData("customerChoice", "radio", null, props.routingName);
                break;
            case "queue":
                setRoutingType("queue");
                props.getRadioData("queue", "radio", null, props.routingName);
                break;
            case "form":
                setRoutingType("form");
                props.getRadioData("form", "radio", null, props.routingName);
                break;
        }

    };
    const setRoutingDefaultValue = (value) => {
        let result = ""
        switch (value) {
            case "customerChoice":
                result = intl.formatMessage({ id: "customerChoice" })
                break;
            case "queue":
                result = intl.formatMessage({ id: "queue" })
                break;
            case "form":
                result = intl.formatMessage({ id: "form" })
                break;
        }
        return result
    }
    const toggleHandler = (routingName) => {
        if (showRoutingDetails === routingName) {
            setShowRoutingDetails('')
        } else {
            setShowRoutingDetails(routingName)
        }
    }
    const confirmeRemoveRouting = async (name) => {
        confirmAlert({
            title: intl.formatMessage({ id: "confirmation" }),
            message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + name + '!',
            buttons: [
                {
                    label: intl.formatMessage({ id: "yes" }),
                    onClick: () => props.deleteRouting(name)
                },
                {
                    label: intl.formatMessage({ id: "no" }),
                }
            ]
        });
    }
    return (
        <div className="list-routing">
            <div className='header-routing' >
                {props.routingName !== "0" ?
                    <div className="name-routing">
                        {showRoutingDetails === props.routingName ?
                            <Button
                                primary
                                size="smallest"
                                icon={<BiUpArrow />}
                                text
                                title="Show details"
                                onClick={() => { toggleHandler(props.routingName) }}
                            />
                            :
                            <Button
                                primary
                                size="smallest"
                                icon={<BsCaretDown />}
                                text
                                title="Show details"
                                onClick={() => { toggleHandler(props.routingName) }}
                            />
                        }
                        <Header className="title-routing" content={props.routingName} />
                        {showRoutingDetails === props.routingName ?
                            <Button
                                size="smallest"
                                primary
                                icon={<TrashCanIcon />}
                                text
                                title={intl.formatMessage({ id: "delete-routing" })}
                                onClick={() => confirmeRemoveRouting(props.routingName)}
                            />
                            :
                            null
                        }
                    </div>
                    :
                    <div className='start-routing'>
                        {showRoutingDetails === props.routingName ?
                            <Button
                                primary
                                size="smallest"
                                icon={<BiUpArrow />}
                                text
                                title="Show details"
                                onClick={() => { toggleHandler(props.routingName) }}
                            />
                            :
                            <Button
                                primary
                                size="smallest"
                                icon={<BsCaretDown />}
                                text
                                title="Show details"
                                onClick={() => { toggleHandler(props.routingName) }}
                            />
                        }
                        <Header className='title-start-routing' content="Start" />
                    </div>
                }
            </div>
            {
                showRoutingDetails === props.routingName ?
                    <div className='select-routing' style={{ marginLeft: '70px' }}>
                        <label style={{ marginLeft: '50px', fontWeight: '700' }}>{translate("routing-type")}</label>
                        <Dropdown
                            style={{ marginBottom: '10px' }}
                            className="child-title-call-flow"
                            vertical="true"
                            value={setRoutingDefaultValue(routingType)}
                            items={items}
                            onChange={handleChange}
                        />
                        {
                            (() => {
                                if (routingType !== "disconnect") {
                                    if (routingType === "queue") {
                                        return (
                                            <Flex>
                                                <div className="child-title-call-flow">
                                                    <CallCenterName
                                                        scope={props.scope}
                                                        searchQueue
                                                        itemName={"queue"}
                                                        getData={props.getRadioData}
                                                        displayName={props.data.queueDisplayName}
                                                        routingName={props.routingName}
                                                    />
                                                </div>
                                            </Flex>
                                        );
                                    } else if (routingType === "form") {
                                        return (
                                            <FormComponent 
                                            setFormData ={props.setFormData}
                                            routingName={props.routingName}
                                            data={props.data}
                                            deleteItemFromRoutingForm={props.deleteItemFromRoutingForm}
                                            updateRoutingFormData={props.updateRoutingFormData}
                                            routingList={props.routingList}
                                            setNextRoutingForm={props.setNextRoutingForm}
                                            chatCenterData={props.chatCenterData}
                                            />
                                        );
                                    } else if (routingType === "customerChoice") {
                                        return (
                                            <>
                                                <div style={{ marginTop: '15px' }}>
                                                    <Input
                                                        className="ivr-name-input"
                                                        onChange={(e, data) => {
                                                            props.setIcrName(data.value, props.routingName);
                                                        }}
                                                        defaultValue={props.data.customerChoiceName}
                                                        label={intl.formatMessage({ id: "name" })}
                                                    />
                                                </div>
                                                <div style={{ marginTop: '15px' }}>
                                                    <Input
                                                        className="ivr-name-input"
                                                        onChange={(e, data) => {
                                                            props.setIcrText(data.value, props.routingName);
                                                        }}
                                                        defaultValue={props.data.customerChoiceText}
                                                        label={intl.formatMessage({ id: "text" })}
                                                    />
                                                </div>
                                                <Flex>
                                                    <Flex column >
                                                        <div className="ivr-div">
                                                            <Flex inline align="end">
                                                                <Button
                                                                    icon={<AddIcon />}
                                                                    text
                                                                    primary
                                                                    content={intl.formatMessage({ id: "add-postBack" })}
                                                                    onClick={async () => {
                                                                        let Ref = Date.now()
                                                                        setNewRef(Ref)
                                                                        childRef.current.addIvr(Ref, props.setPostBack, props.routingName)
                                                                        props.createNewIcr(Ref, props.routingName)
                                                                    }}
                                                                />
                                                            </Flex>
                                                        </div>
                                                        <Icr
                                                            setPostBack={props.setPostBack}
                                                            newRef={newRef}
                                                            getDialKeyInfos={props.getData}
                                                            getData={props.getRadioData}
                                                            data={props.data}
                                                            removeIcr={props.removeIcr}
                                                            ref={childRef}
                                                            setDisconnectIcr={props.setDisconnectIcr}
                                                            scope={props.scope}
                                                            routingName={props.routingName}
                                                            routingList={props.routingList}
                                                            chatCenterData={props.chatCenterData}
                                                        />
                                                    </Flex>

                                                </Flex>
                                            </>
                                        );
                                    }
                                } else return <></>;
                            })()
                        }
                    </div>
                    :
                    null
            }
            <hr></hr>
        </div>
    );
};
export default Routing;