import React, { useState } from "react";
import { Dropdown, Button, } from "@fluentui/react-northstar";
import { authContext } from "../../auth";
import { useIntl } from 'react-intl';
import { searchContactFullinfos, getPersonPhoto } from "../Graph";
const SearchUser = (props) => {
    const [inputItems, setInputItems] = useState([]);
    const intl = useIntl();
    const handleQuery = async (search) => {
        const contactsSuggest = await getUser(search);
        let formatedItems = [];
        for (let contact of contactsSuggest) {
            let photo = await getUserPhoto(contact.id);
            formatedItems.push({
                header: contact.displayName,
                image: photo === null ? "" : window.URL.createObjectURL(photo),
                id: contact.id,
                email: contact.userPrincipalName,
                content: (
                    <div>
                        <Button
                            primary
                            size="small"
                            content={intl.formatMessage({ id: "add" })}
                            onClick={() => {
                                return {
                                    header: contact.displayName,
                                    key: contact.id,
                                    email: contact.userPrincipalName,
                                }
                            }}
                        />
                    </div>
                ),
            });
        }
        setInputItems(formatedItems);
    };
    const getUser = async (query) => {
        return await searchContactFullinfos(authContext, query, true);
    };
    const getUserPhoto = async (id) => {
        return await getPersonPhoto(authContext, id);
    };
    return (
        <>
            {
                props.isUpdate === true ?
                    <Dropdown
                        disabled={true}
                        value={props.displayName}
                        placeholder={intl.formatMessage({ id: 'agent-name' })}
                    />
                    :
                    <Dropdown
                        items={inputItems}
                        defaultSearchQuery={props.displayName ? props.displayName : ""}
                        placeholder={intl.formatMessage({ id: 'agent-name' })}
                        checkable
                        search
                        onSearchQueryChange={(event, data) => {
                            if (data.searchQuery.length > 2) {
                                handleQuery(data.searchQuery);
                            }
                        }}
                        onChange={(e, data) => {
                            props.addContact(data.value)
                        }}
                    />
            }
        </>

    );
};
export default SearchUser;
