import React from "react";
import ChatFlowListComponent from "../../components/ChatFlowListComponents/chatFlowList";
import { connect } from "react-redux";

const ChatFlowListContainer = (props) => {
  return <ChatFlowListComponent scopeList={props.scopeList}/>;
};
const mapStateToProps = (state) => ({
  scopeList: state.scopeList,
})
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatFlowListContainer);