import React, { Component } from "react";
import Welcome from "../containers/Welcome";
import Consent from "./Consent";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { initializeIcons } from "@uifabric/icons";
import "../css/App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
initializeIcons();
export default class App extends Component {
  render() {
    return (
      <Router>
        <Route path="/" render={(props) => <Welcome {...props} />} />
        <Route
          exact
          path="/Consentpresence"
          render={(props) => <Consent {...props} />}
        />
        <Route
          exact
          path="/Consent"
          render={(props) => <Consent {...props} />}
        />
        <Route
          exact
          path="/Consentcc"
          render={(props) => <Consent {...props} />}
        />
      </Router>
    );
  }
}
