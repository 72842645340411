import React, { useState, useEffect } from "react";
import { Header, Dropdown, List, Avatar, Input, Button, RadioGroup, Checkbox, AddIcon,TrashCanIcon } from "@fluentui/react-northstar";
import { BsCaretDown } from 'react-icons/bs';
import { BiUpArrow } from 'react-icons/bi';
import { confirmAlert } from 'react-confirm-alert';
import { useIntl } from 'react-intl';
const ServiceIntegrationParametre = (props) => {
    const [param, setParam] = useState(props.data);
  const [showForm, setShowForm] = useState(true);
  const intl = useIntl();
    const valueItems =[
      "callerId", "callerType", "answerredAt", "flowId", "agentId", "agentName"
    ]
    const typeItems =[
        "constant", "variable"
    ]
  const confirmeRemoveParametre = async (index,paramName) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + paramName + '!',
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => props.deleteServiceParametre(index)
        },
        {
          label: intl.formatMessage({ id: "no" }),
        }
      ]
    });
  }
    useEffect(()=>{
        props.setServiceParameters(props.index,param)
    },[param])
    return (
        <div className="form-container" >
        <div style={{display:'flex'}}>
         {
        showForm?
          <Button
                primary
                size="smallest"
                icon={<BiUpArrow />}
                text
                title={intl.formatMessage({ id: "show-details" })}
                onClick={() => { setShowForm(false) }}
              />
              :
              <Button
                primary
                size="smallest"
                icon={<BsCaretDown />}
                text
                title={intl.formatMessage({ id: "show-details" })}
                onClick={() => { setShowForm(true) }}
              />
        }
          <label>{param.paramName !== "" ? param.paramName : intl.formatMessage({ id: "new-parametre" })}</label>
        <Button
                size="smallest"
                primary
                icon={<TrashCanIcon />}
                text
            title={intl.formatMessage({ id: "delete-parametre" })} 
                onClick={() => confirmeRemoveParametre(props.index, param.paramName )}
        />
        </div>

        {
          showForm ?
                    <div style={{marginLeft:'50px'}}>
              <Header as="h4" content={intl.formatMessage({ id: "label" })} className="label-integration" />
                    <Input
                    value = {param.label}
                    onChange={(e, data) => {
                        setParam({...param,  label: data.value })
                    }}
                    className="from-item"
                    />
              <Header as="h4" content={intl.formatMessage({ id: "type" })} className="label-integration" />
                    <Dropdown
                        className="from-item"
                        items={typeItems}
                        value = {param.type}
                placeholder={intl.formatMessage({ id: "select-type" })}
                        checkable
                        getA11ySelectionMessage={{
                            onAdd: item => `${item} has been selected.`,
                        }}
                        onChange={(e, data) => {
                        setParam({...param,  type: data.searchQuery })
                        }}
                    />
              <Header as="h4" content={intl.formatMessage({ id: "parametre-name" })} className="label-integration" />
                    <Input
                    onChange={(e, data) => {
                        setParam({...param,  paramName: data.value })
                    }}
                    value = {param.paramName}
                    className="from-item"
                    />
              <Header as="h4" content={intl.formatMessage({ id: "value" })} className="label-integration" />
                    <Dropdown
                        onChange={(e, data) => {
                        setParam({...param,  value: data.searchQuery })
                        }}
                        className="from-item"
                        items={valueItems}
                        value = {param.value}
                placeholder={intl.formatMessage({ id: "select-value" })}
                        checkable
                        getA11ySelectionMessage={{
                            onAdd: item => `${item} has been selected.`,
                        }}
                    />
                    </div>

            :
            null

        }
        <hr />
        </div>
    )
}
export default ServiceIntegrationParametre;