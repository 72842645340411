import React from "react";
import { connect } from "react-redux";
import { Header } from "@fluentui/react-northstar";
import { useIntl } from "react-intl";
import GroupsComponent from "../../components/Groups/GroupsComponent";
const GroupsContainer = (props) => {
  const intl = useIntl();
  return (
    <div className="form-container">
      <Header
        as="h2"
        content={intl.formatMessage({ id: "groups-management" })}
      />
      <GroupsComponent scopeList={props.scopeList} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  scopeList: state.scopeList,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer);
