/* eslint-disable no-useless-computed-key */
/* eslint-disable default-case */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TimeZone from "../../components/CallCenterComponents/TimeZone";
import FunctionAdmins from "../../components/CallCenterComponents/FunctionAdmins";
import Language from "../../components/CallCenterComponents/Language";
import { Tabs, Tab } from "react-bootstrap";
import { AddIcon, Input, Header, Divider, Button, Flex, Loader, List, Avatar, TrashCanIcon, Dropdown, Form } from "@fluentui/react-northstar";
import AnnoucementComponent from "../../components/ChatCenterComponents/AnnoucementComponent";
import Routing from "../../components/ChatCenterComponents/Routing";
import { getToken } from "../../components/Graph"
import { useParams } from "react-router-dom";
import Axios from "axios";
import data from "./autoAttendant.json";
import { authContext } from "../../auth";
import update from "immutability-helper";
import "../../css/CallFlow.css";
import { selectChatFlowManagementUrl, addChatFlowManagementUrl, updateChatFlowManagementUrl, getAllCallFlowManagementUrl } from "../../urls";
import { confirmAlert } from 'react-confirm-alert';
import { useIntl } from 'react-intl';
const ChatCenterContainer = (props) => {
  const params = useParams();
  const [chatCenterData, setChatCenterData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [audioList, setAudioList] = useState([]);
  const [functionalAdminsData, setFunctionalAdminsData] = useState([]);
  const [functionalAdminsDetails, setFunctionalAdminsDetails] = useState([]);
  const [displayNewChatflow, setDisplayNewChatflow] = useState("");
  const [isInitiated, setIsInitiated] = useState(false);
  const [callFlowList, setCallFlowList] = useState([]);
  const [fields, setFelds] = useState([]);
  const [isNewRouting, setIsNewRouting] = useState(false);
  const [routingErrorMessage, setRoutingErrorMessage] = useState("");
  const [newRouting, setNewRouting] = useState("");
  const intl = useIntl();
  let items = [];
  const getChatFlowData = async () => {
    if (params.id === "newChatFlow") {
      setChatCenterData(data);
      return;
    }
    let Token = await getToken(authContext);
    const url = selectChatFlowManagementUrl(
      authContext._user.profile.tid,
      Token,
      params.id,
      params.scope
    );
    await Axios.get(url).then((res) => {

      setChatCenterData(res.data);
      setIsInitiated(true)
      if (res.data.functionalAdminsDetails) {
        setFunctionalAdminsDetails(res.data.functionalAdminsDetails)
      }
      if (res.data.functionalAdmins) {
        setFunctionalAdminsData(res.data.functionalAdmins);
      }
    });
  };
  useEffect(() => {
    getChatFlowData();
    if (params.id === "newChatFlow" || chatCenterData.displayName === "") {
      setDisplayNewChatflow("hide");
    } else {
      setDisplayNewChatflow("show");
    }
  }, []);
  useEffect(() => {
    if (props.scopeList) {
      let scopeListName = Object.keys(props.scopeList)
      let defautScope = ""
      if ((chatCenterData.scope === "" || !chatCenterData.scope) && scopeListName.includes("default")) {
        defautScope = "default"
        setChatCenterData((state) => {
          return { ...state, scope: "default" };
        });
      } else {
        defautScope = scopeListName[0]
        setChatCenterData((state) => {
          return { ...state, scope: scopeListName[0] };
        });
      }
      setFelds([
        {
          label: intl.formatMessage({ id: "bot-id" }),
          name: 'botId',
          id: 'bot-id-shorthand',
          key: 'bot-id',
          required: true,
          type: 'text',
          control: {
            as: Input,
            showSuccessIndicator: false,
            onChange: (e, { name, value }) => {
              setChatCenterData((state) => {
                return { ...state, id: value };
              });

            },
          },
        },
        {
          label: intl.formatMessage({ id: "name" }),
          name: 'name',
          id: 'name-shorthand',
          key: 'display-name',
          required: true,
          type: 'text',
          control: {
            as: Input,
            showSuccessIndicator: false,
            onChange: (e, { name, value }) => {
              setChatCenterData((state) => {
                return { ...state, displayName: value };
              });

            },
          },
        },
        {
          label: intl.formatMessage({ id: "bot-key" }),
          name: 'password',
          id: 'password-shorthand',
          key: 'pass-word',
          required: true,
          type: 'password',
          control: {
            as: Input,
            showSuccessIndicator: false,
            onChange: (e, { name, value }) => {
              setChatCenterData((state) => {
                return { ...state, password: value };
              });

            },
          },
        },
        {
          label: {
            content: intl.formatMessage({ id: "scope" }),
            id: "scope",
          },
          name: 'scope',
          key: 'scope',
          required: true,
          id: 'scope',
          control: {
            as: Dropdown,
            items: Object.keys(props.scopeList),
            defaultValue: defautScope,
            placeholder: 'scope',
            onChange: (e, { name, value }) => {
              setChatCenterData((state) => {
                return { ...state, scope: value };
              });
            }
          },
        },
        {
          control: {
            primary: true,
            as: Button,
            content: intl.formatMessage({ id: "next" }),
          },
          key: 'submit',
        },
      ])
    }
  }, [props.scopeList])
  useEffect(() => {
    setChatCenterData((state) => {
      return { ...state, attachedFlowId: {} };
    });
    if (chatCenterData.scope && chatCenterData.scope !== "") {
      getCallflows(chatCenterData.scope)
    }
  }, [chatCenterData.scope])
  useEffect(() => {
    if (callFlowList.length > 0 && Object.keys(chatCenterData.attachedFlowId).length === 0) {
      setChatCenterData((state) => {
        return { ...state, attachedFlowId: { id: callFlowList[0].key, displayName: callFlowList[0].header } };
      });
    }
  }, [callFlowList])
  const getCallflows = async (scope) => {
    setCallFlowList([])
    const url = getAllCallFlowManagementUrl(authContext._user.profile.tid, authContext._user.profile.oid, scope);
    await Axios.get(url).then((res) => {
      Object.keys(res.data).map((item, i) => {
        setCallFlowList(callFlowList => [...callFlowList, { header: res.data[item].displayName, key: res.data[item].id }]);
      })
    })
  }
  const setAttachedFlowId = (callflow) => {
    setChatCenterData((state) => {
      return { ...state, attachedFlowId: callflow };
    });
  }
  const sendData = async () => {
    setIsLoading(true);
    setIsDisabled(true);
    let url = "";
    let Token = await getToken(authContext);
    if (params.id === "newChatFlow") {
      url = addChatFlowManagementUrl(
        authContext._user.profile.tid,
        Token,
        chatCenterData.id,
        chatCenterData.scope
      );
    } else {
      url = updateChatFlowManagementUrl(
        authContext._user.profile.tid,
        Token,
        params.id,
        chatCenterData.scope
      );
    }
    Axios.post(url, chatCenterData).then((res) => {
      setIsLoading(false);
      setIsDisabled(false);
      props.history.push("/chat-flow");
    });

  };
  const getTimeZoneData = (elm, val) => {
    setChatCenterData((state) => {
      return { ...state, timeZone: val.value || "" };
    });
  };
  const getLanguageData = (elm, val) => {
    setChatCenterData((state) => {
      return { ...state, language: val.value || "" };
    });
  };
  const getRoutingRadio = (val, itemToUpdate, info, routingName) => {

    switch (itemToUpdate) {
      case "routing":
        let trasferIvrToRouting = {
          "type": "routing",
          "routingName": val === 'Start' ? "0" : val
        }
        setChatCenterData((state) => {
          return update(state, { routing: { [routingName]: { options: { [info]: { $set: trasferIvrToRouting } } } } });
        });
        break;
      case "radio":
        let itemRoutingObject = {
          type: val,
          enabled: true,
        }
        setChatCenterData((state) => {
          return update(state, { routing: { [routingName]: { $set: itemRoutingObject } } });
        });
        break;
      case "queue":
        setChatCenterData((state) => {
          let routingDetails = {
            enabled: true,
            type: "queue",
            queueDisplayName: val.header,
            queueId: val.key
          }
          return update(state, {
            routing: {
              [routingName]: { $set: routingDetails },
            },
          });
        })
        break;
      case "icrQueue":
        let icrQueueobject = {
          "type": "queue",
          "queueId": val.key,
          "queueDisplayName": val.header
        }
        setChatCenterData((state) => {
          return update(state, { routing: { [routingName]: { customerChoiceActions: { [info]: { next: { $set: icrQueueobject } } } } } });
        });
        break;
      case "routingIcr":
        let icrRoutingobject = {
          "type": "routing",
          "routingName": val === "Start" ? "0" : val,
        }
        setChatCenterData((state) => {
          return update(state, { routing: { [routingName]: { customerChoiceActions: { [info]: { next: { $set: icrRoutingobject } } } } } });
        });
        break;
        break;
      case "customerService":
        setChatCenterData((state) => {
          return {
            ...state,
            displayName: val?.header || "",
          };
        });
        setChatCenterData((state) => {
          if (state.displayName !== "") {
            setDisplayNewChatflow("show");
          } else {
            setDisplayNewChatflow("hide");
          }
          return {
            ...state,
            id: val?.key || "",
          };
        });
        setChatCenterData((state) => {
          return { ...state, userPrincipalName: val?.userPrincipalName };
        });
        break;
      case "promptQueue":
        setChatCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "queue",
                    queueDisplayName: val.header,
                    queueId: val.key,
                  }
                }
              },
            },
          });
        })
        break;
      case "transfer to user":
        setChatCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "transfer",
                    userDisplayName: val.header,
                    targetId: val.key,
                    targetType: "user",
                  }
                }
              },
            },
          });
        })
        break;
      case "promptDisconnect":
        setChatCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "disconnect",
                  }
                }
              },
            },
          });
        })
        break;
      case "prompt transfer to phone":
        setChatCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "transfer to phone",
                    targetId: val,
                  }
                }
              },
            },
          });
        })
        break;
      case "promptRouting":
        setChatCenterData((state) => {
          return update(state, {
            routing: {
              [routingName]: {
                next: {
                  $set: {
                    type: "routing",
                    routingName: val === "Start" ? "0" : val
                  }
                }
              },
            },
          });
        })

        break;
      default:
    }
  };
  const getAnnoucementData = (elm, val, itemToUpdate, annoucementName) => {
    switch (itemToUpdate) {
      case "enable":
        setChatCenterData((state) => {
          return update(state, {
            greeting: { [annoucementName]: { enabled: { $set: val } } },
          });
        });
        break;
      case "radio":
        setChatCenterData((state) => {
          return update(state, {
            greeting: { [annoucementName]: { type: { $set: val } } },
          });
        });
        break;
      case "textToSpeechLanguage":
        setChatCenterData((state) => {
          return update(state, {
            greeting: { [annoucementName]: { language: { $set: val } } },
          });
        });
        break;
      case "text":
        setChatCenterData((state) => {
          return update(state, {
            greeting: { [annoucementName]: { text: { $set: val } } },
          });
        });
        break;

      case "audio":
        setChatCenterData((state) => {
          return update(state, {
            greeting: {
              [annoucementName]: {
                audioFileUrl: {
                  $set: val[0]?.url || decodeURIComponent(val.url) || null,
                },
              },
            },
          });
        });
        break;
      default:
    }
  };
  const getFunctionAdminsData = (val) => {
    if (!functionalAdminsData.includes(val.id)) {
      setFunctionalAdminsData((functionalAdminsData) => [
        ...functionalAdminsData,
        val.id,
      ]);

      let newfunctionalAdminsDetails = { [val.id]: val.displayName };
      setFunctionalAdminsDetails((functionalAdminsDetails) => [
        ...functionalAdminsDetails,
        newfunctionalAdminsDetails,
      ]);
      setChatCenterData((state) => {
        return {
          ...state,
          functionalAdmins: [...functionalAdminsData, val.id],
        };
      });
      setChatCenterData((state) => {
        return {
          ...state,
          functionalAdminsDetails: [
            ...functionalAdminsDetails,
            newfunctionalAdminsDetails,
          ],
        };
      });
    }
  };
  const getItemsadmins = (admins) => {
    if (admins) {
      Object.values(admins).forEach((admin) => {
        let adminName = Object.values(admin)[0];
        let adminkey = Object.keys(admin)[0];
        items.push({
          key: adminkey,
          media: <Avatar name={adminName} />,
          header: adminName,
          headerMedia: (
            <Button
              icon={<TrashCanIcon />}
              onClick={(e, data) => confirmeRemoveAdminUser(adminkey, adminName)}
              text
            />
          ),
        });
      });
      return items;
    } else return [];
  };
  const confirmeRemoveAdminUser = async (id, name) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + name + '!',
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => removeAdminUser(id)
        },
        {
          label: intl.formatMessage({ id: "no" }),
        }
      ]
    });
  }
  const removeAdminUser = (key) => {
    let newFunctionalAdminsData = chatCenterData.functionalAdmins.filter(
      (data) => {
        return data !== key;
      }
    );
    setFunctionalAdminsData(newFunctionalAdminsData);

    let newfunctionalAdminsDetails = chatCenterData.functionalAdminsDetails.filter(
      (data) => {
        return Object.keys(data)[0] !== key;
      }
    );
    setFunctionalAdminsDetails(newfunctionalAdminsDetails);

    setChatCenterData((state) => {
      const newState = state.functionalAdminsDetails.filter((data) => {
        return Object.keys(data)[0] !== key;
      });
      return { ...state, functionalAdminsDetails: newState };
    });

    setChatCenterData((state) => {
      const newState = state.functionalAdmins.filter((data) => {
        return data !== key;
      });
      return { ...state, functionalAdmins: newState };
    });
  };
  const setIcrName = (name, routingName) => {

    if (chatCenterData.routing[routingName].customerChoiceName) {
      setChatCenterData((state) => {
        return update(state, { routing: { [routingName]: { customerChoiceName: { $set: name } } } });
      });
    } else {
      let newRouting = chatCenterData.routing[routingName];
      newRouting.customerChoiceName = name
      setChatCenterData((state) => {
        return update(state, { routing: { [routingName]: { $set: newRouting } } });
      });
    }

  }
  const createNewIcr = (key, routingName) => {
    if (chatCenterData.routing[routingName].customerChoiceActions) {
      setChatCenterData((state) => {
        return update(state, { routing: { [routingName]: { customerChoiceActions: { [key]: { $set: {} } } } } });
      });
    } else {
      let newRouting = chatCenterData.routing[routingName];
      newRouting.customerChoiceActions = { [key]: {} }
      setChatCenterData((state) => {
        return update(state, { routing: { [routingName]: { $set: newRouting } } });
      });
    }
  }
  const setIcrText = (text, routingName) => {
    if (chatCenterData.routing[routingName].customerChoiceText) {
      setChatCenterData((state) => {
        return update(state, { routing: { [routingName]: { customerChoiceText: { $set: text } } } });
      });
    } else {
      let newRouting = chatCenterData.routing[routingName];
      newRouting.customerChoiceText = text
      setChatCenterData((state) => {
        return update(state, { routing: { [routingName]: { $set: newRouting } } });
      });
    }
  }
  const setPostBack = (text, ref, routingName) => {
    setChatCenterData((state) => {
      return update(state, { routing: { [routingName]: { customerChoiceActions: { [ref]: { $set: { text: text } } } } } });
    });
    setChatCenterData((state) => {
      return update(state, { routing: { [routingName]: { customerChoiceActions: { [ref]: { displayText: { $set: text } } } } } });
    });
    setChatCenterData((state) => {
      return update(state, { routing: { [routingName]: { customerChoiceActions: { [ref]: { title: { $set: text } } } } } });
    });
    setChatCenterData((state) => {
      return update(state, { routing: { [routingName]: { customerChoiceActions: { [ref]: { text: { $set: text } } } } } });
    });
    setChatCenterData((state) => {
      return update(state, { routing: { [routingName]: { customerChoiceActions: { [ref]: { value: { $set: ref } } } } } });
    });
    setChatCenterData((state) => {
      return update(state, { routing: { [routingName]: { customerChoiceActions: { [ref]: { type: { $set: "postBack" } } } } } });
    });
  }
  const getSelectedIcrData = (data, routingName) => {
    const getAudioInfo = {
      "type": "Audiofile",
      "language": "",
      "text": "",
      "audioFileUrl": data.url
    }
    setChatCenterData((state) => {
      return update(state, {
        routing: {
          [routingName]: {
            audio: {
              audioFileUrl: { $set: getAudioInfo.audioFileUrl },
              language: { $set: getAudioInfo.language },
              type: { $set: getAudioInfo.type },
              text: { $set: getAudioInfo.text }
            }

          },
        },
      });
    })
  }
  const removeIcr = (x) => {
    setChatCenterData((state) => {
      const icrs = state.routing[0].customerChoiceActions;
      delete icrs[x];
      return update(state, { routing: { ['0']: { customerChoiceActions: { $set: icrs } } } })
    });
  }
  const setDisconnectIcr = (key) => {
    let ivrDisconnect = {
      "type": "disconnect"
    }
    setChatCenterData((state) => {
      return update(state, { routing: { ['0']: { customerChoiceActions: { [key]: { next: { $set: ivrDisconnect } } } } } });
    });
  }
  const addNewRouting = (nameOfNewRouting) => {
    let routingsName = Object.keys(chatCenterData.routing);

    if (!nameOfNewRouting.includes(" ")) {
      setRoutingErrorMessage("");
      if (
        !routingsName.includes(nameOfNewRouting) &&
        nameOfNewRouting !== "Start" &&
        nameOfNewRouting !== "0"
      ) {
        setChatCenterData((state) => {
          return update(state, {
            routing: {
              [nameOfNewRouting]: {
                $set: {
                  enabled: true,
                  type: ""
                },
              },
            },
          });
        });
        setIsNewRouting(false);
      }
    } else {
      setRoutingErrorMessage(
        intl.formatMessage({
          id: "The routing name should not contain a space ' ' ",
        })
      );
    }
  };
  const deleteRouting = (nameOfNewRouting) => {
    if (nameOfNewRouting !== "Default") {
      setChatCenterData((state) => {
        const routings = state.routing;
        const asArray = Object.entries(routings);
        const atLeast9Wins = asArray.filter(
          ([key, value]) => key !== nameOfNewRouting
        );
        const atLeast9WinsObject = Object.fromEntries(atLeast9Wins);
        return update(state, { routing: { $set: atLeast9WinsObject } });
      });
    }
  };
  const setFormData = (item, routingName) => {

    if (chatCenterData.routing[routingName].items) {
      setChatCenterData((state) => {
        return update(state, { routing: { [routingName]: { items: { $push: [item] } } } });
      });
    } else {
      let newRouting = chatCenterData.routing[routingName];
      newRouting.items = [item]
      setChatCenterData((state) => {
        return update(state, { routing: { [routingName]: { $set: newRouting } } });
      });
    }
  }
  const deleteItemFromRoutingForm = (index, routingsName) => {
    setChatCenterData((state) => {
      return update(state, { routing: { [routingsName]: { items: { $splice: [[index, 1]] } } } })
    });

  }
  const updateRoutingFormData = (index, item, routingName) => {
    setChatCenterData((state) => {
      return update(state, { routing: { [routingName]: { items: { [index]: { $set: item } } } } });
    });
  }
  const setNextRoutingForm = (nextRouting, routingName) => {
    if (chatCenterData.routing[routingName].next) {
      setChatCenterData((state) => {
        return update(state, {
          routing: {
            [routingName]: {
              next: {
                $set: {
                  type: chatCenterData.routing[nextRouting].type,
                  routingName: nextRouting
                }
              }
            }
          }
        });
      });
    } else {
      let newRouting = chatCenterData.routing[routingName];
      newRouting.next = {
        type: chatCenterData.routing[nextRouting].type,
        routingName: nextRouting
      }
      setChatCenterData((state) => {
        return update(state, { routing: { [routingName]: { $set: newRouting } } });
      });
    }
  }
  //s('chatCenterData===>', chatCenterData)
  return (
    <div className="form-container">
      {chatCenterData.hasOwnProperty("id") ? (
        <Flex column className="call-flow-div">
          <div className={displayNewChatflow === "hide" ? "show" : "hide"}>
            <Header as="h4" content={intl.formatMessage({ id: "add-new-chat-flow" })} />
            <Flex style={{ marginLeft: "50px" }}>
              <Form
                className='chat-flow-form'
                onSubmit={() => {
                  setDisplayNewChatflow("show")
                  setIsInitiated(true)
                }}
                fields={fields}
              />
            </Flex>
          </div>
          <div className={displayNewChatflow === "hide" ? "hide" : "show"} >
            <Tabs defaultActiveKey="service account" id="uncontrolled-tab-example">
              <Tab eventKey="service account" title={intl.formatMessage({ id: "resource-account" })}>
                <div className="submit-div">
                  <div className="error-div" role="alert" >
                  </div>
                  <div className="submit-button">
                    <Button
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                {
                  isInitiated && chatCenterData.id !== undefined && chatCenterData.displayName !== undefined && chatCenterData.password !== undefined ?
                    <div style={{ display: 'flex' }}>
                      <div>
                        <Header as="h4" content={intl.formatMessage({ id: "chat-flow-id" })} />
                        <Input
                          disabled
                          defaultValue={chatCenterData.id}
                          className="child-title-call-flow"
                          style={{ width: '355px' }}
                          onChange={(e, { name, value }) => {
                            setChatCenterData((state) => {
                              return { ...state, id: value };
                            });
                          }}
                        />
                        <Header as="h4" content={intl.formatMessage({ id: "chat-flow-name" })} />
                        <Input
                          defaultValue={chatCenterData.displayName}
                          className="child-title-call-flow"
                          style={{ width: '355px' }}
                          onChange={(e, { name, value }) => {
                            setChatCenterData((state) => {
                              return { ...state, displayName: value };
                            });
                          }}
                        />
                        <Header as="h4" content={intl.formatMessage({ id: "bot-key" })} />
                        <Input
                          type='password'
                          defaultValue={chatCenterData.password}
                          className="child-title-call-flow"
                          style={{ width: '355px' }}
                          onChange={(e, { name, value }) => {
                            setChatCenterData((state) => {
                              return { ...state, password: value };
                            });

                          }}
                        />
                      </div>
                      <div style={{ marginLeft: '50px' }}>
                        <Header as="h4" content={intl.formatMessage({ id: "scope" })} />
                        <Input
                          defaultValue={chatCenterData.scope}
                          className="child-title-call-flow"
                          style={{ width: '355px' }}
                          disabled
                        />
                        <Header as="h4" content={intl.formatMessage({ id: "link-to-callflow" })} />
                        <Dropdown
                          className="child-title-call-flow"
                          items={callFlowList}
                          value={chatCenterData?.attachedFlowId.id ? { key: chatCenterData.attachedFlowId?.id, header: chatCenterData.attachedFlowId?.displayName } : null}
                          onChange={(e, data) => {
                            let callflow = { id: data.value.key, displayName: data.value.header }
                            setAttachedFlowId(callflow)
                          }}
                        />
                      </div>
                    </div>
                    :
                    null
                }
                <Header as="h4" content={intl.formatMessage({ id: "time-zone" })} />
                <TimeZone
                  getData={getTimeZoneData}
                  timeZone={chatCenterData?.timeZone}
                />
                <Header as="h4" content={intl.formatMessage({ id: "language" })} />
                <Language
                  className="child-title-call-flow"
                  language={chatCenterData.language}
                  getData={getLanguageData}
                />
              </Tab>
              <Tab eventKey="greeting" title={intl.formatMessage({ id: "greeting" })}>
                <div className="submit-div">
                  <div className="error-div" role="alert" >
                  </div>
                  <div className="submit-button">
                    <Button
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <AnnoucementComponent
                  headerTitle={intl.formatMessage({ id: "emergency-message" })}
                  annoucementName="emergencyMessage"
                  getData={getAnnoucementData}
                  defaultValue={chatCenterData.greeting.emergencyMessage}
                />
                <Divider />
                <AnnoucementComponent
                  headerTitle={intl.formatMessage({ id: "welcome-message" })}
                  getData={getAnnoucementData}
                  annoucementName="welcomeMessage"
                  defaultValue={chatCenterData.greeting.welcomeMessage}
                />
                <Divider />
                <AnnoucementComponent
                  headerTitle={intl.formatMessage({ id: "flash-message" })}
                  getData={getAnnoucementData}
                  annoucementName="flashMessage"
                  defaultValue={chatCenterData.greeting.flashMessage}
                />
                <Divider />
                <AnnoucementComponent
                  headerTitle={intl.formatMessage({ id: "custom-message" })}
                  getData={getAnnoucementData}
                  annoucementName="customMessage"
                  defaultValue={chatCenterData.greeting.customMessage}
                />
                <Divider />
              </Tab>
              <Tab eventKey="Routing" title={intl.formatMessage({ id: "routing" })}>
                <div className="submit-div">
                  <div className="error-div" role="alert" >
                  </div>
                  <div className="submit-button">
                    <Button
                      className="submit-button"
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="action-call-flow">
                  {Object.entries(chatCenterData.routing).map(
                    ([key, value]) => {
                      return (
                        <Routing
                          key={key}
                          routingName={key}
                          headerTitle={intl.formatMessage({ id: "chat-flow-type" })}
                          createNewIcr={createNewIcr}
                          setPostBack={setPostBack}
                          setIcrName={setIcrName}
                          setIcrText={setIcrText}
                          getSelectedIcrData={getSelectedIcrData}
                          getRadioData={getRoutingRadio}
                          data={chatCenterData.routing[key]}
                          audiosList={audioList}
                          callFlowId={chatCenterData.id}
                          removeIcr={removeIcr}
                          setDisconnectIcr={setDisconnectIcr}
                          scope={chatCenterData.scope}
                          deleteRouting={deleteRouting}
                          routingList={Object.keys(chatCenterData.routing)}
                          setFormData={setFormData}
                          deleteItemFromRoutingForm={deleteItemFromRoutingForm}
                          updateRoutingFormData={updateRoutingFormData}
                          setNextRoutingForm={setNextRoutingForm}
                          chatCenterData={chatCenterData}
                        />
                      );
                    }
                  )}

                  <Button
                    primary
                    icon={<AddIcon />}
                    text
                    content={intl.formatMessage({ id: "add-new-routing" })}
                    onClick={() => setIsNewRouting(true)}
                  />
                  {isNewRouting === true ? (
                    <div>
                      <div style={{ marginBottom: "10px", color: "red" }}>
                        {routingErrorMessage}
                      </div>
                      <Input
                        onChange={(event) => setNewRouting(event.target.value)}
                        style={{ width: "350px" }}
                      ></Input>
                      <Button
                        primary
                        icon={<AddIcon />}
                        text
                        title="Add new routing"
                        onClick={() => addNewRouting(newRouting)}
                      />
                    </div>
                  ) : null}

                </div>
              </Tab>
              <Tab eventKey="Functional Admins" title={intl.formatMessage({ id: "functional-admins" })}>
                <div className="submit-div">
                  <div className="error-div" role="alert" >
                  </div>
                  <div className="submit-button">
                    <Button
                      className="submit-button"
                      content={intl.formatMessage({ id: "save" })}
                      onClick={sendData}
                      primary
                      loading={isLoading}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="action-call-flow">
                  <Header as="h4" content={intl.formatMessage({ id: "functional-admins" })} />
                  <FunctionAdmins
                    getData={getFunctionAdminsData}
                    FunctionAdmins={chatCenterData.functionAdmins}
                  />
                  <List
                    className="functional-admin-list"
                    items={getItemsadmins(functionalAdminsDetails)}
                  />
                </div>
              </Tab>
              <Tab disabled title={chatCenterData.displayName} />
            </Tabs>
          </div>
        </Flex>
      ) : (
        <Loader />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  scopeList: state.scopeList,
})
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatCenterContainer);