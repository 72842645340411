/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import QueueComponent from "../../components/Queues/QueueComponent";
import { setSlaThresholdInStore, setSlaInStore } from "../../redux/actions";
import data from "./defaultChatQueue.json";
import {
  Header,
  Dropdown,
  List,
  Avatar,
  Input,
  Button,
  RadioGroup,
  Checkbox,
  AddIcon,
  TextArea,
  TrashCanIcon,
  Loader,
} from "@fluentui/react-northstar";
import { useParams, useHistory } from "react-router-dom";
import { authContext } from "../../auth";
import Axios from "axios";
import {
  updateChatQueueManagementUrl,
  addChatQueueManagementUrl,
  selectChatQueueManagementUrl,
  removeChatQueueManagementUrl,
} from "../../urls";
import update from "immutability-helper";
import push from "immutability-helper";
import { getToken } from "../../components/Graph";
import ChatQueueUserSearch from "./ChatQueueUserSearch";
import { confirmAlert } from "react-confirm-alert";
import { useIntl } from "react-intl";
const ChatQueueContainer = (props) => {
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [chatQueueData, setChatQueueData] = useState({});
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const intl = useIntl();
  const getChatQueueData = async () => {
    if (params.id === "newChatQueue") {
      let now = Date.now();
      let chatQueueId = "chat-queue" + now;
      data["id"] = chatQueueId;
      setChatQueueData(data);
      setLoading(false);
      return;
    }
    let Token = await getToken(authContext);
    const url = selectChatQueueManagementUrl(
      authContext._user.profile.tid,
      Token,
      params.id
    );
    Axios.get(url)
      .then((res) => {
        setChatQueueData(res.data);
      })
      .then(() => setLoading(false));
  };
  const setQueueName = (name) => {
    setChatQueueData((state) => {
      return update(state, { queueName: { $set: name } });
    });
  };
  const setQueueType = (type) => {
    setChatQueueData((state) => {
      return update(state, { queueType: { $set: type } });
    });
  };
  const setQueueLanguage = (language) => {
    setChatQueueData((state) => {
      return update(state, { language: { $set: language } });
    });
  };
  const setTextOnHold = (text) => {
    setChatQueueData((state) => {
      return update(state, { textOnHold: { $set: text } });
    });
  };
  const setMaxWaitTime = (value) => {
    setChatQueueData((state) => {
      return update(state, { maxWaitTime: { $set: value } });
    });
  };
  const setCallAnswering = (user) => {
    let userId = user.id;
    const setIdOnAgentsDetails = update(chatQueueData, {
      agentDetails: { [userId]: {} },
    });
    setChatQueueData((setIdOnAgentsDetails) => {
      return update(chatQueueData, {
        agentDetails: {
          [userId]: {
            $set: { displayName: user.header, skill: 100, state: "READY" },
          },
        },
        agentsIds: { $push: [userId] },
      });
    });
  };
  const getQueueTypeContent = (type) => {
    let content = "";
    for (const [key, value] of Object.entries(inputItemsType)) {
      if (value["key"] === type) {
        content = value["content"];
      }
    }

    return content;
  };
  const History = useHistory();
  const inputItemsLanguage = [
    `es-ES`,
    "en-US",
    "fr-FR",
    "fr-CA",
    "en-EN",
    "it-IT",
    "de-DE",
  ];
  const inputItemsType = [
    {
      key: "CALL_QUEUE",
      content: "Call queue",
    },
    {
      key: "CHAT_QUEUE",
      content: "Chat queue",
    },
  ];
  const sendData = async (props) => {
    setIsSaveLoading(true);
    const tenantId = authContext._user.profile.tid;
    let Token = await getToken(authContext);
    var url = "";
    if (params.id !== "newChatQueue") {
      url = updateChatQueueManagementUrl(tenantId, Token, chatQueueData.id);
    } else {
      url = addChatQueueManagementUrl(tenantId, Token, chatQueueData.id);
    }
    //  const url = AddQueueManagementUrl(tenantId, authContext._user.profile.oid)
    Axios.post(url, chatQueueData).then((res, err) => {
      if (!err) {
        setIsSaveLoading(false);
        History.push(`/chat-queues`);
      } else {
        console.log("errorr!!!!", err);
      }
    });
  };
  const getAgentAnswringList = (agents) => {
    let items = [];
    if (agents) {
      for (let [key, value] of Object.entries(agents)) {
        items.push({
          key,
          media: <Avatar name={value?.displayName} />,
          header: value?.displayName,
          headerMedia: (
            <Button
              icon={<TrashCanIcon />}
              onClick={(e, data) =>
                confirmeRemoveCallAnsewring(key, value?.displayName)
              }
              text
            />
          ),
        });
      }
      return items;
    } else return [];
  };
  const confirmeRemoveCallAnsewring = async (id, name) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message:
        intl.formatMessage({ id: "are-you-sure-to-remove" }) + name + "!",
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => removeCallAnsewring(id),
        },
        {
          label: "no",
        },
      ],
    });
  };
  const removeCallAnsewring = (elm) => {
    let userId = elm;
    let index = chatQueueData.agentsIds.indexOf(userId);
    setChatQueueData((chatQueueData) => {
      return update(chatQueueData, {
        agentDetails: { $unset: [userId] },
        agentsIds: { $splice: [[index, 1]] },
      });
    });
  };
  useEffect(() => {
    getChatQueueData();
  }, []);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="form-container">
          <Button
            content={intl.formatMessage({ id: "save" })}
            loading={isSaveLoading}
            primary
            onClick={() => sendData(props)}
            id="submit-button-queue"
          />
          <Header
            as="h4"
            content={intl.formatMessage({ id: "queue-name" })}
            className="label-form_queue"
          />
          <Input
            className="from-item"
            name={intl.formatMessage({ id: "queue-name" })}
            defaultValue={chatQueueData.queueName}
            onChange={(e, data) => {
              setQueueName(data.value);
            }}
          />
          <Header
            id="language-header"
            as="h4"
            content={intl.formatMessage({ id: "type" })}
          />
          <div className="from-item">
            <Dropdown
              items={inputItemsType}
              checkable
              placeholder={intl.formatMessage({ id: "type" })}
              value={getQueueTypeContent(chatQueueData.queueType)}
              defaultValue={getQueueTypeContent(chatQueueData.queueType)}
              onChange={(e, data) => {
                setQueueType(data.value["key"]);
              }}
            />
          </div>
          <Header
            id="language-header"
            as="h4"
            content={intl.formatMessage({ id: "language" })}
          />
          <div className="from-item">
            <Dropdown
              items={inputItemsLanguage}
              placeholder={intl.formatMessage({ id: "language" })}
              checkable
              defaultValue={chatQueueData.language}
              getA11ySelectionMessage={{
                onAdd: (item) => `${item} has been selected.`,
              }}
              onChange={(e, data) => {
                setQueueLanguage(data.searchQuery);
              }}
            />
          </div>
          <Header
            as="h4"
            content={intl.formatMessage({ id: "text-on-hold" })}
            className="label-form_queue"
          />
          <div className="from-item">
            <TextArea
              placeholder={intl.formatMessage({ id: "type-here..." })}
              defaultValue={chatQueueData.textOnHold}
              onChange={(e, data) => {
                setTextOnHold(data.value);
              }}
            />
          </div>
          <Header
            as="h4"
            content={intl.formatMessage({ id: "Max wait time (seconds)" })}
            className="label-form_queue"
          />
          <Input
            className="from-item"
            clearable
            defaultValue={chatQueueData.maxWaitTime}
            onChange={(e, data) => {
              setMaxWaitTime(data.value);
            }}
          />

          <Header
            as="h4"
            content={intl.formatMessage({ id: "agents-list" })}
            className="label-form_queue"
          />
          <ChatQueueUserSearch setCallAnswering={setCallAnswering} />
          <div id="call-answering-div">
            <List
              className="from-item call-ansewering-list"
              items={getAgentAnswringList(chatQueueData.agentDetails)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return {
    queueInfos: state.autoAttendant[props.match.params.id],
    musicSearchResults:
      state.autoAttendantComp?.musicOnHold?.searchResults || [],
    answeringAgents: state.autoAttendant[props.match.params.id]?.agentDetails,
    huntMethod: state.autoAttendant[props.match.params.id]?.huntMethod,
    maxWaitTim: state.autoAttendant[props.match.params.id]?.maxWaitTim,
    queueData: state.autoAttendant[props.match.params.id],
    slaThreshold: state.autoAttendant[props.match.params.id]?.slaThreshold,
    sla: state.autoAttendant[props.match.params.id]?.sla,
  };
};

export default connect(mapStateToProps)(ChatQueueContainer);
