/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown } from "@fluentui/react-northstar";
import { authContext } from "../../auth";
import Axios from "axios";
import { getAllQueueManagementUrl } from "../../urls";
import { useIntl } from 'react-intl';
const QueueSearch = (props) => {
    authContext.handleWindowCallback();
    const [suggestedItems, setSuggestedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState(props?.queueDisplayName);
    const intl = useIntl();
    useEffect(() => {
        setSearchQuery(props.queueDisplayName);
    }, [props.queueDisplayName]);
    const handleQuery = async (e, search) => {
        let list = [];
        setSearchQuery(search.searchQuery);
        if (search.searchQuery.length > 3) {
            if (props.queueSearch) {
                let rawList = await Axios.get(
                    getAllQueueManagementUrl(
                        authContext._user.profile.tid,
                        authContext._user.profile.oid,
                        props.scope
                    )
                );
                list = Object.keys(rawList.data).map((elm) => {
                    return {
                        header: rawList.data[elm].queueName,
                        key: rawList.data[elm].RowKey,
                    };
                });
                setSuggestedItems(() => list);
                return;
            }
        }
    };
    return (
        <>
            <Dropdown
                className="from-item"
                search
                searchQuery={searchQuery}
                items={suggestedItems}
                placeholder={intl.formatMessage({ id: 'start-typing-queue-name' })}
                noResultsMessage={intl.formatMessage({ id: "we-couldn't-find-any-matches" })}
                onSearchQueryChange={(e, textQuery) => handleQuery(e, textQuery)}
                onChange={(e, data) => {
                    if (props.isCallflow === true) {
                        if (props.itemToUpdate === "openHours") {
                            props.setSelectedOpenHoursQueue(data.value.key, data.value.header, props.itemToUpdate)

                        } else if (props.itemToUpdate === "holidays") {
                            props.setSelectedHolidaysQueue(data.value.key, data.value.header, props.itemToUpdate)
                        }

                    } else {
                        props.setSelectedOverflowQueue(data.value.key, data.value.header)
                    }

                }
                }
                defaultSearchQuery={props?.queueInfo?.queueDisplayName}
            />
        </>
    );
};
export default QueueSearch;
