import React, { useEffect, useState } from "react";
import { Header, Checkbox } from "@fluentui/react-northstar";
import { useIntl } from 'react-intl';
const ChatQueueComponent = (props) => {
  const intl = useIntl();
  return (
    <div>
      <div className="is-chat-queue">
        <Header
          as="h4"
          content={intl.formatMessage({ id: "voice" })}
          className="label-form_queue header-announce_position_in_queue"
        />
        <Checkbox
          className="is-chat-queue-checkbox"
          toggle
          disabled
          checked
          onChange={(e, val) => {
            props.setQueueType("call", val.checked);
          }}
        />
      </div>
      <div className="is-chat-queue">
        <Header
          as="h4"
          content={intl.formatMessage({ id: "chat" })}
          className="label-form_queue header-announce_position_in_queue"
        />
        {props.queueInfos.channels?.chat === true ? (
          <Checkbox
            className="is-chat-queue-checkbox"
            toggle
            checked
            onChange={(e, val) => {
              props.setQueueType("chat", val.checked);
            }}
          />
        ) : (
          <Checkbox
            className="is-chat-queue-checkbox"
            toggle
            defaultValue={false}
            onChange={(e, val) => {
              props.setQueueType("chat", val.checked);
            }}
          />
        )}
      </div>
    </div>
  );
};
export default ChatQueueComponent;
