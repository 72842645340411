import React from "react";
import { connect } from "react-redux";
import { setTechnicalUser } from "../../redux/actions";
import axios from "axios";
import { authContext } from "../../auth";
import { useState } from "react";
import {
  Header,
  Label,
  Flex,
  Button,
  AcceptIcon,
  Form,
  FormInput,
  FormButton,
  Input,
} from "@fluentui/react-northstar";
import translate from "../../i18n/translate";
import { getToken } from "../../components/Graph";
import { EditIcon } from "@fluentui/react-northstar";
import {
  TechnicalAccountManagement,
  getAdminConsentUrl,
  ConsentccUrl,
  ConsentpresenceUrl,
  addAADProvisioningGroupManagementURL,
  getAADProvisioningGroupManagementURL,
  deleteAADProvisioningGroupManagementURL,
} from "../../urls";
import { useEffect } from "react";
import { useIntl } from "react-intl";
const TECHNICAL_USERS_API_URL = TechnicalAccountManagement;
const presenceAPp = "b49547c3-196e-4e6b-a4c0-a4deea391db0";
const contactCenterApp = "2ad91f01-bf93-4313-89f1-cb201229e7db";
authContext.handleWindowCallback();
const user = authContext.getCachedUser();
var technicalAccount = {};
var setTechnicalUsertoStore;
const UpdatetechnicalAccount = (key, value) => {
  technicalAccount[key] = value;
};
const GlobalSettingsContainer = (props) => {
  const intl = useIntl();
  const [groupId, setGroupId] = useState("");
  const [groupToUpdate, setGroupToUpdate] = useState(null);
  const [group, setGroup] = useState({});
  if (!props.technicalUser) {
    props.getTechnicalUser();
  }
  setTechnicalUsertoStore = props.setTechnicalUser;
  if (props.technicalUser) {
    technicalAccount = props.technicalUser;
  }
  const [displayTechnicalAccount, setDisplayTechnicalAccount] =
    useState("hide");
  const ConsentStatus = (appId) => {
    if (props.consentedApps && props.consentedApps.indexOf(appId) >= 0) {
      return <AcceptIcon size="large" className="succes-accept-icon" />;
    } else {
      return <AcceptIcon size="large" className="disabled-accept-icon" />;
    }
  };
  const editTechnicalAccount = () => {
    setDisplayTechnicalAccount("show");
  };
  const getGroup = async () => {
    let Token = await getToken(authContext);
    const url = getAADProvisioningGroupManagementURL(
      authContext._user.profile.tid,
      Token
    );
    await axios.get(url).then((Res) => {
      setGroup(Res.data);
    });
  };
  const addGroup = async (group) => {
    let Token = await getToken(authContext);
    let url = addAADProvisioningGroupManagementURL(
      authContext._user.profile.tid,
      groupId,
      Token
    );
    axios.post(url, {}).then((res, err) => {
      getGroup();
    });
  };
  const updateGroup = async (oldGroup, newGroup) => {
    removeGroup(oldGroup);
    addGroup(newGroup);
    getGroup();
  };
  const removeGroup = async (group) => {
    let Token = await getToken(authContext);
    let url = deleteAADProvisioningGroupManagementURL(
      authContext._user.profile.tid,
      group,
      Token
    );
    axios.post(url, {}).then((res, err) => {
      getGroup();
    });
  };

  useEffect(() => {
    if (props.scopeList) {
      getGroup();
    }
  }, [props.scopeList]);

  return (
    <div className="table-list">
      <Header
        as="h2"
        content={translate("Heedify service application consent")}
      />
      <Flex gap="gap.large">
        <Flex.Item size="size.half">
          <Header
            className="titre-global-setting"
            as="p"
            content={translate(
              "Heedify Call Center and Receptionist Console Application"
            )}
            style={{ marginRight: "0px" }}
          ></Header>
        </Flex.Item>
        <span className="close-btn">
          {ConsentStatus(contactCenterApp)}
          <a
            href={getAdminConsentUrl(contactCenterApp) +
              "&redirect_uri=" +
              ConsentccUrl}
            rel="noopener noreferrer"
          >
            <Button content={translate("Consent Permissons")} primary />
          </a>
        </span>
      </Flex>
      <Flex>
        <Flex.Item size="size.half">
          <Header
            className="titre-global-setting"
            as="p"
            content={translate("Heedify Presence Application")}
          ></Header>
        </Flex.Item>
        <span className="check-btn">
          {ConsentStatus(presenceAPp)}
          <a
            href={
              getAdminConsentUrl(presenceAPp) +
              "&redirect_uri=" +
              ConsentpresenceUrl
            }
            rel="noopener noreferrer"
          >
            <Button content={translate("Consent Permissons")} primary />
          </a>
        </span>
      </Flex>
      <Header as="h2" content={translate("Technical Teams account")} />
      <Flex></Flex>
      <Flex className="block">
        <Flex.Item>
          <div className="block">
            <Header
              className="technical-teams-account"
              as="p"
              content={translate(
                "This technical teams account is used by Heedify BackEnd services to track presence status of Agents"
              )}
            ></Header>
            <Header
              className="technical-teams-account"
              as="p"
              content={translate(
                "Please note that we dont support MFA (Multi-Fator Authentication) for this account"
              )}
            ></Header>
          </div>
        </Flex.Item>
        <div className="block">
          <br></br>
          <Label
            className="label-teams-account"
            content={translate("The defined technical user is")}
          ></Label>
          <Label
            className="label-teams-account"
            inline="true"
            content={technicalAccount.userName}
          ></Label>
          <Button
            icon={<EditIcon />}
            text
            iconOnly
            title="Edit technical account"
            onClick={() => editTechnicalAccount()}
          />
          <Form
            className={displayTechnicalAccount}
            onSubmit={() => {
              setTechnicalUsertoStore(technicalAccount);
            }}
          >
            <FormInput
              type="email"
              label={translate("userName")}
              defaultValue={technicalAccount.userName}
              required
              onChange={(a, V) => {
                UpdatetechnicalAccount("userName", V.value);
              }}
            />
            <FormInput
              type="password"
              label={translate("password")}
              defaultValue={technicalAccount.password}
              required
              onChange={(a, V) => {
                UpdatetechnicalAccount("password", V.value);
              }}
            />
            <FormButton content={translate("Submit")} primary />
          </Form>
        </div>
      </Flex>
      {/**
        <Header
        as="h2"
        content={translate("Azure-ad-agent-provisioning-title")}
      />
      <div>
        <Header
          className="titre-global-setting"
          as="p"
          content={translate("Azure-ad-agent-provisioning-discription")}
          style={{ marginRight: "0px" }}
        ></Header>
        <div >
          <Label className='label-teams-account' content={translate("The group id is")}></Label>
          <Label className='label-teams-account' inline="true" content={group.RowKey}></Label>
          <Button
            icon={<EditIcon />}
            text
            iconOnly
            title={intl.formatMessage({ id: "edit" })}
            onClick={(event) => setGroupToUpdate(group.RowKey)}
          />
          {
            groupToUpdate === group.RowKey ?
              <div className="edit-group-form">
                <FormInput
                  defaultValue={groupToUpdate}
                  required
                  showSuccessIndicator={false}
                  onChange={(a, data) => {
                    setGroupId(data.value);
                  }}
                />
                <div className="group-form-control" >
                  <FormButton content={translate("Submit")} primary onClick={() => { updateGroup(group.RowKey, groupToUpdate) }} />
                  <Button content={translate("cancel")} primary onClick={() => { setGroupToUpdate(null) }} />
                </div>

              </div>
              :
              null
          }
        </div>
      </div>
       */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  technicalUser: state.technicalUser,
  consentedApps: state.consentedApps,
  scopeList: state?.scopeList,
});

const mapDispatchToProps = (dispatch) => ({
  getTechnicalUser: async () => {
    let url =
      TECHNICAL_USERS_API_URL +
      user.profile.tid +
      "/default/get/0?userid=" +
      user.profile.oid;
    axios.post(url).then((result) => {
      dispatch(setTechnicalUser(result.data));
    });
  },
  setTechnicalUser: async (techuser) => {
    let url =
      TECHNICAL_USERS_API_URL +
      user.profile.tid +
      "/default/update/0?userid=" +
      user.profile.oid;
    axios.post(url, techuser).then((result) => {
      dispatch(setTechnicalUser(result.data));
    });
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSettingsContainer);
