/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { AddIcon, TrashCanIcon, EditIcon } from "@fluentui/react-icons-northstar";
import Axios from "axios";
import { authContext } from "../../auth";
import { Button, Table } from "@fluentui/react-northstar";
import { connect } from "react-redux";
import { setLicences } from "../../redux/actions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import translate from "../../i18n/translate";
import { getToken } from '../../components/Graph'
import { useIntl } from 'react-intl';
import { useHistory } from "react-router-dom";
import { deleteAgentUrl, getAllAgentsUrl, getAllLicences } from '../../urls';
const AgentListContainer = (props) => {
  const intl = useIntl();
  const history = useHistory();
  var [rowsPlain, setRowsPlain] = useState([]);
  const addAgent = () => {
    history.push(`/agent/newAgent`);
  };
  const getLicenceList = async () => {
    let scopeListName = Object.keys(props.scopeList)
    const promises = []
    let Token = await getToken(authContext);
    for (let scope of scopeListName) {
      const url = getAllLicences(authContext._user.profile.tid, Token, scope);
      await Axios.get(url).then((Res) => {
        promises.push(Res.data)
      });
    }
    Promise.all(promises).then((results) => {
      var stateNew = {}
      for (var result of results) {
        stateNew[result.RowKey] = result;
      }
      return stateNew
    }).then((stateNew) => {
      props.setLicences(stateNew)
    })

  };
  const confirmeRemoveAgent = (agentId, agentName, AgentObject, key) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + agentName + '!',
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => removeAgent(agentId, AgentObject, key)
        },
        {
          label: intl.formatMessage({ id: "no" }),
        }
      ]
    });
  };
  const removeAgent = async (agentId, AgentObject, key) => {
    let result = await Axios.post(deleteAgentUrl(authContext._user.profile.tid, authContext._user.profile.oid, agentId, AgentObject.scope))
    if (result.data === "removed") {
      getAgentsList();
      getLicenceList();
    }
  };
  const selectAgent = (event, data) => {
    history.push(`/agent/${data}`);
  }
  const header = {
    key: "header",
    items: [
      {
        content: intl.formatMessage({ id: "name" }),
        key: "name",
      },
      {
        content: intl.formatMessage({ id: "email" }),
        key: "email",
      },
      {
        content: intl.formatMessage({ id: "scope" }),
        key: "scope",
      },
      {
        content: intl.formatMessage({ id: "USER_PRINCIPAL_CALLFLOW" }),
        key: "USER_PRINCIPAL_CALLFLOW",
      },
      {
        content: intl.formatMessage({ id: "licence-level" }),
        key: "licence-level",
      },
      {
        content: intl.formatMessage({ id: "action" }),
        key: "action",
      },
    ],
  };
  const formatList = (list) => {
    const listArr = Object.keys(list);
    setRowsPlain([])
    return listArr.map((elm, i) => {
      if (list[elm]?.RowKey) {
        let rowTable = {
          key: i,
          items: [
            {
              content: list[elm].displayName,
              key: i + "-1",
            },
            {
              content: list[elm].email,
              key: i + "-4",
            },
            {
              content: list[elm].scope,
              key: i + "-7",
            },
            {
              content: list[elm].outboundCalling ? list[elm].outboundCalling.applicationInstanceDisplayName : null
              ,
              truncateContent: true,
              key: i + "-2",
            },
            {
              content: intl.formatMessage({ id: list[elm].licenceLevel }),
              key: i + "-3",
            },
            {
              content:
                <div>
                  <Button
                    icon={<TrashCanIcon />}
                    text
                    iconOnly
                    title={intl.formatMessage({ id: "remove-agent" })}
                    onClick={() => confirmeRemoveAgent(list[elm].RowKey, list[elm].displayName, list[elm], i)}
                  />
                  <Button
                    icon={<EditIcon />}
                    text
                    iconOnly
                    title={intl.formatMessage({ id: "edit-agent" })}
                    onClick={(event) => selectAgent(event, list[elm].RowKey)}
                  />
                </div>
              ,
              key: i + "-6",
            },
          ],
        };
        setRowsPlain((rowsPlain) => [...rowsPlain, rowTable]);
        return rowsPlain;
      }
      return rowsPlain;
    });
  };
  const getAgentsList = async () => {
    /**
     * for evry scops we get all flows 
     * push all  results in a promise 
     * update agents object (agents)
     */
    if (props.scopeList && props.scopeList !== null) {
      let scopeListName = Object.keys(props.scopeList)
      const promises = []
      for (let scope of scopeListName) {
        const url = getAllAgentsUrl(authContext._user.profile.tid, authContext._user.profile.oid, scope);
        await Axios.get(url).then((res) => {
          promises.push(res.data)
        })
      }
      Promise.all(promises).then((results) => {
        var stateNew = []
        for (var result of results) {
          if (Object.keys(result).length > 0) {
            stateNew = stateNew.concat(Object.values(result))
          }
        }
        return stateNew
      }).then((stateNew) => {
        formatList(stateNew)
      })
    }
  };
  useEffect(() => {
    if (props.scopeList) {
      getAgentsList()
    }
  }, [props.scopeList])
  return (
    <div className="table-list">
      <h2>{translate("agents-list")}</h2>
      <div className="table-header">
        <Button
          icon={<AddIcon />}
          text
          content={intl.formatMessage({ id: "add" })}
          primary
          onClick={addAgent}
        />
      </div>
      <Table
        className="table-agent"
        variables={{
          cellContentOverflow: "none",
        }}
        header={header}
        rows={rowsPlain}
        aria-label="Static table"
      />
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return {
    licences: state.licences,
    scopeList: state.scopeList,
    callFlows: state.callFlows
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLicences: (Licences) => { dispatch(setLicences(Licences)) },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgentListContainer);
