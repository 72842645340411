import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../css/Admin.css";
import { Nav } from "office-ui-fabric-react/lib/Nav";
import { useHistory } from "react-router-dom";
import LicenceContainer from "../Licences/LicenceContainer";
import ProtectedRoute from "../ProtectedRoute";
import CallCenterContainer from "../CallCenterContainer/CallCenterContainer";
import ChatCenterContainer from "../ChatFlowContainer/ChatCenterContainer";
import AdminUsers from "../AdminUsers";
import Media from "../Media/Media";
import QueueContainer from "../Queues/QueueContainer";
import GlobalSettingsContainer from "../GlobalSettings/GlobalSettingsContainer";
import HomePageContainer from "../Home/HomePageContainer";
import QueuesListContainer from "../Queues/QueuesListContainer";
import ChatQueuesListContainer from "../ChatQueues/ChatQueuesListContainer";
import ChatQueueContainer from "../ChatQueues/ChatQueueContainer";
import MediaContainer from "../Media/MediaContainer";
import CallFlowListContainer from "../CallCenterContainer/CallFlowListContainer";
import ChatFlowListContainer from "../ChatFlowContainer/ChatFlowListContainer";
import AgentContainer from "../Agents/AgentContainer";
import AgentListContainer from "../Agents/AgentListContainer";
import GroupsContainer from "../Groups/GroupsContainer";
import { useIntl } from "react-intl";
import { AiOutlineHome } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { RiUserSettingsLine } from "react-icons/ri";
import { RiCustomerService2Line } from "react-icons/ri";
import { TiGroupOutline } from "react-icons/ti";
import { ImFileMusic } from "react-icons/im";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import QueuesIcone from "../../assets/Queues.webp";
import CallFlowIcone from "../../assets/call-flows.png";
import ChatFlowIcone from "../../assets/chat-flows.webp";
class Admin extends React.PureComponent {
  render() {
    return (
      <div id="top-header">
        <div id="left-menu">
          <AdminMenu />
        </div>
        <div id="body-content">
          <ProtectedRoute
            exact
            path={`/`}
            redirectTo="/home"
            component={HomePageContainer}
          />
          <ProtectedRoute
            exact
            path={`/home`}
            redirectTo="/home"
            component={HomePageContainer}
          />
          <ProtectedRoute
            exact
            path={`/call-flow`}
            redirectTo="/welcome"
            component={CallFlowListContainer}
          />
          <ProtectedRoute
            exact
            path={`/chat-flow`}
            redirectTo="/welcome"
            component={ChatFlowListContainer}
          />
          <ProtectedRoute
            exact
            path={`/chat-flow/:id`}
            redirectTo="/welcome"
            component={ChatCenterContainer}
          />
          <ProtectedRoute
            exact
            path={`/chat-flow/:id/:scope`}
            redirectTo="/welcome"
            component={ChatCenterContainer}
          />
          <ProtectedRoute
            exact
            path={`/chat-queues/:id`}
            redirectTo="/welcome"
            component={ChatQueueContainer}
          />
          <ProtectedRoute
            exact
            path={`/call-flow/:id/:scope`}
            redirectTo="/welcome"
            component={CallCenterContainer}
          />
          <ProtectedRoute
            exact
            path={`/call-flow/:id`}
            redirectTo="/welcome"
            component={CallCenterContainer}
          />
          <ProtectedRoute
            exact
            path={`/licences`}
            redirectTo="/welcome"
            component={LicenceContainer}
          />
          <ProtectedRoute
            exact
            path={`/queues`}
            redirectTo="/welcome"
            component={QueuesListContainer}
          />
          <ProtectedRoute
            exact
            path={`/groups`}
            redirectTo="/welcome"
            component={GroupsContainer}
          />
          <ProtectedRoute
            exact
            path={`/chat-queues`}
            redirectTo="/welcome"
            component={ChatQueuesListContainer}
          />
          <ProtectedRoute
            exact
            path={`/agent`}
            redirectTo="/welcome"
            component={AgentListContainer}
          />
          <ProtectedRoute
            exact
            path={`/admins`}
            redirectTo="/welcome"
            component={AdminUsers}
          />
          <ProtectedRoute
            path={`/queues/:id`}
            redirectTo="/welcome"
            component={QueueContainer}
          />
          <ProtectedRoute
            path={`/agent/:id`}
            redirectTo="/welcome"
            component={AgentContainer}
          />
          <ProtectedRoute
            exact
            path={`/media`}
            redirectTo="/welcome"
            component={Media}
          />
          <ProtectedRoute
            exact
            path={`/media/newMedia`}
            redirectTo="/welcome"
            component={MediaContainer}
          />
          <ProtectedRoute
            exact
            path={`/globalSettings`}
            redirectTo="/welcome"
            component={GlobalSettingsContainer}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  //CallQueueList: state.CallQueueList,
});
const mapDispatchToProps = (dispatch) => ({});
const AdminMenu = () => {
  let history = useHistory();
  const intl = useIntl();
  useEffect(() => history.push(history.location), [history]);
  const navLinkGroups = [
    {
      links: [
        {
          name: (
            <div>
              <AiOutlineHome
                style={{ fontSize: "18px", marginRight: "10px" }}
              />{" "}
              Home
            </div>
          ),
          target: "_blank",
          expandAriaLabel: "Expand Parent link 1",
          collapseAriaLabel: "Collapse Parent link 1",
          key: "home",
        },
        {
          name: (
            <div>
              <FiSettings style={{ fontSize: "18px", marginRight: "10px" }} />{" "}
              {intl.formatMessage({ id: "globalSettings" })}
            </div>
          ),
          target: "_blank",
          expandAriaLabel: "Expand Parent link 1",
          collapseAriaLabel: "Collapse Parent link 1",
          key: "globalSettings",
        },
        {
          name: (
            <div>
              <RiUserSettingsLine
                style={{ fontSize: "18px", marginRight: "10px" }}
              />{" "}
              {intl.formatMessage({ id: "Administrators" })}
            </div>
          ),
          target: "_blank",
          expandAriaLabel: "Expand Parent link 1",
          collapseAriaLabel: "Collapse Parent link 1",
          key: "admins",
        },

        {
          name: (
            <div>
              <TiGroupOutline
                style={{ fontSize: "18px", marginRight: "10px" }}
              />{" "}
              {intl.formatMessage({ id: "groups" })}
            </div>
          ),
          key: "groups",
          expandAriaLabel: "Expand Parent link 2",
          collapseAriaLabel: "Collapse Parent link 2",
        },

        {
          name: (
            <div>
              <img
                src={QueuesIcone}
                style={{ width: "20px", marginRight: "10px" }}
              />{" "}
              {intl.formatMessage({ id: "queues" })}
            </div>
          ),
          target: "_blank",
          expandAriaLabel: "Expand Parent link 1",
          collapseAriaLabel: "Collapse Parent link 1",
          key: "queues",
        },
        {
          name: (
            <div>
              <img
                src={CallFlowIcone}
                style={{ width: "20px", marginRight: "10px" }}
              />{" "}
              {intl.formatMessage({ id: "call_flow" })}
            </div>
          ),
          target: "_blank",
          expandAriaLabel: "Expand Parent link 1",
          collapseAriaLabel: "Collapse Parent link 1",
          key: "call-flow",
        },
        {
          name: (
            <div>
              <img
                src={ChatFlowIcone}
                style={{ width: "20px", marginRight: "10px" }}
              />
              {intl.formatMessage({ id: "chat_flow" })}
            </div>
          ),
          target: "_blank",
          expandAriaLabel: "Expand Parent link 1",
          collapseAriaLabel: "Collapse Parent link 1",
          key: "chat-flow",
        },
        {
          name: (
            <div>
              <RiCustomerService2Line
                style={{ fontSize: "18px", marginRight: "10px" }}
              />{" "}
              {intl.formatMessage({ id: "agents" })}
            </div>
          ),
          key: "agent",
          expandAriaLabel: "Expand Parent link 2",
          collapseAriaLabel: "Collapse Parent link 2",
        },
        {
          name: (
            <div>
              <ImFileMusic style={{ fontSize: "18px", marginRight: "10px" }} />{" "}
              {intl.formatMessage({ id: "media" })}
            </div>
          ),
          target: "_blank",
          expandAriaLabel: "Expand Parent link 1",
          collapseAriaLabel: "Collapse Parent link 1",
          key: "media",
        },
        {
          name: (
            <div>
              <AiOutlineSafetyCertificate
                style={{ fontSize: "18px", marginRight: "10px" }}
              />{" "}
              {intl.formatMessage({
                id: "licences",
              })}
            </div>
          ),
          target: "_blank",
          expandAriaLabel: "Expand Parent link 2",
          collapseAriaLabel: "Collapse Parent link 2",
          key: "licences",
        },
      ],
    },
  ];
  let [itemClicked, setItemClicked] = useState(
    history.location.pathname.substring(1)
  );
  return (
    <Nav
      ariaLabel="Nav example with nested links"
      selectedKey={itemClicked}
      onLinkClick={(e, data) => {
        if (!data.forceAnchor) {
          // if it's not anchor point, select it
          setItemClicked(data.key);
          history.push(`/${data.key}`);
        } else {
          // it's anchor point, expand it or unexpand it // normalement il suffit de mettre forceAnchor à true mais y a un issue je crois check https://github.com/microsoft/fluentui/issues/3210
          data.isExpanded = !data.isExpanded;
        }
      }}
      groups={navLinkGroups}
    />
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
