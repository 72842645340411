import React, { useState } from "react";
import { Button, Input } from '@fluentui/react-northstar'
import { addScopeUrl } from "../../urls"
import { authContext } from "../../auth";
import { getToken } from "../../components/Graph";
import Axios from "axios";
import { useIntl } from 'react-intl';
const AddScope = (props) => {
    const intl = useIntl();
    const [newScope, setNewScope] = useState({
        PartitionKey: authContext._user.profile.tid,
        RowKey: "",
        admins: {
            adminsIds: props.scopeList["default"].admins.adminsIds,
            adminsDisplayNames: props.scopeList["default"].admins.adminsDisplayNames
        }
    })
    const addNewScope = () => {
        getToken(authContext).then(async (Token) => {
            Axios.post(addScopeUrl(authContext._user.profile.tid, newScope.RowKey, Token), newScope).then((res) => {
                props.addNewScope(newScope)
                props.setFormattedScopList([])
                props.setIsAddScope(false);
                setNewScope({
                    PartitionKey: authContext._user.profile.tid,
                    RowKey: "",
                    admins: {
                        adminsIds: props.scopeList["default"].admins.adminsIds,
                        adminsDisplayNames: props.scopeList["default"].admins.adminsDisplayNames
                    }
                })

            });
        });
    };
    return (
        <>
            {
                newScope && newScope !== null ?
                    <div >
                        <Input
                            className="from-item"
                            label={intl.formatMessage({ id: "scope-name" })}
                            onChange={(e, data) => {
                                setNewScope({ ...newScope, RowKey: data.value })
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'end', marginTop: "20px" }}>
                            <Button
                                content={intl.formatMessage({ id: "add" })}
                                title={intl.formatMessage({ id: "add" })}
                                primary
                                onClick={() => {
                                    addNewScope()
                                }}
                            />
                            <Button content={intl.formatMessage({ id: "cancel" })} title={intl.formatMessage({ id: "cancel" })} primary style={{ marginLeft: "15px" }}
                                onClick={() => {
                                    props.setIsAddScope(false);
                                    setNewScope({
                                        PartitionKey: authContext._user.profile.tid,
                                        RowKey: "",
                                        admins: {
                                            adminsIds: [],
                                            adminsDisplayNames: {}
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                    :
                    null

            }
        </>
    )
}
export default AddScope