import React from "react";
import { Dropdown } from "@fluentui/react-northstar";
import moment from "moment-timezone";
const TimeZone = (props) => {
  const inputItems = moment.tz.names();
  return (
    <>
      {inputItems ? (
        <Dropdown
          required
          className="child-title-call-flow"
          items={inputItems}
          defaultValue={props.timeZone.toString()}
          defaultSearchQuery={props.timeZone.toString()}
          onChange={props.getData}
          selected={props.timeZone}
          checkable
          search
          getA11ySelectionMessage={{
            onAdd: (item) => `${item} has been selected.`,
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default TimeZone;
