import React from "react";
import CallMaxWaitTimeout from "../../components/Queues/CallMaxWaitTimeout";
import { connect } from "react-redux";
import {
  setSelectedUserTransfertType,
  setSelectedOverflowType,
  setSelectedUserTransfertId,
} from "../../redux/actions";
const CallMaxWaitTimeoutContainer = (props) => {
  return (
    <CallMaxWaitTimeout
      scope={props.scope}
      setSelectedUserTransfertType={props.setSelectedUserTransfertType}
      setSelectedOverflowType={props.setSelectedOverflowType}
      setSelectedUserTransfertId={props.setSelectedUserTransfertId}
      id={props.id}
      overflowType={props.overflowType}
      action={props.action}
      targetObj={{ displayName: props.displayName }}
      {...props}
    />
  );
};
const mapStateToProps = (state, props) => {
  return {
    displayName: state?.autoAttendant[props.match.params.id]?.queueOverflow?.displayName,
    id: state?.autoAttendant[props.match.params.id]?.queueOverflow?.targetId,
    overflowType: state?.autoAttendant[props.match.params.id]?.queueOverflow?.targetType,
    action: state?.autoAttendant[props.match.params.id]?.queueOverflow?.type,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setSelectedUserTransfertType: (value) => dispatch(setSelectedUserTransfertType(value)),
  setSelectedOverflowType: (value) => dispatch(setSelectedOverflowType(value)),
  setSelectedUserTransfertId: (value) => { dispatch(setSelectedUserTransfertId(value)); },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallMaxWaitTimeoutContainer);
