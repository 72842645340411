import React, { useState, useRef, useEffect } from "react";
import {
    Dropdown,
    Input,
    Table,
    Button,
    AddIcon,
    TrashCanIcon,
    EditIcon,
    Form,
    Flex
} from "@fluentui/react-northstar";
import translate from '../../i18n/translate';
import { useIntl } from 'react-intl';
const FormComponent = (props) => {
    const intl = useIntl();
    const [newItem, setNewItem] = useState({})
    const [isAddItem, setIsAddItem] = useState(false)
    const [indexEditItem, setIndexEditItem] = useState(null)
    const [itemToEdit, setItemToEdit] = useState({})
    const [itemsList, setItemsList] = useState([])
    const [nextRouting, setNextRouting] = useState([])
    const formatTypeDefaultValue = (type) =>{
            let result = ""
            switch (type) {
                case "Input.Text":
                    result = intl.formatMessage({ id: "text" })
                    break;
                case "Input.Date":
                    result = intl.formatMessage({ id: "date" })
                    break;
            }
            return result;
    }
    const fields = [
        {
            label: intl.formatMessage({ id: 'label' }),
            name: 'Label',
            id: 'Label-shorthand',
            key: 'Label',

            required: true,
            control: {
                defaultValue: indexEditItem !== null ? itemToEdit.id : null,
                as: Input,
                showSuccessIndicator: false,
                onChange: (e, data) => {
                    if (indexEditItem !== null) {
                        setItemToEdit(itemToEdit => ({
                            ...itemToEdit,
                            id: data.value
                        }));
                    } else {
                        setNewItem(newItem => ({
                            ...newItem,
                            id: data.value
                        }));
                    }
                },
            },
        },
        {
            label: intl.formatMessage({ id: 'placeholder' }),
            name: 'placeholder',
            id: 'placeholder-shorthand',
            key: 'placeholder',
            required: false,
            control: {
                defaultValue: indexEditItem !== null ? itemToEdit.placeholder : null,
                as: Input,
                showSuccessIndicator: false,
                onChange: (e, data) => {
                    if (indexEditItem !== null) {
                        setItemToEdit(itemToEdit => ({
                            ...itemToEdit,
                            placeholder: data.value
                        }));
                    } else {
                        setNewItem(newItem => ({
                            ...newItem,
                            placeholder: data.value
                        }));
                    }
                },
            },
        },
        {
            label: {
                content: intl.formatMessage({ id: 'type' }),
            },
            name: 'Type',
            key: 'Type-friend',
            id: 'Type-shorthand',

            required: true,
            control: {
                defaultValue: indexEditItem !== null ? formatTypeDefaultValue(itemToEdit.type) : null,
                as: Dropdown,
                items: [{
                    header: intl.formatMessage({ id: "text" }),
                    key: "Input.Text"
                },
                {
                    header: intl.formatMessage({ id: "date" }),
                    key: "Input.Date"
                }],
                onChange: (e, data) => {
                    if (indexEditItem !== null) {
                        setItemToEdit(itemToEdit => ({
                            ...itemToEdit,
                            type: data.value.key
                        }));
                    } else {
                        setNewItem(newItem => ({
                            ...newItem,
                            type: data.value.key
                        }));
                    }

                },
            },
        },
        {
            control: {
                as: Button,
                content: 'Confirm',
            },
            key: 'Add',
        },
    ]
    const header = {
        items: [intl.formatMessage({ id: "label" }), intl.formatMessage({ id: 'placeholder' }), intl.formatMessage({ id: 'type' }), intl.formatMessage({ id: 'action' })],
    }
    useEffect(() => {
        setItemsList([]);
        if (props.data.items) {
            props.data.items.map(function (item, i) {
                let itemObject = {
                    key: i,
                    items: [item.id, item.placeholder, formatTypeDefaultValue(item.type), <div>
                        <Button
                            iconOnly
                            icon={<EditIcon />}
                            text
                            title="Edite item"
                            onClick={() => {
                                setIndexEditItem(i)
                                setItemToEdit(item)
                            }}
                        />
                        <Button
                            icon={<TrashCanIcon />}
                            text
                            iconOnly
                            title="Remove item"
                            onClick={() => { props.deleteItemFromRoutingForm(i, props.routingName) }}
                        />
                    </div>],
                }
                setItemsList(itemsList => [...itemsList, itemObject]);
            })
        }
    }, [props.data.items])
    useEffect(() => {
        setNextRouting([])
        for (const routing of props.routingList) {
            if (routing !== props.routingName && props.chatCenterData.routing[routing].type && props.chatCenterData.routing[routing].type !== "") {
                setNextRouting(nextRouting => [...nextRouting, routing]);
            }
        }
    }, [props.routingList])
    return (
        <div>
            <div style={{ marginLeft: '50px', width: '60%' }}>
                <Table compact header={header} rows={itemsList} aria-label="Compact view static table" />
            </div>
            {
                isAddItem || indexEditItem !== null ?
                    <div className="routing-item-form-div">
                        <Form
                            onSubmit={(e, data) => {
                                if (indexEditItem !== null) {
                                    props.updateRoutingFormData(indexEditItem, itemToEdit, props.routingName)
                                    setIndexEditItem(null)

                                } else {
                                    props.setFormData(newItem, props.routingName)
                                    setIsAddItem(false)
                                }
                            }}
                            fields={fields}
                        />
                    </div>
                    :
                    null
            }
            <Button
                style={{ marginLeft: '50px' }}
                icon={<AddIcon />}
                text
                primary
                content={intl.formatMessage({ id: "add-item" })}
                onClick={async () => {
                    setIsAddItem(true)
                }}
            />
            <Flex column >
                <div style={{ marginLeft: '50px' }}>
                    <Flex.Item inline align="end">
                        <label style={{ fontWeight: '700' }}>{translate("next")}</label>
                    </Flex.Item>
                    <Dropdown
                        items={nextRouting}
                        value={props.data.next ? props.data.next.routingName : null}
                        checkable
                        onChange={(e, val) => {
                            props.setNextRoutingForm(val.searchQuery, props.routingName)
                        }}
                    />
                </div>

            </Flex>
        </div>
    )
}
export default FormComponent;