/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import QueueComponent from "../../components/Queues/QueueComponent";
import ServiceIntegrationComponent from "../../components/Queues/ServiceIntegrationComponent";
import ChatQueueComponent from "../../components/Queues/chatQueueComponent"
import {
  setMaxAlertTime,
  setQueueName,
  setMaxCalls,
  setMaxWaitTime,
  deleteAnsweringAgent,
  deleteVipContact,
  addHoldOnMusic,
  deleteOnHoldMusic,
  queryOnHoldMusic,
  setHuntMethod,
  saveSelectedQueue,
  getQueueInfosById,
  setQueueLanguage,
  setAnnouncePositionInQueue,
  setAnnounceEstimatedWaitTime,
  setVipPeriority,
  setVipContact,
  setServicetype,
  setServiceApiKey,
  setServiceURL,
  setServiceParameters,
  addNewServiceParam,
  deleteServiceParametre,
  setServiceResultUrl,
  createNewQueue,
  setAgentSkill,
  setQueueType,
  setQueueScope,
  emptyQueueAgent,
  getAutoAttendantInitState,
  addAnsweringAgent,
  setWaitTimeIntervals,
  deleteWaitTimeInterval,
  setAuthorizedActivityValues,
  setFallbackIfNoAgentConnected,
  playMessageBeforeQueueFallBack,
  setQueueFallBackMessage,
  setWrapUpTime
} from "../../redux/actions";
import { setSlaThresholdInStore, setSlaInStore } from "../../redux/actions"
import { Tabs, Tab } from "react-bootstrap";
import { authContext } from "../../auth";
import { useParams } from "react-router-dom";
import { AddQueueManagementUrl, UpdateQueueManagementUrl } from "../../urls";
import Axios from "axios";
import { Button } from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import ChannelAgentComponent from "../../components/Queues/ChannelAgentComponent"
import { useIntl } from 'react-intl';
const QueueContainer = (props) => {
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showChatTab, setShowChatTab] = useState(false);
  const History = useHistory();
  const params = useParams();
  const intl = useIntl();
  const sendData = (props) => {
    setIsSaveLoading(true);
    const tenantId = authContext._user.profile.tid;
    const data = props.queueData;
    const itemsOfData = Object.keys(data);
    itemsOfData.forEach((element) => {
      if (data[element] === undefined || data[element] === "") {
        // eslint-disable-next-line default-case
        switch (element) {
          case "maxInQueue":
            data[element] = "30";
            break;
          case "maxWaitTime":
            data[element] = "250";
            break;
          case "maxAlertTime":
            data[element] = "40";
            break;
          case "slaThreshold":
            data[element] = "";
            break;
          case "sla":
            data[element] = "";
            break;
        }
      }
      if (element === "channels") {
        data[element] = data[element];
      }
    });
    var url = "";
    if (params.id !== "newQueue") {
      url = UpdateQueueManagementUrl(
        tenantId,
        authContext._user.profile.oid,
        params.id,
        data.scope
      );
    } else {
      url = AddQueueManagementUrl(tenantId, authContext._user.profile.oid, data.scope);
    }
    //  const url = AddQueueManagementUrl(tenantId, authContext._user.profile.oid)

    Axios.post(url, { queue: data }).then((res, err) => {
      if (err) {
        setIsSaveLoading(false);
      } else {
        setIsSaveLoading(false);
        History.push(`/queues`);
      }
    });
  };
  useEffect(() => {
    props.saveSelectedQueue(props.match.params.id);
    if (props.match.params.id !== "newQueue") {
      props.getQueueInfosById(props.match.params.id, props.queueInfos?.scope);
    }
  }, [props.match.params.id]);
  useEffect(() => {
    if (props.queueInfos === undefined) {
      props.createNewQueue()
    }
  }, [props.queueInfos]);
  useEffect(() => {
    if (props.queueInfos && props.queueInfos.channels && props.queueInfos.channels.chat === true) {
      setShowChatTab(true)
    } else {
      setShowChatTab(false)
    }
  }, [props.queueInfos])
  return (
    <div id="queues-div">
      <Tabs defaultActiveKey="queue configuration" id="uncontrolled-tab-example">
        <Tab eventKey="queue configuration" title={intl.formatMessage({ id: "queue-configuration" })}>
          <Button
            content={intl.formatMessage({ id: "save" })}
            loading={isSaveLoading}
            primary
            onClick={() => sendData(props)}
            id="submit-button-queue"
          />
          <QueueComponent
            deleteAnsweringAgent={props.deleteAnsweringAgent}
            deleteVipContact={props.deleteVipContact}
            setHuntMethod={props.setHuntMethod}
            setMaxCalls={props.setMaxCalls}
            setMaxWaitTime={props.setMaxWaitTime}
            setQueueName={props.setQueueName}
            setQueueLanguage={props.setQueueLanguage}
            setMaxAlertTime={props.setMaxAlertTime}
            queueInfos={props.queueInfos}
            addHoldOnMusic={props.addHoldOnMusic}
            queryOnHoldMusic={props.queryOnHoldMusic}
            musicSearchResults={props.musicSearchResults}
            deleteOnHoldMusic={props.deleteOnHoldMusic}
            answeringAgents={props.answeringAgents}
            huntMethod={props.huntMethod}
            queueData={props.queueData}
            setSlaThreshold={props.slaThreshold}
            setSla={props.sla}
            setAnnouncePositionInQueue={props.setAnnouncePositionInQueue}
            setAnnounceEstimatedWaitTime={props.setAnnounceEstimatedWaitTime}
            setVipPeriority={setVipPeriority}
            setVipContact={setVipContact}
            setWrapUpTime={setWrapUpTime}
            setWaitTimeIntervals={props.setWaitTimeIntervals}
            deleteWaitTimeInterval={props.deleteWaitTimeInterval}
            setFallbackIfNoAgentConnected={props.setFallbackIfNoAgentConnected}
            setAuthorizedActivityValues={props.setAuthorizedActivityValues}
            {...props}
          />
        </Tab>
        {
          props.queueInfos?.integrationParams !== undefined ?
            <Tab eventKey="service integration" title={intl.formatMessage({ id: "service-integration" })} >
              <Button
                content={intl.formatMessage({ id: "save" })}
                loading={isSaveLoading}
                primary
                onClick={() => sendData(props)}
                id="submit-button-queue"
              />
              <ServiceIntegrationComponent
                autoAttendant={props.autoAttendant}
                queueInfos={props.queueInfos}
                setServicetype={props.setServicetype}
                setServiceApiKey={props.setServiceApiKey}
                setServiceURL={props.setServiceURL}
                setServiceParameters={props.setServiceParameters}
                addNewServiceParam={props.addNewServiceParam}
                deleteServiceParametre={props.deleteServiceParametre}
                setServiceResultUrl={props.setServiceResultUrl}
              />
            </Tab>
            :
            null
        }
        {
          props.queueInfos?.channels !== undefined ?
            <Tab eventKey="chat queue" title={intl.formatMessage({ id: "chat-queue" })} >
              <Button
                content={intl.formatMessage({ id: "save" })}
                loading={isSaveLoading}
                primary
                onClick={() => sendData(props)}
                id="submit-button-queue"
              />
              <ChatQueueComponent
                queueInfos={props.queueInfos}
                setQueueType={props.setQueueType}
                {...props}
              />
            </Tab>
            :
            null
        }
        {
          showChatTab === true && props.queueInfos && props.scopeList ?
            <Tab eventKey="chat" title={intl.formatMessage({ id: "chat" })} >
              <Button
                content={intl.formatMessage({ id: "save" })}
                loading={isSaveLoading}
                primary
                onClick={() => sendData(props)}
                id="submit-button-queue"
              />
              <ChannelAgentComponent queueInfos={props.queueInfos} scopeList={props.scopeList} answeringAgents={props.answeringAgents} />
            </Tab>
            :
            null
        }
      </Tabs>
    </div>
  );
};
const mapStateToProps = (state, props) => {
  return {
    autoAttendant: state.autoAttendant,
    queueInfos: state.autoAttendant[props.match.params.id],
    musicSearchResults: state.autoAttendantComp?.musicOnHold?.searchResults || [],
    answeringAgents: state.autoAttendant[props.match.params.id]?.agentDetails,
    huntMethod: state.autoAttendant[props.match.params.id]?.huntMethod,
    maxWaitTim: state.autoAttendant[props.match.params.id]?.maxWaitTim,
    queueData: state.autoAttendant[props.match.params.id],
    slaThreshold: state.autoAttendant[props.match.params.id]?.slaThreshold,
    sla: state.autoAttendant[props.match.params.id]?.sla,
    scopeList: state.scopeList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAgentSkill: (agentId, skill) => dispatch(setAgentSkill(agentId, skill)),
  saveSelectedQueue: (value) => dispatch(saveSelectedQueue({ value })),
  addHoldOnMusic: (value) => dispatch(addHoldOnMusic({ value })),
  queryOnHoldMusic: (val) => dispatch(queryOnHoldMusic(val)),
  deleteOnHoldMusic: (id) => dispatch(deleteOnHoldMusic(id)),
  deleteAnsweringAgent: (id) => dispatch(deleteAnsweringAgent(id)),
  deleteVipContact: (id) => dispatch(deleteVipContact(id)),
  setHuntMethod: (huntMethod) => dispatch(setHuntMethod(huntMethod)),
  setMaxWaitTime: (maxWaitTime) => dispatch(setMaxWaitTime(maxWaitTime)),
  setMaxCalls: (maxCalls) => dispatch(setMaxCalls(maxCalls)),
  setQueueName: (queueName) => dispatch(setQueueName(queueName)),
  setAnnouncePositionInQueue: (boolean) => dispatch(setAnnouncePositionInQueue(boolean)),
  setAnnounceEstimatedWaitTime: (boolean) => dispatch(setAnnounceEstimatedWaitTime(boolean)),
  setVipPeriority: (enableVipPriority) => dispatch(setVipPeriority(enableVipPriority)),
  setVipContact: (contact, queueInfos) => dispatch(setVipContact(contact, queueInfos)),
  setQueueLanguage: (langue) => dispatch(setQueueLanguage(langue)),
  setMaxAlertTime: (maxAlertTime) => dispatch(setMaxAlertTime(maxAlertTime)),
  setSlaThreshold: (setSlaThreshold) => dispatch(setSlaThresholdInStore(setSlaThreshold)),
  setSla: (setSla) => dispatch(setSlaInStore(setSla)),
  setwrapUpTime: (wrapUpTime) => dispatch(setWrapUpTime(wrapUpTime)),
  getQueueInfosById: (value, scope) => dispatch(getQueueInfosById(value, scope)),
  setServicetype: (type) => dispatch(setServicetype(type)),
  setServiceApiKey: (apiKey) => dispatch(setServiceApiKey(apiKey)),
  setServiceURL: (url) => dispatch(setServiceURL(url)),
  setServiceParameters: (key, parameters) => dispatch(setServiceParameters(key, parameters)),
  addNewServiceParam: () => dispatch(addNewServiceParam()),
  deleteServiceParametre: (index) => dispatch(deleteServiceParametre(index)),
  setServiceResultUrl: (resultUrl) => dispatch(setServiceResultUrl(resultUrl)),
  createNewQueue: () => dispatch(createNewQueue()),
  setQueueType: (type, value) => dispatch(setQueueType(type, value)),
  setQueueScope: (scope) => dispatch(setQueueScope(scope)),
  emptyQueueAgent: () => dispatch(emptyQueueAgent()),
  getAutoAttendantInitState: (scopeListName) => dispatch(getAutoAttendantInitState(scopeListName)),
  setWaitTimeIntervals: (interval) => dispatch(setWaitTimeIntervals(interval)),
  deleteWaitTimeInterval: (interval) => dispatch(deleteWaitTimeInterval(interval)),
  setAuthorizedActivityValues: (values) => dispatch(setAuthorizedActivityValues(values)),
  setFallbackIfNoAgentConnected: (value) => { dispatch(setFallbackIfNoAgentConnected(value)); },
  playMessageBeforeQueueFallBack: (value) => { dispatch(playMessageBeforeQueueFallBack(value)); },
  setQueueFallBackMessage: (value) => { dispatch(setQueueFallBackMessage(value)); },
  addAnsweringAgent: (value) => {
    if (value.id) {
      dispatch(addAnsweringAgent(value));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QueueContainer);
