import React, { useState, useEffect } from "react";
import {
  Input,
  RadioGroup,
  Header,
  AddIcon,
  Button,
  ParticipantAddIcon 
} from "@fluentui/react-northstar";
import QueueUserSearch from "./QueueUserSearch";
import { useIntl } from 'react-intl';
const VipPriority = (props) => { 
    const [vipType, setVipType] = useState('');
    const [newContact,setNewContact] = useState({type:'',id:'',name:''})
    const setSelectedUserTransfertIdProp = (user) =>{
        setNewContact({type:'user',id: user.id, name: user.header})
    }
  const intl = useIntl();
    return  (
        <div>
        <RadioGroup
        onCheckedValueChange={(e, props) => {
        setNewContact({...newContact,type:props.value})
        setVipType(props.value)
        }}
        items={[
            {
              key: '1',
            label: intl.formatMessage({ id: "phone" }),
              value: 'phone',
            },
            {
              key: '2',
              label: intl.formatMessage({ id: "user" }),
              value: 'user',
            },
          ]}
        />
        <div>
            {vipType === 'phone'  &&
            <div>
                <div>
                    <Input
                    className="vip-input"
                    clearable
                  placeholder={intl.formatMessage({ id: "name" })} 
                    onChange ={(e,data)=>{
                        setNewContact({...newContact,name:data.value})
                    }}
                    />
                </div>
                <div>
                    <Input
                    className="vip-input"
                    clearable
                  placeholder={intl.formatMessage({ id: "phone-number" })} 
                    onChange ={(e,data)=>{
                        setNewContact({...newContact,id:data.value})
                    }}
                    />
                </div>
                <Button
                content={intl.formatMessage({ id: "add" })} 
                      onClick={() => {
                        props.setVipContact(newContact,props.queueInfos)
                        props.setIsNewVipPriority(false)
                      }}
                      primary
                    />
            
            </div>
            }
            { vipType === 'user' &&
            <div>
                <QueueUserSearch
                setSelectedUserTransfertIdProp={setSelectedUserTransfertIdProp}
                overflowType="USER"
                itemToUpdate="vipPriority"
                />
                <Button
                content={intl.formatMessage({ id: "add" })} 
                      onClick={() => {
                        props.setVipContact(newContact,props.queueInfos)
                        props.setIsNewVipPriority(false)
                      }}
                      primary
                    />
            </div>
            }
        </div>
    </div>
    )  
    
};
export default VipPriority;