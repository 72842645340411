import React from 'react'
import { connect } from 'react-redux'
import MediaComponent from '../../components/MediaComponent'
const MediaContainer = (props) => {
    return (<><MediaComponent />
    </>
    )
}
const mapStateToProps = (state, props) => {
    return {
        answeringAgents: state.autoAttendant[props.match.params.id]?.agentDetails,
    }
}
export default connect(
    mapStateToProps,
)(MediaContainer);


