import {
  Table,
  Button,
  Flex,
  Dropdown,
  Text,
  Input,
} from "@fluentui/react-northstar";
import { gridNestedBehavior } from "@fluentui/accessibility";
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { TrashCanIcon } from "@fluentui/react-icons-northstar";
import CallCenterName from "../../components/CallCenterComponents/CallCenterName";
import { v4 as uuidv4 } from "uuid";
import update from "immutability-helper";
import translate from "../../i18n/translate";
import { useIntl } from "react-intl";
const inputItems = [
  "Queue",
  "Transfer to user",
  "Disconnect",
  "Routing",
  "Transfer to phone",
];
const StaticDropDown = (props) => (
  <Dropdown
    className="StaticDropDown"
    defaultValue={props?.defaultVal}
    items={props.inputItems}
    placeholder={`Select ${props.column}`}
    checkable
    onChange={(e, val) => {
      props.actionChangeHandler(props.rowid, val.value, props.dialKey);
    }}
    getA11ySelectionMessage={{
      onAdd: (item) => `${item} has been selected.`,
    }}
  />
);

const IvrContainer = forwardRef((props, ref) => {
  const deleteKey = (key) => {
    setRows((state) => state.filter((elm) => elm.key !== key));
  };
  const intl = useIntl();
  const header = {
    key: "header",
    items: [
      {
        content: "IVR",
        key: "ivr",
      },
      {
        content: intl.formatMessage({ id: "action-type" }),
        key: "getA11ySelectionMessage",
      },
      {
        content: intl.formatMessage({ id: "redirect-to" }),
        key: "pic",
      },
      {
        content: intl.formatMessage({ id: "delete" }),
        key: "delete",
      },
    ],
  };
  const getRedirectComponent = (val, data, dielKey) => {
    switch (val.toLowerCase()) {
      case "routing":
        let routingList = Object.keys(props.routingList);
        routingList.map((item, i) => {
          if (item === "0") {
            routingList[i] = "Start";
          }
        });
        let allOptions = props.data.options;
        return {
          content: (
            <Flex gap="gap.small" vAlign="center">
              <Dropdown
                className="StaticDropDown"
                defaultValue={
                  allOptions[dielKey]?.routingName === "0"
                    ? "Start"
                    : allOptions[dielKey]?.routingName
                }
                items={routingList}
                placeholder={intl.formatMessage({ id: "select-routing" })}
                checkable
                onChange={(e, data) => {
                  let value = "";
                  if (data.value === "Start") {
                    value = "0";
                  } else {
                    value = data.value;
                  }
                  props.getData(
                    data.value,
                    "routing",
                    dielKey,
                    props.routingName
                  );
                }}
              />
            </Flex>
          ),
        };
      case "transfer":
        return {
          content: (
            <Flex gap="gap.small" vAlign="center">
              <CallCenterName
                itemName="ivrTransfer"
                defaultVal={data}
                getData={props.getData}
                searchactifProfils
                dielKey={dielKey}
                displayName={data.queueDisplayName}
                routingName={props.routingName}
              />
            </Flex>
          ),
        };
      case "transfer to phone":
        let defaultTransferToNumberRouting = props.data.options[dielKey];
        return {
          content: (
            <Flex gap="gap.small" vAlign="center">
              <Input
                style={{ width: "253px" }}
                defaultValue={
                  defaultTransferToNumberRouting
                    ? defaultTransferToNumberRouting.targetId
                    : "+"
                }
                onChange={(e, data) => {
                  props.getData(
                    data.value,
                    "transfer to phone",
                    dielKey,
                    props.routingName
                  );
                }}
              />
            </Flex>
          ),
        };
      case "transfer to user":
        return {
          content: (
            <Flex gap="gap.small" vAlign="center">
              <CallCenterName
                itemName="ivrTransfer"
                defaultVal={data}
                getData={props.getData}
                searchactifProfils
                dielKey={dielKey}
                displayName={data.queueDisplayName}
                routingName={props.routingName}
              />
            </Flex>
          ),
        };
      case "queue":
        return {
          content: (
            <Flex gap="gap.small" vAlign="center">
              <CallCenterName
                scope={props.scope}
                itemName="ivrQueue"
                getData={props.getData}
                searchQueue
                searchactifProfils
                displayName={data.queueDisplayName}
                dielKey={dielKey}
                routingName={props.routingName}
              />
            </Flex>
          ),
        };
      default:
        if (
          props.data.options[dielKey] &&
          props.data.options[dielKey].type === "disconnect"
        ) {
          return "";
        } else {
          props.setDisconnectIvr(dielKey, props.routingName);
          return "";
        }
    }
  };
  const actionChangeHandler = (key, val, dialKey) => {
    setRows((state) => {
      const lineIndex = state.findIndex((elm) => elm.key === key);
      return update(state, {
        [lineIndex]: {
          items: {
            [2]: {
              content: {
                $set: getRedirectComponent(
                  val,
                  { queueDisplayName: "" },
                  dialKey
                ).content,
              },
            },
          },
        },
      });
    });
  };
  useEffect(() => {
    const options = props.data.options;
    const rowItems = Object.keys(options).map((elm, i) => {
      const id = uuidv4();
      if (options[elm].type !== "loop") {
        let defaultValue = "";
        if (
          options[elm].type === "transfer" &&
          options[elm].targetType === "phone"
        ) {
          defaultValue = "transfer to phone";
        } else if (
          options[elm].type === "transfer" &&
          options[elm].targetType === "user"
        ) {
          defaultValue = options[elm].type + " to " + options[elm].targetType;
        } else {
          defaultValue = options[elm].type;
        }

        return {
          key: id,
          items: [
            {
              content: <Text rowid={id} column="Ivr" content={elm} />,
              key: `${i}-0`,
              id: "name-1",
            },
            {
              content: (
                <Flex gap="gap.small" vAlign="center">
                  <StaticDropDown
                    dialKey={elm}
                    defaultVal={
                      defaultValue.charAt(0).toUpperCase() +
                      defaultValue.slice(1)
                    }
                    actionChangeHandler={actionChangeHandler}
                    rowid={id}
                    inputItems={inputItems}
                    column="action"
                  />
                </Flex>
              ),
              key: `${i}-1`,
              id: "name-1",
            },
            {
              content: getRedirectComponent(
                defaultValue,
                {
                  queueDisplayName:
                    options[elm].queueDisplayName ||
                    options[elm].userDisplayName ||
                    options[elm].targetId,
                },
                elm
              ),
              key: `${i}-2`,
            },
            {
              content: (
                <Flex gap="gap.small" vAlign="center">
                  <Button
                    primary
                    icon={<TrashCanIcon />}
                    onClick={(e, key) => {
                      props.removeIvr(elm, props.routingName);
                      deleteKey(id);
                    }}
                    text
                  />{" "}
                </Flex>
              ),
              key: `${i}-3`,
            },
          ],
        };
      }
    });

    setRows(rowItems);
  }, [props.data.options]);
  const [rows, setRows] = useState([]);
  const [scope, setScope] = useState(props.scope);
  useImperativeHandle(ref, (props) => ({
    addIvr: (data) => {
      const uid = uuidv4();
      const newIvr = {
        key: uid,
        items: [
          {
            content: <Text content={data} rowid={uid} column="ivr" />,
            key: `${rows.length + 1}-1`,
          },
          {
            content: (
              <Flex>
                <StaticDropDown
                  dialKey={data}
                  rowid={uid}
                  inputItems={inputItems}
                  actionChangeHandler={actionChangeHandler}
                  column="action"
                />
              </Flex>
            ),

            key: `${rows.length + 1}-2`,
            id: "name-1",
          },
          {
            content: (
              <Flex gap="gap.small" vAlign="center">
                <CallCenterName searchQueue scope={scope} />
              </Flex>
            ),
            key: `${rows.length + 1}-3`,
          },
          {
            content: (
              <Flex gap="gap.small" vAlign="center">
                <Button
                  primary
                  icon={<TrashCanIcon />}
                  onClick={(key) => deleteKey(newIvr.key)}
                  text
                />{" "}
              </Flex>
            ),
            key: `${rows.length + 1}-4`,
          },
        ],
      };
      setRows((state) => {
        const newSate = [...state, newIvr];
        return newSate;
      });
    },
  }));

  return (
    <Table
      className="table_ivrs"
      variables={{
        cellContentOverflow: "none",
      }}
      header={header}
      rows={rows}
      aria-label="Nested navigation"
      accessibility={gridNestedBehavior}
    />
  );
});

export default IvrContainer;
