/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { authContext } from "../../auth";
import Axios from "axios";
import {
  TrashCanIcon,
  AddIcon,
  EditIcon,
} from "@fluentui/react-icons-northstar";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Loader } from "@fluentui/react-northstar";
import { useHistory } from "react-router-dom";
import { Table } from "@fluentui/react-northstar";
import { removeCallFlowManagementUrl, getAllCallFlowManagementUrl } from "../../urls"
import { useIntl } from 'react-intl';
import translate from '../../i18n/translate'
const ListExampleSelectable = (props) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [callFlowListTable, setCallFlowListTable] = useState([]);
  const history = useHistory();
  const removeCallFlow = (elm, scope) => {
    const url = removeCallFlowManagementUrl(authContext._user.profile.tid, authContext._user.profile.oid, elm, scope);
    Axios.post(url).then(() => {
      setCallFlowListTable((prev) => {
        return [
          ...prev.filter((item, i) => item.key !== elm)
        ]
      })
      setIsLoading(false);
    });
  };
  const addCallFlow = () => {
    history.push(`/call-flow/newCallFlow`);
  };
  const selectCallFlow = (event, data) => {
    history.push(`/call-flow/${data.RowKey}/${data.scope}`);
  };
  const getCallFlowData = async () => {
    let scopeListName = Object.keys(props.scopeList)
    const promises = []
    for (let scope of scopeListName) {
      const url = getAllCallFlowManagementUrl(authContext._user.profile.tid, authContext._user.profile.oid, scope);
      await Axios.get(url).then((res) => {
        promises.push(res.data)
      })
    }
    Promise.all(promises).then((results) => {
      var stateNew = {}
      for (var result of results) {
        if (Object.keys(result).length > 0) {
          stateNew = Object.assign({}, stateNew, result);
        }
      }
      return stateNew
    }).then((stateNew) => {
      props.setCallFlowList(stateNew)
      formatRawToList(stateNew)
      setIsLoading(false);
    })
  };
  const confirmeRemoveCallflow = (elm, callFlowName, scope) => {
    confirmAlert({
      title: intl.formatMessage({ id: "confirmation" }),
      message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + callFlowName + '!',
      buttons: [
        {
          label: intl.formatMessage({ id: "yes" }),
          onClick: () => removeCallFlow(elm, scope)
        },
        {
          label: intl.formatMessage({ id: "no" }),
        }
      ]
    });
  };
  const formatRawToList = (data) => {
    return Object.keys(data).map((elm, i) => {
      let rowTable = {
        key: elm,
        items: [
          {
            content: data[elm].displayName,
            key: i + "-1",
          },
          {
            content: data[elm].scope,
            key: i + "-9",
          },
          {
            content: data[elm].timeZone,
            key: i + "-2",
          },
          {
            content: data[elm].language,
            key: i + "-3",
          },
          {
            content: data[elm].greeting.emergencyMessage.enabled.toString(),
            key: i + "-4",
          },
          {
            content: data[elm].greeting.welcomeMessage.enabled.toString(),
            key: i + "-5",
          },
          {
            content: data[elm].greeting.flashMessage.enabled.toString(),
            key: i + "-6",
          },
          {
            content: data[elm].greeting.customMessage.enabled.toString(),
            key: i + "-7",
          },
          {
            content: (
              <div>
                <Button
                  icon={<TrashCanIcon />}
                  text
                  iconOnly
                  title={intl.formatMessage({ id: "remove-call-flow" })}
                  onClick={() => confirmeRemoveCallflow(elm, data[elm].displayName, data[elm].scope)}
                />
                <Button
                  icon={<EditIcon />}
                  text
                  iconOnly
                  title={intl.formatMessage({ id: "edit-call-flow" })}
                  onClick={(event) => selectCallFlow(event, data[elm])}
                />
              </div>
            ),
            key: i + "-8",
          },
        ],
      };
      setCallFlowListTable((callFlowListTable) => [
        ...callFlowListTable,
        rowTable,
      ]);
      return callFlowListTable;
    });
  };
  useEffect(() => {
    if (props.scopeList && props.scopeList !== null) {
      getCallFlowData();
    }
  }, [props.scopeList]);
  const header = {
    key: "header",
    items: [
      {
        content: intl.formatMessage({ id: "resource-account" }),
        key: "Service costumer",
      },
      {
        content: intl.formatMessage({ id: "scope" }),
        key: "scope",
      },
      {
        content: intl.formatMessage({ id: "time-zone" }),
        key: "TimeZone",
      },
      {
        content: intl.formatMessage({ id: "language" }),
        key: "Language",
      },
      {
        content: intl.formatMessage({ id: "emergency-message" }),
        key: "Emergency Message",
      },
      {
        content: intl.formatMessage({ id: "welcome-message" }),
        key: "Welcome Message",
      },
      {
        content: intl.formatMessage({ id: "flash-message" }),
        key: "Flash Message",
      },
      {
        content: intl.formatMessage({ id: "custom-message" }),
        key: "Custom Message",
      },
      {
        content: intl.formatMessage({ id: "action" }),
        key: "Action",
      },
    ],
  };
  return (
    <div className="table-list">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h2> {translate("call-flow-list")}</h2>
          <div className="table-header">
            <Button
              icon={<AddIcon />}
              text
              content={intl.formatMessage({ id: "add" })}
              primary
              onClick={addCallFlow}
            />
          </div>
          <Table
            className="table-call-flow"
            header={header}
            rows={callFlowListTable}
          />
        </>
      )}
    </div>
  );
};

export default ListExampleSelectable;
