import React, { useState, useEffect, } from "react";
import update from "immutability-helper";
import {
    Input,
    Header,
    Checkbox,
    Flex,
    AddIcon,
    Button,
    TrashCanIcon
} from "@fluentui/react-northstar";
import AudioComponent from './AudioComponent'
import { confirmAlert } from 'react-confirm-alert';
import { useIntl } from 'react-intl';
import QuestionComponent from "./QuestionComponent";
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import './Survey.css'
const SurveysComponent = (props) => {
    const [showNameInput, setShowNameInput] = useState(false);
    const [showQuestionDetails, setShowQuestionDetails] = useState()
    const [newQuestionName, setNewQuestionName] = useState(null)
    const [showSurveyDetails, setShowSurveyDetails] = useState(false)
    const [usedOrder, setUsedOrder] = useState([])
    const [allQuestion, setAllQuestion] = useState([]);
    const [addQuestionErrorMessage, setAddQuestionErrorMessage] = useState(null);
    const intl = useIntl();
    let callflowSurvey = props.callCenterData.survey;
    let callflowLanguage = props.callCenterData.language
    let TEXT2SPEECH_DEFAULT_VALUE = 'text2speech'

    const toggleHandler = (QuestionName) => {
        if (showQuestionDetails === QuestionName) {
            setShowQuestionDetails('')
        } else {
            setShowQuestionDetails(QuestionName)
        }
    }
    const removeQuestion = (QuestionName) => {
        let newQuestionList = callflowSurvey.questions;
        delete newQuestionList[QuestionName]
        setAllQuestion(allQuestion.filter(item => item !== QuestionName))
        props.setCallCenterData((state) => {
            return update(state, {
                survey: { questions: { $set: newQuestionList } },
            });
        })
    }
    const confirmRemoveQuestion = (questionName) => {
        confirmAlert({
            title: intl.formatMessage({ id: "confirmation" }),
            message: intl.formatMessage({ id: "are-you-sure-to-remove" }) + questionName + '!',
            buttons: [
                {
                    label: intl.formatMessage({ id: "yes" }),
                    onClick: () => removeQuestion(questionName)
                },
                {
                    label: intl.formatMessage({ id: "no" }),
                }
            ]
        });
    }
    const setCloserMessageAudioType = (type) => {
        let audio = { type: type };
        if (type === TEXT2SPEECH_DEFAULT_VALUE) {
            audio.language = callflowLanguage
        }
        props.setCallCenterData((state) => {
            return update(state, {
                survey: { goodByeMessage: { $set: audio } },
            });
        });
    }
    const setCloserMessageAudiofile = (audio) => {
        props.setCallCenterData((state) => {
            return update(state, {
                survey: { goodByeMessage: { $set: audio } }
            });
        });
    }
    useEffect(() => {
        if (callflowSurvey && callflowSurvey.enabled === true && callflowSurvey.questions) {
            Object.keys(callflowSurvey.questions).map((item, i) => {
                if (callflowSurvey.questions[item].Order) {
                    setUsedOrder(usedOrder => [...usedOrder, callflowSurvey.questions[item].Order]);
                }
            })
        }
        if (callflowSurvey && callflowSurvey.enabled === true) {
            setShowSurveyDetails(true)
        }
    }, [])
    useEffect(() => {
        if (props.callCenterData && callflowSurvey && callflowSurvey.questions) {
            setAllQuestion(Object.keys(callflowSurvey.questions))
        }
    }, [props.questions])
    useEffect(() => {
        if ((callflowSurvey && !callflowSurvey.goodByeMessage) ||
            (callflowSurvey && callflowSurvey.goodByeMessage && callflowSurvey.goodByeMessage.type === TEXT2SPEECH_DEFAULT_VALUE && !callflowSurvey.goodByeMessage.language)) {
            let goodByeMessage = { type: TEXT2SPEECH_DEFAULT_VALUE, language: callflowLanguage }
            props.setCallCenterData((state) => {
                return update(state, {
                    survey: { goodByeMessage: { $set: goodByeMessage } },
                });
            });
        }
    }, [callflowSurvey])
    return (
        <div className="Survey-div">
            <Flex hAlign="start" vAlign="start" gap="gap.small" style={{ marginBottom: '20px' }}>
                <Header as="h5" className="start-servery-div-title" content={intl.formatMessage({ id: "ENABLE_SURVEY" })} />
                <Checkbox
                    className=" survey-checkbox"
                    toggle
                    checked={callflowSurvey.enabled && callflowSurvey.enabled === true ? true : false}
                    onClick={(e, data) => {
                        if (data.checked === true) {
                            setShowSurveyDetails(true)
                        } else {
                            setShowSurveyDetails(false)
                        }
                        props.setCallCenterData((state) => {
                            return update(state, {
                                survey: { enabled: { $set: data.checked } },
                            });
                        });
                    }}
                />
            </Flex>
            {
                showSurveyDetails === true ?
                    <div >
                        <Flex hAlign="start" vAlign="start" gap="gap.small" style={{ marginBottom: '20px' }}>
                            <Header as="h5" className="start-servery-div-title" content={intl.formatMessage({ id: "timeout" })} />
                            <Input
                                className="numerical-value-input"
                                defaultValue={callflowSurvey.timeout ? callflowSurvey.timeout : "0"}
                                onChange={(event, data) => {
                                    props.setCallCenterData((state) => {
                                        return update(state, {
                                            survey: { timeout: { $set: data.value } },
                                        });
                                    });
                                }}
                                type="number"
                                min="0"
                            />
                            <span>{intl.formatMessage({ id: "SECONDES" })}</span>
                        </Flex>
                        {
                            allQuestion.map((item, i) => {
                                return (
                                    <div key={i} >
                                        <Flex hAlign="start" vAlign="start" gap="gap.small">
                                            <div className="question-name-div" >
                                                {showQuestionDetails === item ?
                                                    <Button
                                                        primary
                                                        size="smallest"
                                                        icon={<BsChevronUp />}
                                                        text
                                                        title={intl.formatMessage({ id: "HOVER_SHOW_DETAILS_BTN_MESSAGE" })}
                                                        onClick={() => { toggleHandler(item) }}
                                                    />
                                                    :
                                                    <Button
                                                        primary
                                                        size="smallest"
                                                        icon={<BsChevronDown />}
                                                        text
                                                        title={intl.formatMessage({ id: "HOVER_HIDE_DETAILS_BTN_MESSAGE" })}
                                                        onClick={() => { toggleHandler(item) }}
                                                    />
                                                }
                                                <Header className='title-start-routing' content={item} />
                                            </div>
                                            <Button
                                                primary
                                                size="smallest"
                                                icon={<TrashCanIcon />}
                                                text
                                                title={intl.formatMessage({ id: "HOVER_DELETE_QUESTION_MESSAGE" })}
                                                onClick={() => { confirmRemoveQuestion(item) }}
                                            />
                                        </Flex>
                                        {showQuestionDetails === item ?
                                            <QuestionComponent
                                                audioList={props.audioList}
                                                questionName={item}
                                                setCallCenterData={props.setCallCenterData}
                                                question={callflowSurvey.questions[item]}
                                                callFlowId={props.callCenterData.id}
                                                usedOrder={usedOrder}
                                                setUsedOrder={setUsedOrder}
                                                callFlowLanguage={callflowLanguage}
                                            />
                                            :
                                            null
                                        }

                                    </div>
                                )

                            })
                        }
                        {showNameInput === true ?
                            <>
                                <Flex hAlign="start" vAlign="start" gap="gap.small">
                                    <Header as="h5" content={intl.formatMessage({ id: "name" })} />
                                    <Input
                                        onChange={(event, data) => {
                                            setNewQuestionName(data.value)

                                        }}
                                        className="survey-name-input"
                                    />
                                    <Button
                                        icon={<AddIcon />}
                                        text
                                        primary
                                        onClick={() => {
                                            if (newQuestionName !== null && newQuestionName.length > 0 && !newQuestionName.includes(" ")) {
                                                setShowNameInput(false)
                                                let defaultQuestionObject = { audio: { type: TEXT2SPEECH_DEFAULT_VALUE, language: callflowLanguage } }
                                                props.setCallCenterData((state) => {
                                                    return update(state, {
                                                        survey: { questions: { [newQuestionName]: { $set: defaultQuestionObject } } },
                                                    });

                                                })
                                                setAddQuestionErrorMessage(null)
                                            } else {
                                                setAddQuestionErrorMessage(intl.formatMessage({ id: "ADD_QUESTION_ERROR_MESSAGE" }))
                                            }


                                        }}
                                    />
                                </Flex>
                                {addQuestionErrorMessage !== null ?
                                    <p style={{ color: 'red' }}>{addQuestionErrorMessage}</p>
                                    :
                                    null
                                }
                            </>
                            :
                            <Button
                                icon={<AddIcon />}
                                text
                                primary
                                content={intl.formatMessage({ id: "ADD_NEW_QUEUESTION" })}
                                onClick={() => setShowNameInput(true)}
                            />
                        }
                        <Flex hAlign="start" vAlign="start" gap="gap.small" style={{ marginBottom: '20px' }}>
                            <Header as="h5" className="start-servery-div-title" content={intl.formatMessage({ id: "GOOD_BYE_MESSAGE" })} />
                            <div style={{ marginLeft: '-50px' }}>
                                <AudioComponent
                                    audioList={props.audioList}
                                    fileLocationUnderCallflow={"survey/goodbyeMessage"}
                                    setAudioType={setCloserMessageAudioType}
                                    setAudiofile={setCloserMessageAudiofile}
                                    data={callflowSurvey.goodByeMessage}
                                    callFlowId={props.callCenterData.id}
                                />
                            </div>
                        </Flex>
                    </div>
                    :
                    null
            }


        </div>
    )
}
export default SurveysComponent;