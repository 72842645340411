import React from "react";
import {
  Button,
  Header,
  Flex,
  Label,
} from "@fluentui/react-northstar";
import { Redirect } from "react-router-dom";
import { authContext } from "../auth";
import axios from "axios";
import { baseConsentUrl, ProvioningURL, ConsentccUrl, ConsentpresenceUrl } from "../urls"
const presenceAPp = "b49547c3-196e-4e6b-a4c0-a4deea391db0";
const contactCenterApp = "2ad91f01-bf93-4313-89f1-cb201229e7db";
const consentUrl = baseConsentUrl;
const provioningURL = ProvioningURL;

class Consent extends React.PureComponent {
  render() {
    authContext.handleWindowCallback();
    let user = authContext.getCachedUser();
    let welcome = null;
    if (user) {
      welcome = "Welcome " + user.userName;
    } else {
      authContext.login();
    }
    let appId = null;
    if (this.props.location.pathname === "/Consentpresence") {
      appId = presenceAPp;
    }
    if (this.props.location.pathname === "/Consentcc") {
      appId = contactCenterApp;
    }
    function getJsonFromUrl(url) {
      if (!url) url = location.search;
      var query = url.substr(1);
      var result = {};
      query.split("&").forEach(function (part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    }
    let location = this.props.location;

    let params = getJsonFromUrl();
    if (
      params.admin_consent &&
      params.admin_consent === "True" &&
      params.tenant
    ) {
      let adminProfile = authContext.getCachedUser();
      ///{tenant}/{action}/{appId}/{adminId}
      axios.post(
        provioningURL +
        adminProfile.profile.tid +
        "/add/" +
        appId +
        "/" +
        adminProfile.profile.oid,
        adminProfile
      );
      return <Redirect to="/" />;
    } else {
      return (
        <div className="Concent">
          <Flex hAlign="center" vAlign="center" gap="gap.large">
            <Header as="h5" content={welcome} color={"green"} />
          </Flex>
          <Label
            color="white"
            content="We need your consent for our Applications below to be able to deliver "
          />
          <Header as="h3" content="  Call Center and Receptionist Console" />
          <Flex gap="gap.large">
            <Button
              primary
              content="Admin Consent"
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  consentUrl +
                  contactCenterApp +
                  "&redirect_uri=" + ConsentccUrl;
              }}
            />
          </Flex>
          <Header
            as="h3"
            content="Heedify Presence Application" />
          <Flex gap="gap.large">
            <Button
              primary
              inline
              content="Admin Consent"
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  consentUrl +
                  presenceAPp +
                  "&redirect_uri=" + ConsentpresenceUrl;
              }}
            />
          </Flex>
        </div>
      );
    }
  }
}
export default Consent;
