import React, { useState, useEffect } from "react";
import { getAllChannelsFlowsUrl, getAllChatFlowManagementUrl, getAllAgentsUrl, assigneChannelUrl, unAssigneChannelUrl, getchannelFromConsmosDbUrl } from "../../urls";
import { Dropdown, Table } from "@fluentui/react-northstar";
import { getToken } from "../../components/Graph"
import { authContext } from "../../auth";
import Axios from "axios";
const ChannelAgentComponent = (props) => {
    const [channelList, setChannelList] = useState([]);
    const [channels, setChannels] = useState([]);
    const header = {
        items: ['Channel name', 'Teams name', 'Agent'],
    }
    const getChannels = async () => {
        let Token = await getToken(authContext);
        let scopeListName = Object.keys(props.scopeList)
        const promises = []
        for (let scope of scopeListName) {
            const url = getAllChatFlowManagementUrl(authContext._user.profile.tid, Token, scope);
            await Axios.get(url).then((res) => {
                promises.push(res.data)
            })
        }
        Promise.all(promises).then(async (results) => {
            var newChatflowList = []
            for (var result of results) {
                if (Object.keys(result).length > 0) {
                    newChatflowList = newChatflowList.concat(result);
                }
            }
            for (const chatFlow of newChatflowList) {
                let url = getAllChannelsFlowsUrl(props.queueInfos.PartitionKey, props.queueInfos.scope, chatFlow.id, Token);
                await Axios.get(url).then((res) => {

                    for (let item of res.data) {
                        let chatChannel = item;
                        chatChannel['assignedAgent'] = item.assignedAgent;
                        chatChannel['chatFlow'] = chatFlow;
                        if (item !== "") {
                            chatChannel['assignedAgent'] = item.assignedAgent;
                        } else {
                            chatChannel['assignedAgent'] = {};
                        }
                        setChannels(channels => [...channels, chatChannel]);
                    }
                })
            }
        })
    };
    const getAgentsList = async () => {
        let agents = []
        for (const item in props.answeringAgents) {
            if (Object.hasOwnProperty.call(props.answeringAgents, item)) {
                const element = props.answeringAgents[item];
                let agent = {
                    key: item,
                    header: element.displayName
                }
                agents.push(agent)
            }
        }
        updateChannelList(agents)
    };
    const updateChannelList = async (agents) => {
        for (const item of channels) {
            let channel = {
                key: item.channelId,
                items: [item.channelName,
                item.teamName ? item.teamName : "",
                <Dropdown
                    clearable
                    defaultValue={item.assignedAgent && item.assignedAgent.id ?
                        {
                            key: item.assignedAgent.id,
                            header: item.assignedAgent.displayName
                        }
                        :
                        ""
                    }
                    items={agents}
                    onChange={async (e, data) => {
                        if (data.value !== null) {
                            data = {
                                agentId: data.value.key,
                                agentDisplayName: data.value.header,
                                channelId: item.channelId,
                                teamName: item.teamName,
                                channelName: item.channelName
                            }
                            let url = assigneChannelUrl(props.queueInfos.PartitionKey, props.queueInfos.scope, item.chatFlow.id, item.channelId, null)
                            await Axios.post(url, data).then((res) => {
                            })
                        } else {
                            let url = unAssigneChannelUrl(props.queueInfos.PartitionKey, props.queueInfos.scope, item.chatFlow.id, item.channelId, null)
                            await Axios.post(url).then((res) => {
                            })
                        }
                    }}
                />
                ]
            }
            setChannelList(channelList => [...channelList, channel]);
        }
    }
    useEffect(() => {
        getChannels()
    }, [])
    useEffect(() => {
        setChannelList([])
        getAgentsList()

    }, [channels])
    useEffect(() => {
        setChannelList([])
        getAgentsList()
    }, [props.answeringAgents])
    return (
        <div style={{ paddingTop: '3%', marginLeft: '3%' }}>
            <Table header={header} rows={channelList} className="table-channels" />
        </div>
    )

}
export default ChannelAgentComponent;

/**
 *   
 */