/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, Button } from "@fluentui/react-northstar";
import { authContext } from "../../auth";
import {
  searchContactFullinfos,
  getPersonPhoto,
} from "../../components/Graph";
import Axios from "axios";
import { getAllQueueManagementUrl } from "../../urls";
import translate from '../../i18n/translate'
import { useIntl } from 'react-intl';
const ChatQueueUserSearch = (props) => {
  authContext.handleWindowCallback();
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState(props.targetCF?.displayName);
  const intl = useIntl();
  useEffect(() => {
    setSearchQuery(props.targetCF?.displayName);
  }, [props.targetCF?.displayName]);

  const handleQuery = async (e, search) => {
    let list = [];

    setSearchQuery(search.searchQuery);
    if (search.searchQuery.length > 3) {
      if (props.queueSearch) {
        let rawList = await Axios.get(
          getAllQueueManagementUrl(
            authContext._user.profile.tid,
            authContext._user.profile.oid
          )
        );
        list = Object.keys(rawList.data).map((elm) => {
          return {
            header: rawList.data[elm].queueName,
            key: rawList.data[elm].RowKey,
          };
        });
        setSuggestedItems(() => list);

        return;
      }
      const contactsSuggest = await getUser(search.searchQuery);
      let formatedItems = [];
      for (let contact of contactsSuggest) {
        let photo = await getUserPhoto(contact.id);

        formatedItems.push({
          header: contact.displayName,
          image: photo === null ? "" : window.URL.createObjectURL(photo),
          id: contact.id,
          content: (
            <div>
              {" "}
              <Button
                primary
                size="small"
                content={intl.formatMessage({ id: "add" })}
                onClick={() => { }}
              />{" "}
            </div>
          ),
        });
      }
      setSuggestedItems(() => formatedItems);
    }
  };
  const getUser = async (query) => {
    return await searchContactFullinfos(authContext, query, true);
  };
  const getUserPhoto = async (id) => {
    return await getPersonPhoto(authContext, id);
  };
  return (
    <>
      <Dropdown
        className="from-item"
        search
        searchQuery={searchQuery}
        items={suggestedItems}
        placeholder={intl.formatMessage({ id: "start-typing" })}
        noResultsMessage={intl.formatMessage({ id: "we-couldn't-find-any-matches" })}
        onSearchQueryChange={(e, textQuery) => handleQuery(e, textQuery)}
        getA11ySelectionMessage={{
          onAdd: (item, val) => {
            props.setCallAnswering(item)
          },
        }}
      />
    </>
  );
};
export default ChatQueueUserSearch;
