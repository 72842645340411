import React from "react";
import {
  Header,
  RadioGroup,
  Dropdown,
  Input,
  Checkbox
} from "@fluentui/react-northstar";
import QueueUserSearchContainer from "../../containers/Queues/QueueUserSearchContainer";
import { useIntl } from 'react-intl';
const CallMaxWaitTimeout = (props) => {
  const intl = useIntl();
  const getItems = () => {
    return [
      {
        name: "Disconnect",
        key: "disconnect",
        label: intl.formatMessage({ id: "disconnect" }),
        value: "disconnect",
      },
      {
        name: "Transfer To",
        key: "transfer",
        label: intl.formatMessage({ id: "transfert-to" }),
        value: "transfer",
      },
      {
        name: "Queue",
        key: "queue",
        label: intl.formatMessage({ id: "queue" }),
        value: "queue",
      },
    ];
  };
  const handleChange = (e, data) => {
    props.setSelectedOverflowType(data.value, props.itemToUpdate);
  };
  return (
    <div className="open-hours-fallback" >
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '405px', marginTop: '25px' }}>
        <Header
          as="h5"
          content={props.title || intl.formatMessage({ id: "queue-fallback" })}
        />
      </div>
      <div style={{marginLeft:'50px'}}>
      <RadioGroup
        className="from-item"
        vertical
        checkedValue={props.action}
        items={getItems()}
        onCheckedValueChange={handleChange}
      />
      </div>
      {props.action === "transfer" ? (

        <TransferDetails
          setSelectedUserTransfertType={props.setSelectedUserTransfertType}
          setSelectedUserTransfertId={props.setSelectedUserTransfertIdForCF}
          overflowType={props.overflowType}
          type={props.action}
          itemToUpdate={props.itemToUpdate}
          id={props.id}
          {...props}
        />
      )
        :
        null
      }
      {
        props.action === "queue" ? (
          <QueueDetails
            scope={props.scope}
            setSelectedUserTransfertType={props.setSelectedUserTransfertType}
            setSelectedUserTransfertId={props.setSelectedUserTransfertIdForCF}
            overflowType={props.overflowType}
            type={props.action}
            itemToUpdate={props.itemToUpdate}
            id={props.id}
            {...props}
          />
        )
          :
          null
      }

    </div>
  );
};
const inputItems = ["phone", "user", "voicemail"];
const TransferDetails = (props) => {
  const intl = useIntl();
  const handleOverflowtypeChange = (data) => {
    props.setSelectedUserTransfertType(data.value, props.itemToUpdate);
  };

  return (
    <>
      <Dropdown
        className="from-item"
        items={inputItems}
        value={props.overflowType}
        onChange={(e, data) => {
          handleOverflowtypeChange(data);
        }}
        placeholder={intl.formatMessage({ id: "transfert-type" })}
        checkable
      />
      {props.overflowType === "phone" || props.overflowType === "voicemail" ? (

        <Input
          className="from-item"
          defaultValue={props?.id}
          onBlur={(e) => {
            props.setSelectedUserTransfertId(
              { id: e.target.value },
              props.itemToUpdate
            )
          }}
          clearable
        />
      ) : (
        <QueueUserSearchContainer
          itemToUpdate={props.itemToUpdate}
          isCallflow={props.isCallflow}
          overflowType={props.targetType}
          queueSearch={false}
          setSelectedUserTransfertIdForCF={
            props.setSelectedUserTransfertIdForCF
          }
          setSelectedUserTransfertId={props.setSelectedUserTransfertId}
          targetCF={props.targetObj}
          {...props}
        />
      )}
    </>
  );
};
const QueueDetails = (props) => {
  return (
    <>
      <QueueUserSearchContainer
        scope={props.scope}
        queueInfo={props.queueInfo}
        isQueueFallback={props?.isCallflow ? false : true}
        itemToUpdate={props.itemToUpdate}
        isCallflow={props.isCallflow ? true : false}
        overflowType={props.targetType}
        queueSearch={true}
        setSelectedUserTransfertIdForCF={
          props.setSelectedUserTransfertIdForCF
        }
        setSelectedUserTransfertId={props.setSelectedUserTransfertId}
        setSelectedHolidaysQueue={props.setSelectedHolidaysQueue}
        setSelectedOpenHoursQueue={props.setSelectedOpenHoursQueue}
        targetCF={props.targetObj}
        {...props}
      />

    </>
  );
};
export default CallMaxWaitTimeout;